import * as S from './styles';

export const P = ({
  children,
  className,
  align,
  bold,
  width,
  width10,
  color,
  marginTop,
  marginBottom,
  nowrap,
  paddingX,
  fontSize,
}) => (
  <S.P
    className={className + ' p'}
    align={align}
    bold={bold}
    width={width}
    width10={width10}
    color={color}
    marginTop={marginTop}
    marginBottom={marginBottom}
    nowrap={nowrap}
    paddingX={paddingX}
    fontSize={fontSize}
  >
    {children}
  </S.P>
);

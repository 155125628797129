import styled from 'styled-components/macro';
import { space, layout } from 'styled-system';

export const H1slide = styled.h1`
  color: var(--color-white);
  font-family: 'EurostileBold';
  font-size: 29px;
  font-weight: 400;
  line-height: 40px;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  width: 100%;
  ${space};
  ${layout};
`;

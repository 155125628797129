import { UiWrapper } from 'coinscrap-webapp-core-ts';

UiWrapper.defineComponent('inputCheck', {
  styles: {
    default: {
      checkComponent: {
        padding: '0',
        color: '#ccc !important',
        background: '#ccc',
        marginRight: '5px',
        width: '18px',
        height: '18px',
      },
    },
    activeSt: {
      checkComponent: {
        color: '#B000F5',
        background: '#B000F5',
      },
    },
  },
});

import { Header } from 'components/Header/header';
import { MainButton } from 'components/MainButton/MainButton';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import { useEffect, useState } from 'react';
import { NavigationWrapper, Page, UiWrapper } from 'coinscrap-webapp-core-ts';
import { routes } from 'routes';
import ReactLoading from 'react-loading';
import * as S from './styles';
import { MarginWrapper } from '../../../layout/MarginWrapper/MarginWrapper';
import { Api } from '../../../api/api';
import { TournamentUtils } from '../../../utils/TournamentUtils';
import * as CommonStyles from '../../../styles/common';
import { CommonUtils } from '../../../utils/CommonUtils';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DEFAULT_OPTIONS = {
  responsive: true,
  aspectRatio: 2,
  layout: {
    padding: '0',
    autoPadding: false,
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  // scales: {
  //   xAxis: {
  //     display: false,
  //   },
  //   yAxis: {
  //     display: false,
  //   },
  // },
  elements: {
    point: {
      borderWidth: '0',
      pointStyle: 'line',
    },
    line: {
      borderWidth: '0',
    },
  },
};

export const StockDetail = () => {
  const { navigateTo } = NavigationWrapper.use();
  const [instrument, setInstrument] = useState(null);
  const [updated, setUpdated] = useState(new Date(0));
  const [selectedInterval, setSelectedInterval] = useState('1W');
  const [loadingInstrument, setLoadingInstrument] = useState(true);
  const [loadingHistory, setLoadingHistory] = useState(true);
  const [dataEntries, setDataEntries] = useState([]);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [options, setOptions] = useState(DEFAULT_OPTIONS);

  const [wallet, setWallet] = useState(null);
  const [remainingTitles, setRemainingTitles] = useState(0);

  const { useHeader } = UiWrapper.use();
  const { params, query } = Page.use();
  const { id: instrumentId /*otroParametroCualquiera*/ } = params;
  const { tournamentId, ticker, marketName } = query;

  useEffect(() => {
    setOptions({
      responsive: true,
      animation: {
        duration: 0,
      },
      aspectRatio: 2,
      layout: {
        padding: '0',
        autoPadding: false,
      },
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
      },
      scales: {
        xAxis: {
          display: false,
          // ticks: {
          //   suggestedMin: 0,
          //   suggestedMax: dataEntries.length,
          //   max: dataEntries.length,
          // }
        },
        yAxis: /*[*/ {
          display: false,
          ticks: {
            beginAtZero: false,
            // min: minValue,
            // max: maxValue,
            suggestedMin: minValue, //min
            suggestedMax: maxValue, //max
          },
        } /*]*/,
      },
      elements: {
        point: {
          borderWidth: '0',
          pointStyle: 'line',
        },
        line: {
          borderWidth: '0',
        },
      },
    });
  }, [minValue, maxValue]);

  useEffect(() => {
    if (instrument) {
      setLoadingHistory(true);
      let timeRange;
      switch (selectedInterval) {
        case '1D':
          timeRange = 'day';
          break;
        case '1W':
          timeRange = 'week';
          break;
        case '1M':
          timeRange = 'month';
          break;
        case '1Y':
          timeRange = 'year';
          break;
        case '5Y':
          timeRange = 'all';
          break;
        default:
          console.error('unknown interval', selectedInterval);
          break;
      }
      Api.instance
        .getFormattedRateHistoryForTicker(instrument.id, timeRange)
        .then(({ min, max, data: formattedData }) => {
          setDataEntries(formattedData || []);
          setMinValue(min);
          setMaxValue(max);

          setLoadingHistory(false);
        });
    }
  }, [selectedInterval, instrument, instrument?.id]);

  useEffect(() => {
    let cb;
    if (ticker && marketName) {
      console.log('Find instrument by ticker and marketName', ticker, marketName);
      cb = Api.instance.findInstrumentByTickerAndMarket.bind(Api.instance, ticker, marketName);
    } else if (instrumentId) {
        console.log('Find instrument by id', instrumentId);
      cb = Api.instance.findInstrumentById.bind(Api.instance, instrumentId);
    } else {
      return;
    }
    setLoadingInstrument(true);
    // eslint-disable-next-line no-shadow
    cb().then((instrument) => {
      if (instrument) {
        console.log('set instrument', instrument);
        setInstrument({
          ...instrument,
        });
        console.log('Intrument -- ', instrument)

        setLoadingInstrument(false);
      }
    });
  }, [ticker, marketName, instrumentId]);

  useEffect(() => {
    if (tournamentId) {
      // setLoadingInstrument(true);
      // eslint-disable-next-line no-shadow
      Api.instance.findMyWalletInTournament(tournamentId).then((walletData) => {
        if (walletData) {
          setWallet({
            ...walletData,
          });

          // setLoadingInstrument(false);
        }
      });
    }
  }, [tournamentId]);

  useEffect(() => {
    if (instrument && wallet) {
      Api.instance
        .findRemainingOrdersByWalletAndIsin('buy', wallet.id, instrument.isin)
        .then((buyOrders) => {
          if (buyOrders) {
            let newRemaining = 0;
            for (let i = 0; i < buyOrders.length; i++) {
              const buyOrder = buyOrders[i];
              newRemaining += buyOrder.remainingTitles;
            }
            setRemainingTitles(newRemaining);

            console.log('newRemaining TTT', newRemaining);
            // setLoadingInstrument(false);
          }
        });
    }
  }, [instrument, wallet]);

  useEffect(() => {
    if (instrument) {
      Api.instance.socketHandler.subscribeInstrument(
        instrument.ticker,
        instrument.marketId,
        (updatedInstrument) => {
          instrument.last = updatedInstrument.last;
          instrument.var = updatedInstrument.var;
          instrument.vol = updatedInstrument.vol;
          instrument.lastSeen = updatedInstrument.lastSeen;
        }
      );
    }
  }, [instrument]);

  useEffect(() => {
    setUpdated(new Date((Date.now()) - 15 * 60 * 1000));
  }, [instrument, instrument?.lastSeen])

  useHeader(<Header closeRoute={routes.tournamentHomeMain} />);
  return loadingInstrument || !instrument ? (
    <CommonStyles.FullHeightLoadingContainer>
      <ReactLoading type="spinningBubbles" color="#ffffff" height="20%" width="20%" />
    </CommonStyles.FullHeightLoadingContainer>
  ) : (
    <S.Wrapper>
      <S.Content>
        <MarginWrapper justifyContent="space-between" sinpadding style={{ zIndex: 999 }}>
          <S.Caja>
            <S.H1>{instrument?.ticker}</S.H1>
            <S.Small uppercase marginBottom>
              {instrument?.isin} - {instrument?.name}
            </S.Small>
            <S.H2>{TournamentUtils.formatCurrency(instrument?.last || 0, 2, true, wallet?.tournament?.market?.currency)}</S.H2>
            {instrument?.lastSeen &&
              <S.Verde>
                ACTUALIZADO A {CommonUtils.padded(new Date(instrument?.lastSeen).getHours(), 2)}:
                {CommonUtils.padded(new Date(instrument?.lastSeen).getMinutes(), 2)}:
                {CommonUtils.padded(new Date(instrument?.lastSeen).getSeconds(), 2)}
              </S.Verde>
            }
            <S.Flex style={{ display: 'none' }}>
              <S.Small>Sentimiento agregado en Redes Sociales</S.Small>
              <S.ProgressBar red="50">
                <S.ProgressBarLabel>Neutro</S.ProgressBarLabel>
              </S.ProgressBar>
              <S.IcoAzul>
                <InfoOutlinedIcon fontSize="medium" />
              </S.IcoAzul>
            </S.Flex>
            <S.IcoVerde>
              <ArrowDropUp />
            </S.IcoVerde>
            <S.Verde color={TournamentUtils.signedColor(instrument.var)}>
              {TournamentUtils.formatDecimals(
                (instrument.var / (instrument.last - instrument.var)) * 100.0,
                2
              )}
              %
            </S.Verde>
          </S.Caja>
          {dataEntries.length ?
            <S.P>Max: {TournamentUtils.formatCurrency(maxValue, 2, true, wallet?.tournament?.market?.currency)}</S.P>
            :
            <S.P style={{ textAlign: 'center' }}>No se encuentran datos para el rango seleccionado</S.P>
          }
          <S.Row>
            <S.Div>
              {dataEntries.length ? <S.P>Min: {TournamentUtils.formatCurrency(minValue, 2, true, wallet?.tournament?.market?.currency)}</S.P> : null}
              <S.Texto>
                <S.H3>6/6</S.H3>
                <S.Small marginBottom center small>
                  Este númreo es indicativo del riesgo del producto siendo 1/6 indicativo de menor
                  riesgo y 6/6 de mayor riesgo
                </S.Small>
              </S.Texto>
              <S.Flex padding>
                <S.H3 active={selectedInterval === '1D'} onClick={() => setSelectedInterval('1D')} style={{ cursor: 'pointer' }}>
                  1D
                </S.H3>
                <S.H3 active={selectedInterval === '1W'} onClick={() => setSelectedInterval('1W')} style={{ cursor: 'pointer' }}>
                  1S
                </S.H3>
                <S.H3 active={selectedInterval === '1M'} onClick={() => setSelectedInterval('1M')} style={{ cursor: 'pointer' }}>
                  1M
                </S.H3>
                <S.H3 active={selectedInterval === '1Y'} onClick={() => setSelectedInterval('1Y')} style={{ cursor: 'pointer' }}>
                  1A
                </S.H3>
                <S.H3 active={selectedInterval === '5Y'} onClick={() => setSelectedInterval('5Y')} style={{ cursor: 'pointer' }}>
                  5A
                </S.H3>
              </S.Flex>
              <S.Flex>
                {remainingTitles > 0 && (
                  <MainButton
                    pink
                    left
                    full
                    onClick={() => {
                      navigateTo(routes.tournamentTradeInputData, {
                        queryParams: {
                          instrumentId: instrument.id,
                          orderType: '12',
                        },
                      });
                    }}
                  >
                    VENDER
                  </MainButton>
                )}
                <MainButton
                  right={remainingTitles > 0}
                  fullWidth={remainingTitles <= 0}
                  onClick={() => {
                    navigateTo(routes.tournamentTradeInputData, {
                      queryParams: {
                        instrumentId: instrument.id,
                        orderType: '11',
                      },
                    });
                  }}
                >
                  COMPRAR
                </MainButton>
              </S.Flex>
              <S.Small center small marginTop>
                Rentabilidades pasadas no son garantía de rentabilidades futuras
              </S.Small>
            </S.Div>
          </S.Row>
        </MarginWrapper>
        {/* eslint-disable no-nested-ternary */}
        {loadingHistory ? (
          <CommonStyles.FullHeightLoadingContainer zIndex={1}>
            <ReactLoading type="spinningBubbles" color="#ffffff" height="20%" width="20%" />
          </CommonStyles.FullHeightLoadingContainer>
        ) : dataEntries.length ? (
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              flexDirection: 'column',
              top: '0',
              bottom: '0',
              left: '0',
              right: '0',
              height: '100%',
              width: '100%',
              paddingTop: '85%',
              zIndex: 1,
            }}
          >
            <div style={{}}>
              <Line options={options} data={(canvas) => {
                console.log('instrument', instrumentId, 'tournament', tournamentId);

                return {
                  labels: dataEntries.map((it) => it.x),
                  datasets: [
                    {
                      data: dataEntries,
                      fill: true,
                      backgroundColor: '#0059FA',
                      tension: 0.5,
                    },
                  ],
                };
              }} width="100%" height="auto" />
            </div>
            <div
              style={{
                background: 'linear-gradient(#0059FA, #B000F5)',
                display: 'flex',
                flexGrow: '1',
              }}
            />
          </div>
        ) : null}
      </S.Content>
    </S.Wrapper>
  );
};

import { UiWrapper } from 'coinscrap-webapp-core-ts';

UiWrapper.defineComponent('inputSuggest', {
  styles: {
    default: {
      containerComponent: {
        width: '100%',
      },
    },
  },
});

import styled from 'styled-components/macro';
import { space, layout } from 'styled-system';

export const H1small = styled.h1`
  color: ${(props) => (props.blue ? '#01A8FA' : 'white')};
  font-size: 16px;
  line-height: 4vh;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  text-transform: uppercase;
  font-family: 'Eurostile';
  margin-right: ${(props) => (props.marginRight ? '15px' : '0')};
  width: ${(props) => (props.center ? '100%' : 'auto')};
  ${space};
  ${layout};
  @media (max-width: 990px) {
    font-size: 12px;
  }
  `;
import { UiWrapper } from 'coinscrap-webapp-core-ts';

UiWrapper.defineComponent('inputRadio', {
  styles: {
    default: {
      flexDirection: 'row',
      flexWrap: 'nowrap',
      maxWidth: '100%',
      overflow: 'scroll',
    },
  },
});

import { Header } from 'components/Header/header';
import { H3 } from 'components/H3/H3';
import { MainButton } from 'components/MainButton/MainButton';
import { Editable } from 'components/Editable/Editable';

import { useState } from 'react';
import { NavigationWrapper, UiWrapper, SelectItem } from 'coinscrap-webapp-core-ts';
import { routes } from 'routes';
import * as S from './styles';
import { MarginWrapper } from '../../../layout/MarginWrapper/MarginWrapper';

export const AdvancedSummary = () => {
  const { navigateTo } = NavigationWrapper.use();
  const { useHeader } = UiWrapper.use();
  const [mercado, setMercado] = useState('1');
  const [ordenes, setOrdenes] = useState('1');
  const [loss, setLoss] = useState('1');
  const [minimo, setMinimo] = useState('10,00€');
  const [maximo, setMaximo] = useState('1.000,00€');
  const [apalancada, setApalancada] = useState('1');
  const [interes, setInteres] = useState('8');
  const [corto, setCorto] = useState('1');

  useHeader(<Header closeRoute={routes.tournamentHomeMain} />);
  return (
    <S.Wrapper>
      <S.Content>
        <MarginWrapper justifyContent="space-between">
          <S.Caja>
            <H3 marginBottom="20px" center>
              RESUMEN AVANZADO
            </H3>
            <Editable label="Mercado" value={mercado} tipo="select">
              <SelectItem value="0" disabled>
                Selecciona
              </SelectItem>
              <SelectItem value="1">IBEX-35</SelectItem>
              <SelectItem value="2">NASDAQ-100</SelectItem>
            </Editable>
            <Editable label="Ordenes Limitadas" value={ordenes} tipo="select">
              <SelectItem value="0" disabled>
                Selecciona
              </SelectItem>
              <SelectItem value="1">Sí</SelectItem>
              <SelectItem value="2">No</SelectItem>
            </Editable>
            <Editable label="Stop Loss" value={loss} tipo="select">
              <SelectItem value="0" disabled>
                Selecciona
              </SelectItem>
              <SelectItem value="1">Sí</SelectItem>
              <SelectItem value="2">No</SelectItem>
            </Editable>
            <Editable label="Volumen mínimo" value={minimo} tipo="text" />
            <Editable label="Volumen máximo" value={maximo} tipo="text" />
            <Editable label="Compra apalancada" value={apalancada} tipo="select">
              <SelectItem value="0" disabled>
                Selecciona
              </SelectItem>
              <SelectItem value="1">Sí</SelectItem>
              <SelectItem value="2">No</SelectItem>
            </Editable>
            <Editable label="Intereses de la compra apalancada" value={interes} tipo="select">
              <SelectItem value="0" disabled>
                porcentaje (%)
              </SelectItem>
              <SelectItem value="1">1%</SelectItem>
              <SelectItem value="2">2%</SelectItem>
              <SelectItem value="3">3%</SelectItem>
              <SelectItem value="4">4%</SelectItem>
              <SelectItem value="5">5%</SelectItem>
              <SelectItem value="6">6%</SelectItem>
              <SelectItem value="7">7%</SelectItem>
              <SelectItem value="8">8%</SelectItem>
            </Editable>
            <Editable label="Ventas en corto" value={corto} tipo="select">
              <SelectItem value="0" disabled>
                Selecciona
              </SelectItem>
              <SelectItem value="1">Sí</SelectItem>
              <SelectItem value="2">No</SelectItem>
            </Editable>
          </S.Caja>
          <MainButton
            pink
            full
            fullWidth
            marginTop="50px"
            onClick={() => {
              navigateTo(routes.tournamentShare);
            }}
          >
            SIGUIENTE
          </MainButton>
        </MarginWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

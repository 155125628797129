import { H1eurobold } from 'components/H1eurobold/H1eurobold';

import { MarginWrapper } from 'layout/MarginWrapper/MarginWrapper';
import { NavigationWrapper, Image, Page, UiWrapper, SessionWrapper } from 'coinscrap-webapp-core-ts';
import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import * as S from './styles';
import { CommonUtils } from '../../utils/CommonUtils';
import * as CommonStyles from '../../styles/common';
import { Api } from '../../api/api';
import { routes } from '../../routes';
import { Header } from '../../components/Header/header';

function AvatarList({ avatars, pageName, children, ...props }) {
  const { navigateTo } = NavigationWrapper.use();
  let { sessionData } = SessionWrapper.use();
  const fullScreen = new URLSearchParams(window.location.search).get('full_screen') || 'false';
  const list = avatars.map((avatar, index) => (
    <Image
      key={index}
      height="125px"
      width="125px"
      avatarSt
      src={avatar}
      style={{ cursor: 'pointer' }}
      onClick={() => {
        Api.instance.updateUserPhoto(avatar).then(user => {
          if (user?.photo) {
            sessionData.photo = user.photo;
          }
        });
        if (pageName) {
          navigateTo(pageName);
        } else {
          navigateTo(routes.tournamentHomeMain);
        }
      }}
    />
  ));
  return <S.Lista>{list}</S.Lista>;
}

export const CreateAvatar = () => {
  const [avatars, setAvatars] = useState([]);
  const [loadingAvatars, setLoadingAvatars] = useState(true);

  const { useHeader } = UiWrapper.use();
  const { query, full_screen: fullScreen } = Page.use();
  const { pageName } = query;


  useEffect(() => {
    setAvatars([]);
    for (let n = 0; n < 30; n++) {
      const st = `/game/img/avatars/avatar${CommonUtils.padded((n + 1), 3)}.png`
      setAvatars(arr => [...arr, st])
    }
    setLoadingAvatars(false);
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, 300)
  }, []);

  useHeader(<Header closeRoute={routes.tournamentHomeMain} />);

  return loadingAvatars ? (
    <CommonStyles.FullHeightLoadingContainer>
      <ReactLoading type="spinningBubbles" color="#ffffff" height="20%" width="20%" />
    </CommonStyles.FullHeightLoadingContainer>
  ) : (
    <S.Wrapper>
      <S.Content>
        <MarginWrapper justifyContent="space-between">
          <div>
            <H1eurobold marginBottom="2vh">Elige tu avatar</H1eurobold>
          </div>
          <AvatarList
            avatars={avatars}
            pageName={pageName}
          />
        </MarginWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

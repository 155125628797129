import { MainButton } from 'components/MainButton/MainButton';
import { H3 } from 'components/H3/H3';
import { P } from 'components/P/P';
import { Header } from 'components/Header/header';

import { useEffect, useState } from 'react';
import { MarginWrapper } from 'layout/MarginWrapper/MarginWrapper';
import {
  NavigationWrapper,
  InputDate,
  RadioOption,
  InputRadio,
  UiWrapper,
} from 'coinscrap-webapp-core-ts';
import { routes } from 'routes';
import * as S from './styles';
import {TournamentUtils, useTemporaryTournament} from '../../utils/TournamentUtils';
import { Api } from '../../api/api';

export const FechasTorneo = () => {
  const { navigateTo } = NavigationWrapper.use();
  const { useHeader } = UiWrapper.use();
  // const [final, setFinal] = useState();
  // const [inicio, setInicio] = useState();

  const [selecttime, setSelecttime] = useState('');
  const [booleantime, setbooleantime] = useState('true');

  const [tmpTournament, setTmpTournament] = useTemporaryTournament();

  useEffect(() => {
    let toAdd;
    switch (selecttime) {
      case '1s':
        toAdd = 86400 * 1000 * 7;
        break;
      case '2s':
        toAdd = 86400 * 1000 * 14;
        break;
      case '1m':
        toAdd = 86400 * 1000 * 31;
        break;
      default:
        return;
    }
    if (toAdd) {
      const start = new Date(Date.now() + 30 * 1000);
      setTmpTournament({

        start,
        end: new Date(start.getTime() + toAdd),
      });
    }
  }, [selecttime]);

  useEffect(() => {
    if (typeof tmpTournament.start === 'object' && typeof tmpTournament.end === 'object') {
      const days = Math.round(
        (tmpTournament.end.getTime() - tmpTournament.start.getTime()) / (86400 * 1000)
      );
      switch (days) {
        case 7:
          setSelecttime('1s');
          break;
        case 14:
          setSelecttime('2s');
          break;
        case 31:
          setSelecttime('1m');
          break;
        default:
          setSelecttime('');
          break;
      }
    }
  }, [tmpTournament]);

  useEffect(() => {
    let toAdd;
    switch (tmpTournament?.limitJoinDateOption) {
      case '2d':
        toAdd = 2;
        break;
      case '5d':
        toAdd = 5;
        break;
      case '7d':
        toAdd = 7;
        break;
      case '15d':
        toAdd = 15;
        break;
      default:
        return;
    }
    if (toAdd) {
      let today = new Date();
      today.setDate(today.getDate() + toAdd);
      setTmpTournament({

        limitJoinDate: new Date(today),
      });
    }
  }, [tmpTournament?.limitJoinDateOption]);

  function updateTmpTournamentStart(date) {
    if (!date) {
      return;
    }
    // eslint-disable-next-line no-param-reassign
    date = new Date(date.getTime() + 300 * 1000);
    if (date.getTime() >= Date.now()) {
      setTmpTournament({

        start: date,
        end: new Date(Math.max(date.getTime() + 86400 * 4 * 1000, tmpTournament.end.getTime())),
      });
    }
  }

  function updateTmpTournamentEnd(date) {
    if (!date) {
      return;
    }
    // eslint-disable-next-line no-param-reassign
    date = new Date(date.getTime() + 300 * 1000);
    if (date.getTime() - tmpTournament.start.getTime() >= 86400 * 1000) {
      setTmpTournament({

        end: date,
      });
    }
  }

  useHeader(<Header closeRoute={routes.tournamentHomeMain} />);
  return (
    <S.Wrapper>
      <S.Content>
        <MarginWrapper justifyContent="space-between">
          <S.Caja>
            <H3>Indica las fechas del torneo</H3>
            <S.Cajita>
              <S.MitadL>
                <InputDate
                  label="Fecha inicio"
                  value={tmpTournament.start}
                  minDate={new Date() + 86400 * 1000}
                  onChange={(e) => updateTmpTournamentStart(e)}
                  cursorSt
                  fullClickable
                  disabled
                />
              </S.MitadL>
              <S.MitadR>
                <InputDate
                  label="Fecha final"
                  value={tmpTournament.end}
                  minDate={new Date(tmpTournament.start.getTime() + 86400 * 4 * 1000)}
                  onChange={(e) => updateTmpTournamentEnd(e)}
                  cursorSt
                  fullClickable
                  disabled
                />
              </S.MitadR>
            </S.Cajita>
            <P color="white" bold left marginBottom="15px" marginTop="20px">
              Empieza hoy mismo
            </P>
            <S.Cajita>
              <InputRadio
                value={selecttime}
                onChange={(e) => {
                  setSelecttime(e.target.value);
                }}
              >
                <RadioOption value="1s" selectedSt={selecttime === '1s'}>
                  1 semana
                </RadioOption>
                <RadioOption value="2s" selectedSt={selecttime === '2s'}>
                  2 semanas
                </RadioOption>
                <RadioOption value="1m" selectedSt={selecttime === '1m'}>
                  1 mes
                </RadioOption>
              </InputRadio>
            </S.Cajita>

            <P color="white" bold left marginBottom="15px" marginTop="20px">
              ¿Hasta cuando se puede inscribir la gente?
            </P>
            <S.Cajita style={{display: 'none'}}>
              <InputRadio
                value={booleantime}
                onChange={(e) => {
                  setbooleantime(e.target.value);
                  if (e.target.value === 'false') {
                    setTmpTournament({

                      limitJoinDate: null,
                      limitJoinDateOption: null,
                    });
                  } else {
                    const today = new Date();
                    today.setDate(today.getDate() + 2);
                    setTmpTournament({  limitJoinDate: new Date(today), limitJoinDateOption: '2d' });
                  }
                }}
              >
                <RadioOption value="true" selectedSt={booleantime === 'true'}>
                  Si
                </RadioOption>
                <RadioOption value="false" selectedSt={booleantime === 'false'}>
                  No
                </RadioOption>
              </InputRadio>
            </S.Cajita>

            {booleantime === 'true' ? (
              <S.Cajita>
                <InputRadio
                  style={{ width: 320 }}
                  value={tmpTournament?.limitJoinDateOption}
                  onChange={(e) => {
                    setTmpTournament({ limitJoinDateOption: e.target.value});
                  }}
                >
                  <RadioOption value="2d" selectedSt={tmpTournament?.limitJoinDateOption === '2d'}>
                    2 Días
                  </RadioOption>
                  <RadioOption value="5d" selectedSt={tmpTournament?.limitJoinDateOption === '5d'}>
                    5 Días
                  </RadioOption>
                  <RadioOption value="7d" selectedSt={tmpTournament?.limitJoinDateOption === '7d'}>
                    7 Días
                  </RadioOption>
                  <RadioOption value="15d" selectedSt={tmpTournament?.limitJoinDateOption === '15d'}>
                    15 Días
                  </RadioOption>
                </InputRadio>
              </S.Cajita>
            ) : null}
          </S.Caja>
          <MainButton
            pink
            full
            fullWidth
            marginTop="10px"
            onClick={() => {
              navigateTo(routes.tournamentCreateVisibility);
            }}
          >
            SIGUIENTE
          </MainButton>
        </MarginWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

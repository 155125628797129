import React, { useState, useEffect } from 'react';
import { MainButton } from 'components/MainButton/MainButton';
import { H1slide } from 'components/H1slide/H1slide';
import { H2slide } from 'components/H2slide/H2slide';
import { Header } from 'components/Header/header';
import { NavigationWrapper, UiWrapper } from 'coinscrap-webapp-core-ts';
import { routes } from 'routes';
import ReactLoading from 'react-loading';
import {TournamentUtils, useTemporaryTournament} from '../../utils/TournamentUtils';
import * as CommonStyles from '../../styles/common';

import * as S from './styles';
import { Api } from '../../api/api';


export const Crear = () => {
  const { navigateTo } = NavigationWrapper.use();
  const { useHeader } = UiWrapper.use();
  const [loadingDelete, setLoadingDelete] = useState(false);

  const [tmpTournament, setTmpTournament] = useTemporaryTournament(null);

  useHeader(<Header canGoBack={false} closeRoute={routes.tournamentHomeMain} />);

  return loadingDelete ?
    (<CommonStyles.InlineLoadingContainer>
      <ReactLoading type="spinningBubbles" color="#ffffff" height="20%" width="20%" />
    </CommonStyles.InlineLoadingContainer>)
    :
    (<S.Wrapper>
      <S.Content>
        <H1slide center>Objetivo del torneo:</H1slide>
        <H2slide marginBottom="2vh">Mayor rentabilidad</H2slide>
        <S.Img src="/game/img/mayorrentabilidad.png" />
      </S.Content>

      <MainButton
        pink
        full
        fullWidth
        marginTop="10px"
        onClick={async () => {
          if (tmpTournament && tmpTournament.id) {
            setLoadingDelete(true);
            await Api.instance.deleteTmpTournament(tmpTournament);
            setLoadingDelete(false);
          }
          navigateTo(routes.tournamentCreateName);
        }}
      >
        EMPEZAR
      </MainButton>

      <S.TextP onClick={() => navigateTo(routes.tournamentCreateName)}>Continuar con mi último torneo</S.TextP>
    </S.Wrapper>);
};

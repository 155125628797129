import { Header } from 'components/Header/header';
import { H1bold } from 'components/H1bold/H1bold';
import { Pbig } from 'components/Pbig/Pbig';
import { MainButton } from 'components/MainButton/MainButton';
import ShareIcon from '@material-ui/icons/Share';

import { NavigationWrapper, UiWrapper, Image } from 'coinscrap-webapp-core-ts';
import { useEffect, useState } from 'react';
import * as S from './styles';
import { MarginWrapper } from '../../layout/MarginWrapper/MarginWrapper';
import { routes } from '../../routes';
import { CommonUtils } from '../../utils/CommonUtils';
import {useTemporaryTournament, useTournamentSaving} from '../../utils/TournamentUtils';

export const Share = () => {
  const { navigateTo } = NavigationWrapper.use();
  const { useHeader } = UiWrapper.use();
  const [pwdTournament, setPwdTournament] = useState(null);
  const [isPublic, setIsPublic] = useState(true);
  const [isCopied, setIsCopied] = useState(false);
  const [newTournamentName, setTournamentName] = useState(null);
  const [newTournamentId, setTournamentId] = useState(null);

  // const [saving] = useTournamentSaving();
  // const [tmpTournament, setTmpTournament] = useTemporaryTournament(null);

  useEffect(() => {
    setTournamentName(localStorage.getItem('new_tournament_name') || newTournamentName);
    setTournamentId(localStorage.getItem('new_tournament_id') || newTournamentId);

  }, [])

  const copyTextToClipboard = () => {
    const route =
      'https://senseizero.powlink.io/mksim-join-trn?entw=e9c6e8f3b657ff1d52ba7eef13f2954b&tourn_id=' +
      newTournamentId +
      '&share_code=' +
      pwdTournament;
    if (CommonUtils.pushToNative) {
      CommonUtils.pushToNative({
        body: {
          action: 'shareTournament',
          params: {
            route,
          },
        },
      });
    } else {
      navigator.clipboard
        .writeText(route)
        .then(() => {
          // If successful, update the isCopied state value
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, 3000);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  useEffect(() => {
    const newTournamentPwd = localStorage.getItem('new_tournament_password');
    const newTournamentPublic = localStorage.getItem('new_tournament_public');
    if (newTournamentPwd !== null) {
      setPwdTournament(newTournamentPwd);
    }
    if (newTournamentPublic !== null) {
      setIsPublic(newTournamentPublic);
    }
  }, [pwdTournament]);

  useEffect(() => {
    localStorage.setItem('should_restart_creation', 'true');
  }, []);

  useHeader(<Header canGoBack={false} closeRoute={routes.tournamentHomeMain} />);
  return (
    <S.Wrapper>
      <S.Content>
        <MarginWrapper justifyContent="space-between">
          <S.Caja>
            <Image height="54px" width="auto" marginBottomTopSt src="/game/img/senseizero.svg" />
            <H1bold marginBottom="40px">¡Enhorabuena por crear tu torneo!</H1bold>
            <Pbig marginBottom="30px" color="white" align="center" style={{ display: 'none' }}>
              Puedes invitar a cualquier persona a unirse a este juego. Comparte este enlace con tus
              contactos.
            </Pbig>
            <Pbig marginBottom="10px" color="white" align="center">
              Nombre del torneo
            </Pbig>
            <H1bold big>{newTournamentName}</H1bold>
            {(isPublic === 'false' || !isPublic) && (
              <Pbig marginTop="16px" marginBottom="10px" color="white" align="center">
                Código del torneo
              </Pbig>
            )}
            {(isPublic === 'false' || !isPublic) && (
              <S.Password onClick={copyTextToClipboard}>
                <H1bold big>
                  {pwdTournament}
                  <ShareIcon htmlColor="#fff" style={{ marginLeft: 10, fontSize: '30px' }} />
                </H1bold>
              </S.Password>
            )}
            {isCopied && (
              <Pbig marginBottom="10px" color="white" align="center">
                ¡Enlace copiado!
              </Pbig>
            )}
          </S.Caja>
          <MainButton
            pink
            full
            fullWidth
            marginTop="10px"
            disabled={!newTournamentId}
            onClick={() => {
              localStorage.removeItem('overviewTab');
              navigateTo(routes.tournamentDetailOverview, {
                routeParams: { id: newTournamentId },
                queryParams: { full_screen: true, },
                mode: 'replace',
                resetHistory: true,
              });
            }}
          >
            FINALIZAR
          </MainButton>
        </MarginWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

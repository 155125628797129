import styled from 'styled-components/macro';
import { verticalFlex } from 'styles/common';

export const Wrapper = styled.div`
  ${verticalFlex}
  align-items: center;
  background: var(--background-global);
  background-position: center;
  background-size: cover;
  justify-content: space-between;
  min-height: 100%;
  width: 100%;
`;

export const Content = styled.div`
  ${verticalFlex}
  flex: 1;
  height: 100%;
  justify-content: space-between;
  width: 100%;
`;

export const Caja = styled.div`
  ${verticalFlex}
  flex: 1;
  height: 100%;
  justify-content: start;
  max-width: 100%;
  padding: 0 20px;
  width: 100%;
`;

export const Img = styled.img`
  align-self: end;
  flex-grow: 1;
  height: 302px;
  padding-top: 33px;
`;

export const Cajita = styled.div`
  ${verticalFlex}
  flex-direction: row;
  justify-content: space-between;
  max-width: 100%;
`;

export const Div = styled.div`
  max-width: 100%;
  padding: 0 20px;
  width: 100%;
`;

import { UiWrapper } from 'coinscrap-webapp-core-ts';

UiWrapper.defineComponent('inputDate', {
  styles: {
    default: {
      containerComponent: {},
      inputContainerComponent: {
        backgroundColor: 'rgba(255,255,255,0.2)  !important',
        fontFamily: 'Roboto, sans-serif',
        margin: '30px 0 15px',
        border: '0',
        borderRadius: '10px',
        padding: '15px 10px 15px 0',
        flexDirection: 'row-reverse',
      },
      inputComponent: {
        padding: '0',
        fontSize: '16px',
        color: 'rgba(255,255,255,0.56)',
      },
      labelComponent: {
        color: 'white',
        paddingBottom: '5px',
        fontFamily: 'Roboto, sans-serif',
        fontSize: '16px',
        lineHeight: '16px',
        transform: 'translate(0px, 7px) scale(0.85) !important',
      },
      iconContainerComponent: {
        padding: '5px',
        marginLeft: '0',
      },
      iconComponent: {
        color: 'rgba(255,255,255,0.56) ',
      },
    },
    focusedMode: {
      inputComponent: {
        color: 'white',
      },
      iconComponent: {
        color: 'white',
      },
    },
    filledMode: {
      inputComponent: {
        color: 'white',
      },
      iconComponent: {
        color: 'white',
      },
    },
    cursorSt: {
      containerComponent: {
        cursor: 'pointer'
      },
      inputContainerComponent: {
        cursor: 'pointer'
      },
      inputComponent: {
        cursor: 'pointer'
      },
      iconContainerComponent: {
        cursor: 'pointer'
      },
      iconComponent: {
        cursor: 'pointer'
      },
    },
    editableSt: {
      containerComponent: {
        backgroundColor: 'transparent',
        width: '100%',
        borderRadius: '0',
        border: '0',
        borderBottom: '1px solid white',
        margin: '10px 0',
      },
      inputContainerComponent: {
        backgroundColor: 'transparent !important',
        margin: '0',
        border: '0',
        padding: '10px 0',
        flexDirection: 'initial',
      },
      inputComponent: {
        fontSize: '18px',
        color: 'white',
        fontFamily: 'EurostileBold',
        fontWeight: '100',
        padding: '0',
        paddingLeft: '0',
      },
      labelComponent: {
        color: 'white',
        fontSize: '14px',
        fontFamily: 'Helvetica',
        transform: 'translate(0px, -10px) scale(0.85) !important',
      },
      iconContainerComponent: {
        padding: '0',
        marginLeft: '0',
      },
      iconComponent: {
        color: '#6586D4',
      },
    },
  },
});

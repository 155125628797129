import { Header } from 'components/Header/header';
import { Pbig } from 'components/Pbig/Pbig';
import { H1slide } from 'components/H1slide/H1slide';
import { H1bold } from 'components/H1bold/H1bold';
import { Psmall } from 'components/Psmall/Psmall';

import { useEffect, useState } from 'react';
import { NavigationWrapper, Page, UiWrapper } from 'coinscrap-webapp-core-ts';
import ReactLoading from 'react-loading';
import * as S from './styles';
import { MarginWrapper } from '../../layout/MarginWrapper/MarginWrapper';
import { Api } from '../../api/api';
import { TournamentUtils } from '../../utils/TournamentUtils';
import * as CommonStyles from '../../styles/common';
import { P } from '../../components/P/P';
import { routes } from '../../routes';
import {CommonUtils} from '../../utils/CommonUtils';

function ExecutionsList({ executions, children, active, ...props }) {
  const { navigateTo } = NavigationWrapper.use();
  const list = executions.map((execution) => (
    <S.Cajatransparente
      key={execution.id}
      row
      border
      onClick={() => {
        // navigateTo(routes.tournamentCompetitor);
      }}
    >
      <S.CajaDere>
        <S.Flex>
          {/* <S.Ico sinmargin src={execution.saleOrder?.user?.photo || '/game/img/usuario2.svg'} /> */}
          <S.Column>
            <P nowrap marginBottom="5px" color="white" fontSize="10px">
              <span>{execution.saleOrder?.instrument?.displayName}</span> (
              {execution.saleOrder?.instrument?.ticker})
            </P>
            <Psmall nowrap color="#b9b9b9">
              <S.Trades src="/game/img/trades.svg" />
              <S.Span color="#b9b9b9">
                {execution.executedTitles} (
                {TournamentUtils.formatCurrency(
                  execution.executionPrice * execution.executedTitles, 2, true, execution.saleOrder?.wallet?.tournament?.market?.currency
                )}
                )
              </S.Span>
            </Psmall>
          </S.Column>
        </S.Flex>
      </S.CajaDere>
      <S.CajaIzq>
        <P
          nowrap
          marginBottom="5px"
          color={TournamentUtils.signedColor(execution.absProfit)}
          align="right"
        >
          {execution.absProfit >= 0 ? '+' : ''}{' '}
          {TournamentUtils.formatCurrency(execution.absProfit, 2, true, execution.saleOrder?.wallet?.tournament?.market?.currency)}
        </P>
        <Psmall
          nowrap
          marginBottom="5px"
          align="right"
          color={TournamentUtils.signedColor(execution.profitability)}
        >
          {execution.profitability > 0 ? <S.Top src="/game/img/top.svg" /> : null}
          <S.Span color={TournamentUtils.signedColor(execution.profitability)}>
            {TournamentUtils.formatDecimals(execution.profitability * 100, 1)}%
          </S.Span>
        </Psmall>
      </S.CajaIzq>
    </S.Cajatransparente>
  ));
  if (executions?.length <= 0)
    return <S.White active={active}>Actualmente no se ha realizado ninguna venta</S.White>;
  return <S.Lista active={active}>{list}</S.Lista>;
}

export const Competitor = () => {
  const { navigateTo } = NavigationWrapper.use();
  const [selectab, setSelecttab] = useState('min');
  const [user, setUser] = useState([]);
  const [isMe, setIsMe] = useState();
  const [wallets, setWallets] = useState([]);
  const [lastSaleExecutions, setLastSaleExecutions] = useState([]);
  const [bestExecutionsByProfitability, setBestExecutionsByProfitability] = useState([]);
  const [lowerExecutionsByAbsProfit, setLowerExecutionsByAbsProfit] = useState([]);
  const [higherExecutionsByAbsProfit, setHigherExecutionsByAbsProfit] = useState([]);
  const { useHeader } = UiWrapper.use();
  const { params, query } = Page.use();
  const { id: userId } = params;

  const [loadingUser, setLoadingUser] = useState(true);

  useEffect(() => {
    if (wallets.length) {
      let allSaleExecutions = [];

      for (let i = 0; i < wallets.length; i++) {
        const wallet = wallets[i];
        for (let j = 0; j < wallet.saleOrders.length; j++) {
          const saleOrder = wallet.saleOrders[j];
          allSaleExecutions = [
            ...allSaleExecutions,
            ...saleOrder.saleExecutions.map((it) => ({
              ...it,
              saleOrder: {...saleOrder, saleExecutions: [], wallet: {...wallet, saleOrders: []}, user: wallet.user},
            })),
          ];
        }
      }

      CommonUtils.sortBy(allSaleExecutions,
          // [it => it.executedTitles * it.executionPrice, -1],
          ['executionDate', -1],
          ['idExecution', -1],
          ['id', -1],
      );

      setBestExecutionsByProfitability(
          CommonUtils.sortBy([...allSaleExecutions], ['profitability', -1]).slice(0, 3)
      );
      setLowerExecutionsByAbsProfit(
          CommonUtils.sortBy([...allSaleExecutions],
              ['absProfit', 1],
              [it => it.executedTitles * it.executionPrice, 1],
          ).slice(0, 3)
      );
      setHigherExecutionsByAbsProfit(
          CommonUtils.sortBy([...allSaleExecutions], ['absProfit', -1]).slice(0, 3)
      );

      // setLastSaleExecutions(allSaleExecutions.slice(0, 3));
      setLastSaleExecutions(allSaleExecutions);
    }
  }, [wallets]);

  useEffect(() => {
    // eslint-disable-next-line no-shadow
    Api.instance.findUserById(userId).then((user) => {
      if (user) {
        setUser({
          ...user,
        });
        setWallets(user.wallets);
        setLoadingUser(false);
      }
    });
  }, []);

  useEffect(() => {
    Api.instance.getCurrentUser().then((userApi) => {
      if (userApi.id === userId) {
        setIsMe(true);
      } else {
        setIsMe(false);
      }
    });
  }, [userId]);

  useHeader(<Header closeRoute={routes.tournamentHomeMain} />);
  return loadingUser ? (
    <CommonStyles.FullHeightLoadingContainer>
      <ReactLoading type="spinningBubbles" color="#ffffff" height="20%" width="20%" />
    </CommonStyles.FullHeightLoadingContainer>
  ) : (
    <S.Wrapper>
      <S.Content>
        <MarginWrapper justifyContent="start">
          <S.Caja>
            <S.IcoG
              sinmargin
              src={user?.photo ? user?.photo : '/game/img/avatars/avatar001.png'}
              style={{ cursor: isMe ? 'pointer' : 'default' }}
              onClick={() => {
                if (isMe) {
                  navigateTo(routes.createAvatar, {
                    queryParams: { pageName: routes.tournamentHomeMain, showHeader: 'true' },
                  });
                }
              }}
            />
            <H1slide marginBottom="15px" center>
              {user?.username}
            </H1slide>
            <Pbig color="white" align="center">
              Total ganado
            </Pbig>
            <H1bold
              marginBottom="30px"
              center
              color={TournamentUtils.signedColor(user?.totalProfit)}
            >
              {TournamentUtils.formatCurrency(user?.totalProfit)}
            </H1bold>
            <S.Tab>
              <S.TabTitle
                active={selectab === 'best'}
                onClick={(e) => {
                  setSelecttab('best');
                }}
              >
                Mejor resultado
              </S.TabTitle>
              <S.TabTitle
                active={selectab === 'min'}
                onClick={(e) => {
                  setSelecttab('min');
                }}
              >
                Menor beneficio
              </S.TabTitle>
              <S.TabTitle
                active={selectab === 'max'}
                onClick={(e) => {
                  setSelecttab('max');
                }}
              >
                Mayor beneficio
              </S.TabTitle>
            </S.Tab>
            {/*TODO: Separate executions*/}
            <ExecutionsList
              executions={bestExecutionsByProfitability}
              active={selectab === 'best'}
            />
            <ExecutionsList executions={lowerExecutionsByAbsProfit} active={selectab === 'min'} />
            <ExecutionsList executions={higherExecutionsByAbsProfit} active={selectab === 'max'} />
          </S.Caja>
        </MarginWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

import delay from 'delay';
import {useEffect, useState} from 'react';
import { Api } from '../api/api';
import { CommonUtils } from './CommonUtils';
import {MemoryState} from './MemoryState';

export class TournamentUtils {

  static get savingQueue() {
    return TournamentUtils._savingQueue || [];
  }

  static formatCurrency(amount, decimals = 2, useThousandSeparator = true, currency = 'EUR') {
    const currencySymbol = (currency && currency.trim().replace('EUR', '€').replace('USD', '$') || (currency && currency.trim())) || '€';
    if (!amount || Number.isNaN(amount)) {
      return `0 ${currencySymbol}`;
    }
    // eslint-disable-next-line no-param-reassign
    amount = Math.round(amount * 10 ** decimals) / 10 ** decimals;
    let numberPart;
    numberPart = amount.toLocaleString('es', {
      maximumFractionDigits: decimals,
      minimumFractionDigits: decimals,
    });
    if (amount < 10000) {
      numberPart = numberPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
    let res = `${numberPart} ${currencySymbol}`;
    if (!useThousandSeparator) {
      if (decimals > 0) {
        const withoutCurrency = res.replace(/\D+$/, '');
        const floatPartRegex = /(\D)(\d+)$/.exec(withoutCurrency);
        const floatSeparator = floatPartRegex[1];
        const floatPart = floatPartRegex[2];
        const intPart = withoutCurrency.substring(0, floatPartRegex.index);
        res = `${intPart.replace(/\D+/g, '')}${floatSeparator}${floatPart} ${currencySymbol}`;
      } else {
        res = res.replace(/\D/g, '');
      }
    }
    return res;
  }

  static formatDecimals(amount, decimals = 2, useMillarSeparator = true) {
    if (Number.isNaN(amount)) {
      // eslint-disable-next-line no-param-reassign
      amount = 0.0;
    }
    // eslint-disable-next-line no-param-reassign
    amount = Math.round(amount * 10 ** decimals) / 10 ** decimals;
    let res = amount.toLocaleString('es', {
      maximumFractionDigits: decimals,
      minimumFractionDigits: decimals,
    });
    if (!useMillarSeparator) {
      if (decimals > 0) {
        const withoutCurrency = res.replace(/\D+$/, '');
        const floatPartRegex = /(\D)(\d+)$/.exec(withoutCurrency);
        const floatSeparator = floatPartRegex[1];
        const floatPart = floatPartRegex[2];
        const intPart = withoutCurrency.substring(0, floatPartRegex.index);
        res = `${intPart.replace(/\D+/g, '')}${floatSeparator}${floatPart}`;
      } else {
        res = res.replace(/\D/g, '');
      }
    }
    return res;
  }

  /**
   * @param {function(): object} tournamentGetter
   * @param {function(tournament: Tournament): void} cb
   */
  static saveTemporaryTournamentAsync(tournamentGetter, cb) {
    let prevTournament;
    return () => {
      const partial = tournamentGetter();
      const newTmpTournament = {...partial, id: partial.id || MemoryState.tmpTournament?.id};
      if (['CREATED', 'STARTED'].includes(newTmpTournament.status)) {
        TournamentUtils.savingQueue.splice(0, Math.max(0, TournamentUtils.savingQueue.length - 1))
      } else {
        localStorage.removeItem('stop_saving');
      }
      return new Promise((resolve) => {
        if (newTmpTournament.id) {

          // setTimeout(() => {
            TournamentUtils._savingQueue = TournamentUtils._savingQueue || [];
            TournamentUtils.savingQueue.push(async () => {
              // if (prevTournament && (!newTmpTournament.name || !newTmpTournament.name.trim())) {
              //   newTmpTournament.name = prevTournament.name
              // }
              prevTournament = newTmpTournament;
              await Api.instance.updateTmpTournament(newTmpTournament).then((tournamentFromApi) => {
                let normalized;
                if (tournamentFromApi) {
                  normalized = CommonUtils.normalizeTournament(tournamentFromApi);
                  if (['CREATED', 'STARTED'].includes(tournamentFromApi.status)) {
                    MemoryState.tmpTournament = null;
                    localStorage.setItem('stop_saving', 'true');
                    // TournamentUtils.savingQueue.splice(0, TournamentUtils.savingQueue.length)
                    resolve(newTmpTournament);
                  } else {
                    // this.updateTemporaryTournamentInMemory(normalized);
                    resolve(normalized || newTmpTournament);
                  }
                } else {
                  resolve(newTmpTournament);
                }
              }).catch(reason => {
                if (MemoryState.tmpTournament) {
                  delete MemoryState.tmpTournament.id;
                }
              });
            });
            this.processSavingQueue();
          // });
        } else {
          console.log('skip saving', newTmpTournament);
        }
      }).then(value => {
        if (cb) {
          cb(value);
        }
      });
    };
  }

  static processSavingQueue() {
    if (this._processingSavingQueue) {
      return;
    }
    this._processingSavingQueue = true;

    let n = 0;
    const processNextItem = async () => {
      const [cb] = TournamentUtils.savingQueue.splice(0, 1);
      if (cb) {
        console.log('process saving', ++n, 'pending', TournamentUtils.savingQueue.length);
        await cb();
      } else {
        console.log('wait for next saving at', n);
        await delay(67 * 5);
      }
    }

    setTimeout(async () => {
      while (true) {
        await processNextItem();
        if (localStorage.getItem('stop_saving')) {
          TournamentUtils.savingQueue.splice(0, TournamentUtils.savingQueue.length);
          this._processingSavingQueue = false
          break;
        }
      }
    });
  }

  static signedColor(f, zeroIsUp = true) {
    if (f > 0 || (zeroIsUp && f === 0)) {
      return '#2D923E';
    }
    if (f < 0) {
      return '#922d2d';
    }
    return '#FFFFFF';
  }

  static processBuyOrderExecutions(buyOrder, wallet) {
    const newBuyExecutions = [];
    let pendingTitlesToDistribute = buyOrder.remainingTitles;
    CommonUtils.sortBy(buyOrder.buyExecutions, ['executionDate', 1]);
    for (let j = 0; j < buyOrder.buyExecutions.length; j++) {
      const buyExecution = buyOrder.buyExecutions[j];
      buyExecution.remainingTitles = Math.min(
        buyExecution.executedTitles,
        pendingTitlesToDistribute
      );
      pendingTitlesToDistribute -= buyExecution.remainingTitles;
      newBuyExecutions.push({
        ...buyExecution,
        buyOrder: { ...buyOrder, wallet, buyExecutions: [] },
      });
    }
    buyOrder.buyExecutions = newBuyExecutions;
  }

  static saving() {
    return TournamentUtils.savingQueue.length > 0;
  }

  static clearSaveQueue() {
    localStorage.setItem('stop_saving', 'true');
    TournamentUtils.savingQueue.splice(0, TournamentUtils.savingQueue.length);
  }
}

export const useTemporaryTournament = (defaultValue = undefined) => {
  const tmpTournamentInMemory = MemoryState.loadTemporaryTournamentFromMemory(defaultValue);

  const [tmpTournamentInState, setTmpTournamentInState] = useState( tmpTournamentInMemory);

  let lastSaveAttempt = 0;
  const saveFn = TournamentUtils.saveTemporaryTournamentAsync(() => ({
    ...MemoryState.loadTemporaryTournamentFromMemory()
  })/*, setTmpTournamentInState*/);

  const setTmpTournament = (partialTournament) => {
    MemoryState.updateTemporaryTournamentInMemory(partialTournament);
    setTmpTournamentInState(MemoryState.loadTemporaryTournamentFromMemory());
    if ((Date.now() - lastSaveAttempt) >= 1000) {
      saveFn();
      // setTmpTournamentInState(partialTournament);
    } else {
      // setTmpTournamentInState(partialTournament);
      setTimeout(() => {
        setTmpTournament(MemoryState.loadTemporaryTournamentFromMemory());
        // setTmpTournamentInState(MemoryState.loadTemporaryTournamentFromMemory());
      }, 1001);
    }
    lastSaveAttempt = Date.now();
  }

  useEffect(() => {
    // saveFn();
  }, [tmpTournamentInState]);

  // let memoryPriority = false;
  useEffect(() => {
    lastSaveAttempt = Date.now();

    if (MemoryState.tmpTournament?.id) {
      return;
    }
    Api.instance.findOrCreateTmpTournament().then((tournamentFromAPI) => {
      if (tournamentFromAPI) {

        let toSet;

        // if (memoryPriority) {
          toSet = {...tournamentFromAPI, ...(MemoryState.loadTemporaryTournamentFromMemory()), id: tournamentFromAPI.id};
          MemoryState.updateTemporaryTournamentInMemory({id: MemoryState.tmpTournament?.id || toSet.id});
        // } else {
        //   toSet = {...tournamentFromAPI};
        //   MemoryState.updateTemporaryTournamentInMemory(toSet);
        // }
        // memoryPriority = true;

        setTmpTournamentInState(toSet);
      }
    });

  }, []);

  return [tmpTournamentInState, setTmpTournament];
}

export const useTournamentSaving = () => {
  const [saving, setSaving] = useState(TournamentUtils.saving());

  let id;
  id = setInterval(() => {
    setSaving(TournamentUtils.saving());
  }, 67);

  useEffect(() =>
      () => {
        clearInterval(id);
      }, [saving]);

  return [saving];
}

export const useInterval = (delayMillis) => {
  const [step, setStep] = useState(0);
  useEffect(() => {
    let _step = 0;
    const id = setInterval(() => {
      console.log('interval: incrementing step', _step);
      setStep(++_step);
    }, delayMillis);
    return () => clearInterval(id);
  }, [delayMillis]);
  return [step];
}

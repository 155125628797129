import { UiWrapper } from 'coinscrap-webapp-core-ts';

UiWrapper.defineComponent('image', {
  styles: {
    default: {},
    marginBottomSt: {
      marginBottom: '10px',
    },
    fillwhiteSt: {
      filter: 'grayscale(100%) brightness(200%)',
    },
    marginBottomTopSt: {
      margin: '40px auto',
    },
    avatarSt: {
      // maxWidth: 150,
      margin: 10,
    }
  },
});

import { UiWrapper } from 'coinscrap-webapp-core-ts';

UiWrapper.defineComponent('input', {
  styles: {
    default: {
      width: '100%',
      background: 'rgba(255,255,255,0.2)',
      fontFamily: 'Roboto, sans-serif',
      color: 'rgba(255,255,255,0.56)',
      padding: '15px',
      margin: '15px 0',
      border: '0',
      borderRadius: '10px',
      fontSize: '16px'
    },
    inputwhiteSt: {
      background: 'white',
      fontSize: '14px',
      color: '#979797',
      padding: '20px 12px',
    },
  },
});

import styled from 'styled-components/macro';
import { space, layout } from 'styled-system';

export const H4 = styled.h4`
  font-family: 'Roboto', sans-serif;
  color: var(--color-white);
  font-size: 16px;
  line-height: 5vw;
  text-align: left;
  width: 100%;
  ${space};
  ${layout};
`;
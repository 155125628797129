import * as S from './styles';

export const Pbig = ({ children, className, align, color, marginTop, marginBottom, paddingX, nowrap }) => (
  <S.Pbig
    className={className + ' pbig'}
    align={align}
    color={color}
    nowrap={nowrap}
    marginTop={marginTop}
    marginBottom={marginBottom}
    paddingX={paddingX}
  >
    {children}
  </S.Pbig>
);

import * as S from './styles';

export const H1bold = ({ children, className, marginBottom, big, paddingX, color }) => (
  <S.H1regular
    className={className + ' h1 '}
    marginBottom={marginBottom}
    paddingX={paddingX}
    big={big}
    color={color}
  >
    {children}
  </S.H1regular>
);

/* eslint-disable no-nested-ternary */
import styled from 'styled-components/macro';
import { space, layout } from 'styled-system';

export const Psmall = styled.p`
  bottom: ${(props) => (props.absolute ? '5px' : 'initial')};
  color: ${(props) => props.color};
  font-family: ${(props) => (props.bold ? 'HelveticaBold' : 'Helvetica')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : (props.big ? '16px' : '14px'))};
  font-weight: 400;
  line-height: 18px;
  position: ${(props) => (props.absolute ? 'absolute' : 'relative')};
  right: ${(props) => (props.absolute ? '10px' : 'initial')};
  text-align: ${(props) => props.align};
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: ${(props) => (props.nowrap ? 'nowrap' : 'initial')};
  ${space};
  ${layout};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0')};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '0')};
  @media (max-width: 990px) {
    font-size: ${(props) => (props.fontSize ? props.fontSize : (props.big ? '12px' : '11px'))};
    line-height: 14px;
  }
`;

import styled from 'styled-components/macro';
import { verticalFlex } from 'styles/common';

export const Wrapper = styled.div`
  ${verticalFlex}
  align-items: center;
  justify-content:space-between;
  width: 100%;
  min-height: 100%;
`;

export const Content = styled.div`
  ${verticalFlex}
  flex: 1;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
  height: 100%;
`;

export const Caja = styled.div`
  flex-direction: column;
  justify-content: start;
  width: 100%;
  align-items: start;
  display: ${(props) => (props.active ? 'flex' : 'none')};
`;

export const Cajatransparente = styled.div`
  display: ${(props) => (props.noactive ? 'none' : 'flex')};
  flex-direction: ${(props) => (props.row ? 'row' : 'column')};
  max-width: ${(props) => (props.midwith ? '70%' : '100%')};
  width: ${(props) => (props.midwith ? '70%' : '100%')};
  margin-right: ${(props) => (props.marginright ? '10px' : '0')};
  background: transparent;
  border-radius: ${(props) => (props.border ? '0' : '4px')};
  padding-bottom: ${(props) => (props.border ? '10px' : '0')};
  margin-bottom: 10px;
  border-bottom: ${(props) => (props.border ? '1px solid rgba(255,255,255,0.3)' : '0')};
  align-items: center;
  cursor: pointer;
`;

export const CajaDere = styled.div`
  display: flex;
  width: 64%;
  align-items: end;
`;

export const CajaIzq = styled.div`
  display: flex;
  flex-direction: column;
  width: 36%;
  align-items: end;
  justify-content: center;
`;

export const Ico = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50px;
  border: 2px solid white;
  margin-bottom: ${(props) => (props.sinmargin ? '0' : '-20px')};
  margin-right: 8px;
  z-index: 999;
`;

export const Tab = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;
`;

export const TabTitle = styled.h2`
  font-family: Helvetica;
  font-size: 16px;
  padding: 0 15px 10px;
  color: ${(props) => (props.active ? '#01A8FA' : 'white')};
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  cursor: pointer;
  @media (max-width: 990px) {
    font-size: 12px;
  }
`;

export const TabTitleMercado = styled.h2`
  color: ${(props) => (props.active ? '#01A8FA' : 'white')};
  font-size: 12px;
  flex: 1;
  align-self: center;
  text-align: center;
  text-transform: uppercase;
  /* font-family: 'Eurostile'; */
  font-family: Helvetica;
  cursor: pointer;
`;

export const Span = styled.span`
  padding-right: ${(props) => (props.paddingright ? '5px' : '0')};
  color: ${(props) => (props.color)};
  margin-right: ${(props) => (props.marginright ? '5px' : '0')};
`;

export const Top = styled.img`
  width: auto;
  height: 8px;
  padding-right: 5px;
  margin-left: ${(props) => (props.marginleft ? '10px' : '0')};
`;

export const TopBig = styled.img`
  width: auto;
  height: 20px;
  padding-right: 15px;
  margin-left: ${(props) => (props.marginleft ? '10px' : '0')};
  vertical-align: middle;
`;

export const Lista = styled.div`
  display:flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  margin-bottom: ${(props) => (props.marginBottom ? '15px' : '0')};
`;

export const ListTabs = styled.div`
  display: ${(props) => (props.active ? 'flex' : 'none')};
  flex-direction: column;
  width: 100%;
  align-items: start;
  margin-bottom: ${(props) => (props.marginBottom ? '15px' : '0')};
  max-height: 60vh;
  overflow: scroll;
  @media (max-width: 990px) {
    max-height: auto;
  }
`;

export const Flex = styled.div`
  align-items: center;
  display: flex;
  max-width: 100%;
  width: 100%;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: calc(100% - 55px);
`;

export const Trades = styled.img`
  width: auto;
  height: 16px;
  padding-right: 5px;
  margin-left: ${(props) => (props.marginleft ? '10px' : '0')};
  vertical-align: middle;
`;

export const Separator = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Line = styled.div`
  height: 1px;
  background: white;
  flex-grow: 1;
`;

export const SpanSeparator = styled.span`
  border: 1px solid white;
  padding: 0 5px;
  margin: ${(props) => (props.noactive ? '10px' : '0 10px')};
  color: var(--color-white);
  font-size: 11px;
  line-height: 3.5vh;
  text-transform: uppercase;
  font-family: 'Eurostile';
  display: ${(props) => (props.noactive ? 'none' : 'block')};
  cursor: pointer;
`;

export const Ranquing = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50px;
  border: ${(props) => (props.active ? '1px solid #01A8FA' : '1px solid white')};
  margin-right: 8px;
`;

export const More = styled.img`
  width: auto;
  height: 12px;
  padding: 0 0 0 50px;
  margin-bottom: 5px;
  display: ${(props) => (props.noactive ? 'none' : 'block')};
`;

export const Row = styled.div`
  margin: 0 -20px;
  width: calc(100% + 40px);
  margin-bottom: ${(props) => (props.marginBottom)};
  position: relative
`;

export const CajaBlanca = styled.div`
  background: white;
  border-radius: 20px;
  padding: 0px 20px;
  width: auto;
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  justify-content: center;
  align-items:center;
`;

export const Filtro = styled.div`
  font-family: HelveticaBold;
  font-size: 12px;
  color: ${(props) => (props.active ? '#01A8FA' : 'black')};
  border-bottom: ${(props) => (props.active ? '2px solid #01A8FA' : '2px solid white')};
  border-top: ${(props) => (props.active ? '2px solid #01A8FA' : '2px solid white')};
  padding: 6px 10px 4px;
  cursor: pointer;
`;


export const Password = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  padding: 5px 0;
  cursor: pointer;
`;
import { Header } from 'components/Header/header';
import { H1small } from 'components/H1small/H1small';
import { P } from 'components/P/P';
import { Psmall } from 'components/Psmall/Psmall';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import JoinIcon from '@material-ui/icons/MeetingRoomOutlined';
import Join2Icon from '@material-ui/icons/EmojiEventsOutlined';
import CreateIcon from '@material-ui/icons/PostAddOutlined';
import PeopleIcon from '@material-ui/icons/PersonAddOutlined';

import { useEffect, useState } from 'react';
import { Image, NavigationWrapper, UiWrapper } from 'coinscrap-webapp-core-ts';
import { routes } from 'routes';
import ReactLoading from 'react-loading';
import * as S from './styles';
import { MarginWrapper } from '../../../layout/MarginWrapper/MarginWrapper';
import { Api } from '../../../api/api';
import * as CommonStyles from '../../../styles/common';
import { TournamentUtils } from '../../../utils/TournamentUtils';
import { CommonUtils } from '../../../utils/CommonUtils';
import { navigateToNative } from '../../../routes/routes';
import LockIcon from '../../../assets/svg/lock.svg';

function MyTournamentsList({ tournaments, isFinishedTmt, children, ...props }) {
  const { navigateTo } = NavigationWrapper.use();
  const fullScreen = new URLSearchParams(window.location.search).get('full_screen') || 'false';

  const list = tournaments.map((tournament) => {
    const topTraderStyle = {};
    if (tournament.myPosition === 0) {
      topTraderStyle.display = 'none';
    }

    return (
      <S.Cajatransparente
        key={tournament.id}
        onClick={() => {
          localStorage.removeItem('overviewTab');
          if (fullScreen !== 'true' && CommonUtils.pushToNative) {
            CommonUtils.pushToNative({
              body: {
                action: 'goFullScreen',
                params: {
                  route: '/tournament/detail/overview/' + tournament.id,
                  querySt: `?q=&full_screen=true${isFinishedTmt ? '&isEndTmt=true' : ''}`,
                  queryParams: isFinishedTmt ? { isEndTmt: isFinishedTmt } : {},
                },
              },
            });
          } else {
            navigateTo(routes.tournamentDetailOverview, {
              routeParams: { id: tournament.id },
              queryParams: { full_screen: 'true', isEndTmt: isFinishedTmt }
            });
          }
        }}
      >
        <S.CajaAzulDegradado>
          <S.CajaDere>
            <S.Ico src={tournament.user.photo} />
            <P nowrap color="white">
              {tournament.name || 'Torneo sin nombre'}
            </P>
          </S.CajaDere>
          <S.CajaIzq hidden={isFinishedTmt}>
            <Psmall nowrap align="center" color="white">
              {CommonUtils.formatTimeInterval('Resta{verbPlural} %n %s', tournament.end.getTime() - Date.now())}
            </Psmall>
          </S.CajaIzq>
          <S.Calificacion hidden={isFinishedTmt} src="/game/img/oro.svg" />
        </S.CajaAzulDegradado>
        <S.CajaInterior>
          <S.CajaDer>
            <Psmall nowrap color="black" marginBottom="5px" style={topTraderStyle}>
              <S.Span paddingright>1º</S.Span>
              <S.Span>{tournament.wallets[0]?.user?.username}</S.Span>
            </Psmall>
            <S.More src="/game/img/more.svg" style={topTraderStyle} />
            <P nowrap color="#1044C0" marginTop="5px">
              <S.Span paddingright="15px">{tournament?.myWallet?.hasPositions ? (tournament.myPosition + 1) + 'º' : ''}</S.Span>
              <S.Span>Tú</S.Span>
            </P>
          </S.CajaDer>
          <S.CajaIzq>
            <Psmall nowrap align="right" black marginBottom="15px" style={topTraderStyle}>
              <S.Span
                color={!tournament.wallets[0]?.hasPositions ? 'lightgray' : TournamentUtils.signedColor(tournament.wallets[0]?.profitability)}
                marginright
                style={{ fontSize: '10px' }}
              >
                {(tournament.wallets[0]?.profitability * 100.0).toFixed(2).replace('.', ',')}%
              </S.Span>
              {TournamentUtils.formatCurrency(
                tournament.wallets[0]?.balance + (tournament.wallets[0]?.remainingValue || 0), 2, true, tournament.market?.currency
              )}
            </Psmall>
            <Psmall nowrap align="right" color="black">
              {tournament?.myWallet?.hasPositions && tournament?.myWallet?.profitability >= 0 ? <S.Top src="/game/img/top.svg" /> : null}
              <S.Span
                color={!tournament?.myWallet?.hasPositions ? 'lightgray' : TournamentUtils.signedColor(tournament?.myWallet?.profitability)}
                marginright
              >
                {(tournament?.myWallet?.profitability * 100.0).toFixed(2).replace('.', ',')}%
              </S.Span>
              {TournamentUtils.formatCurrency(
                tournament?.myWallet?.balance + (tournament?.myWallet?.remainingValue || 0), 2, true, tournament.market?.currency
              )}
            </Psmall>
          </S.CajaIzq>
        </S.CajaInterior>
      </S.Cajatransparente>
    );
  });
  return <S.Responsive style={{ width: '100%' }}>{list}</S.Responsive>;
}

function AvailableTournamentsList({ tournaments, children, ...props }) {
  const { navigateTo } = NavigationWrapper.use();
  const fullScreen = new URLSearchParams(window.location.search).get('full_screen') || 'false';

  // eslint-disable-next-line no-nested-ternary
  const list = CommonUtils.sortBy(tournaments, [it => it.public ? -1 : 1, 1], ['end', 1]).map((tournament) =>
  (
    <S.Cajatransparente
      midwith
      marginright
      key={tournament.id}
      onClick={() => {
        if (fullScreen !== 'true' && CommonUtils.pushToNative) {
          CommonUtils.pushToNative({
            body: {
              action: 'goFullScreen',
              params: {
                route: '/tournament/join/' + tournament.id,
                querySt: '?q=&full_screen=true',
                queryParams: {},
              },
            },
          });
        } else {
          navigateTo(routes.tournamentJoin, {
            routeParams: { id: tournament.id },
            queryParams: { can_go_back: fullScreen === 'true', full_screen: 'true' },
          });
        }
      }}
    >
      <S.CajaColor color="#65C7D4">
        <div style={{ display: 'flex', flexGrow: 1, width: '100%', justifyContent: 'flex-end' }}>
          <Image height="10px" width="auto" src={LockIcon} style={{ opacity: tournament.public ? 0 : 1 }} />
        </div>
        <div
          style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'end' }}
        >
          <S.CajaDere>
            <S.Ico src={tournament.user.photo} />
            <P nowrap color="white">
              {tournament.name || 'Torneo sin nombre'}
            </P>
          </S.CajaDere>
          <S.CajaIzq>
            <Psmall nowrap align="center" color="white">
              {CommonUtils.formatTimeInterval('Resta{verbPlural} %n %s', tournament.end.getTime() - Date.now())}
            </Psmall>
          </S.CajaIzq>
        </div>
      </S.CajaColor>
      <S.CajaInterior>
        <S.Column>
          <Psmall nowrap color="black" marginBottom="5px">
            <S.Span marginright>1º</S.Span>
            <S.Span>{tournament.wallets[0]?.user?.username}</S.Span>
            {tournament.wallets[0]?.profitability >= 0 ? (
              <S.Top marginleft src="/game/img/top.svg" />
            ) : null}
            <S.Span
              color={TournamentUtils.signedColor(tournament.wallets[0]?.profitability)}
              marginright
            >
              {(tournament.wallets[0]?.profitability * 100.0).toFixed(2).replace('.', ',')}%
            </S.Span>
          </Psmall>
          <Psmall nowrap color="black" marginTop="5px" style={{ display: 'none' }}>
            <S.Usu src="/game/img/usuario1.svg" />
            <S.Usu src="/game/img/usuario2.svg" /> 24
          </Psmall>
        </S.Column>
      </S.CajaInterior>
    </S.Cajatransparente>
  ));

  const allBtn = (
    <S.CajaVerTodos
      onClick={() => navigateToNative(navigateTo, routes.tournamentSearch, fullScreen)}
    >
      <H1small marginright>VER TODOS</H1small>
    </S.CajaVerTodos>
  );

  const lista = [list.slice(0, 5), tournaments?.length > 5 && allBtn];
  return <S.Scroll style={{ width: '100%' }}>{lista}</S.Scroll>;
}

function BuyExecutionsList({ buyExecutions, children, ...props }) {
  const { navigateTo } = NavigationWrapper.use();
  const fullScreen = new URLSearchParams(window.location.search).get('full_screen') || 'false';
  const list = buyExecutions.map((buyExecution) => (
    <S.Cajatransparente
      row
      border
      key={buyExecution.id}
      onClick={() => {
        if (fullScreen !== 'true' && CommonUtils.pushToNative) {
          CommonUtils.pushToNative({
            body: {
              action: 'goFullScreen',
              params: {
                route: '/tournament/competitor/' + buyExecution.buyOrder.user.id,
                querySt: '?q=&full_screen=true',
                queryParams: {},
              },
            },
          });
        } else {
          navigateTo(routes.tournamentCompetitor, {
            routeParams: { id: buyExecution.buyOrder.user.id },
            queryParams: { full_screen: 'true' }
          });
        }
      }}
    >
      <S.CajaDere>
        <S.Flex>
          <S.Ico sinmargin src={buyExecution.buyOrder.user.photo || '/game/img/usuario2.svg'} />
          <S.Column>
            <Psmall nowrap marginBottom="5px" color="white">
              {buyExecution.buyOrder.user.username}
            </Psmall>
            <Psmall nowrap color="#b9b9b9" fontSize="10px">
              <span>{buyExecution.buyOrder.instrument.displayName}</span> (
              {buyExecution.buyOrder.instrument.ticker})
            </Psmall>
          </S.Column>
        </S.Flex>
      </S.CajaDere>
      <S.CajaIzq>
        <Psmall nowrap marginBottom="5px" align="right" color={TournamentUtils.signedColor(4.58)}>
          <S.Top src="/game/img/top.svg" style={{ display: 'none' }} />
          <S.Span color={TournamentUtils.signedColor(4.58)} style={{ display: 'none' }}>
            4,58%
          </S.Span>
        </Psmall>
        <Psmall nowrap align="right" color="black">
          <S.Trades src="/game/img/trades.svg" />
          <S.Span color="#b9b9b9">{buyExecution.executedTitles}</S.Span>
        </Psmall>
      </S.CajaIzq>
    </S.Cajatransparente>
  ));
  return <S.Lista style={{ width: '100%' }}>{list}</S.Lista>;
}

function FabsButton({ medium, onClick, children, ...props }) {
  return <Fab
    size={medium ? 'medium' : 'large'}
    onClick={onClick}
    style={{
      color: '#fff',
      background: 'linear-gradient(180deg,var(--color-pink) 0%,var(--color-violet) 100%)',
      marginTop: '10px',
      cursor: 'pointer'
    }} aria-label="edit">
    {children}
  </Fab>;
}

export const Main = () => {
  const { navigateTo } = NavigationWrapper.use();
  const [selectab, setSelecttab] = useState('t');
  const { useHeader } = UiWrapper.use();

  const [tournamentsForMyWallets, setTournamentsForMyWallets] = useState([]);
  const [endTournaments, setEndTournaments] = useState([]);
  const [myCreatedTournamentsCount, setMyCreatedTournamentsCount] = useState(0);
  const [myExecutedByOrdersCount, setMyExecutedByOrdersCount] = useState(0);
  const [availableTournaments, setAvailableTournaments] = useState([]);
  const [lastBuyExecutions, setLastBuyExecutions] = useState([]);
  const [loadingMyTournaments, setLoadingMyTournaments] = useState(false);
  const [loadingEndTournaments, setLoadingEndTournaments] = useState(false);
  const [loadingAvailableTournaments, setLoadingAvailableTournaments] = useState(false);
  const [loadingExecutions, setLoadingExecutions] = useState(false);
  const [user, setUser] = useState(null);

  const [showFabs, setShowFabs] = useState(false);

  useEffect(() => {
    window.GameManager.findTournamentsForMyWallets = () => {
      Api.instance.findTournamentsForMyWallets().then((tournamentsForMyWalletsFromApi) => {
        setLoadingMyTournaments(false);
        if (tournamentsForMyWalletsFromApi) {
          setTournamentsForMyWallets([]);
          setTournamentsForMyWallets(tournamentsForMyWalletsFromApi);
        }
      });
    }
    window.GameManager.findAvailableTournaments = () => {
      Api.instance.findAvailableTournaments().then((tournaments) => {
        setLoadingAvailableTournaments(false);
        if (tournaments) {
          setAvailableTournaments([]);
          setAvailableTournaments(tournaments);
        }
      });
    }
    window.GameManager.findEndTournaments = () => {
      Api.instance.findEndTournaments().then((enTournamentsFromApi) => {
        setLoadingEndTournaments(false);
        if (enTournamentsFromApi) {
          setEndTournaments(enTournamentsFromApi);
        }
      });
    }
    window.GameManager.getCurrentUser = () => {
      Api.instance.getCurrentUser().then((userFromApi) => {
        if (userFromApi) {
          setUser(userFromApi);
        }
      });
    }
    window.GameManager.findMyCreatedTournamentsCount = () => {
      Api.instance.findMyCreatedTournaments().then((myCreatedTournamentsFromAPI) => {
        if (myCreatedTournamentsFromAPI) {
          setMyCreatedTournamentsCount(myCreatedTournamentsFromAPI.length);
        }
      });
    }
    window.GameManager.findMyExecutedByOrdersCount = () => {
      Api.instance.findMyExecutedBuyOrders().then((myExecutedByOrders) => {
        if (myExecutedByOrders) {
          setMyExecutedByOrdersCount(myExecutedByOrders.length);
        }
      });
    }
    window.GameManager.reloadTournaments = () => {
        window.GameManager.findTournamentsForMyWallets();
        window.GameManager.findAvailableTournaments();
        // window.GameManager.findEndTournaments();
        // window.GameManager.getCurrentUser();
        // window.GameManager.findMyCreatedTournamentsCount();
        // window.GameManager.findMyExecutedByOrdersCount();
    }
    setLoadingMyTournaments(true);
    setLoadingAvailableTournaments(true);
    window.GameManager.reloadTournaments(); // Call multiple times from app

    setLoadingEndTournaments(true);
    window.GameManager.findEndTournaments(); // Only call this time

    window.GameManager.getCurrentUser(); // Only call this time
    window.GameManager.findMyCreatedTournamentsCount(); // Only call this time
    window.GameManager.findMyExecutedByOrdersCount(); // Only call this time
  }, []);

  useEffect(() => {
    window.GameManager.reloadLastBuyExecutions = () => {
      Api.instance.findLastBuyExecutions(3).then((buyExecutions) => {
        if (buyExecutions) {
          setLastBuyExecutions([]);
          setLastBuyExecutions(buyExecutions);
          setLoadingExecutions(false);
        }
      });
    }
    setTimeout(() => {
      setLoadingExecutions(true);
      window.GameManager.reloadLastBuyExecutions() // Call multiple times from app
    }, 128);
  }, []);

  const fullScreen = new URLSearchParams(window.location.search).get('full_screen') || 'false';

  useHeader(
    fullScreen === 'true' ? (
      <Header canGoBack={false} canClose={false} closeRoute={routes.tournamentHomeMain} />
    ) : null
  );

  return (
    <S.Wrapper>
      <S.Content>
        <MarginWrapper justifyContent="start" padding="0 0 15px 0">
          <S.Tab>
            <S.TabTitle
              active={selectab === 't'}
              onClick={(e) => {
                setSelecttab('t');
              }}
            >
              Torneos
            </S.TabTitle>
            <S.TabTitle
              active={selectab === 'p'}
              onClick={(e) => {
                setSelecttab('p');
              }}
            >
              Mi progreso
            </S.TabTitle>
            <S.TabTitle
              active={selectab === 'e'}
              onClick={(e) => {
                setSelecttab('e');
              }}
            >
              Terminados
            </S.TabTitle>
          </S.Tab>
          <S.Caja bottom noactive={selectab !== 't'}>
            <H1small marginBottom="5px">MIS TORNEOS</H1small>
            <MyTournamentsList tournaments={tournamentsForMyWallets} />
            {availableTournaments?.length > 0 && (
              <H1small marginBottom="5px">TORNEOS DISPONIBLES</H1small>
            )}
            <AvailableTournamentsList tournaments={availableTournaments} />
            {lastBuyExecutions.length ? <H1small marginBottom="5px">ÚLTIMOS TRADES</H1small> : ''}
            {loadingExecutions ? (
              <CommonStyles.InlineLoadingContainer>
                <ReactLoading type="spinningBubbles" color="#ffffff" height="20%" width="20%" />
              </CommonStyles.InlineLoadingContainer>
            ) : (
              <BuyExecutionsList buyExecutions={lastBuyExecutions} />
            )}
            <S.FabsMain>
              <S.Fabs active={showFabs} >
                <FabsButton
                  medium
                  onClick={() => {
                    navigateToNative(navigateTo, routes.tournamentCreate, fullScreen);
                  }}
                >
                  <Join2Icon style={{ marginLeft: 6 }} />
                  <AddIcon style={{ width: 15, marginLeft: -8, marginTop: -20 }} />
                </FabsButton>
                <FabsButton
                  medium
                  onClick={() => {
                    navigateToNative(navigateTo, routes.tournamentSearch, fullScreen);
                  }}
                >
                  <Join2Icon />
                </FabsButton>
              </S.Fabs>
              <FabsButton
                onClick={() => setShowFabs(!showFabs)}>
                <AddIcon />
              </FabsButton>
            </S.FabsMain>
          </S.Caja>
          <S.Caja noactive={selectab !== 'p'}>
            <S.Lista>
              <S.Cajatransparente row border>
                <S.CajaDere>
                  <S.Flex>
                    <S.Progreso sinmargin src="/game/img/principiante.svg" />
                    <S.Column>
                      <Psmall nowrap marginBottom="5px" color="white">
                        Principiante
                      </Psmall>
                      <Psmall nowrap color="green">
                        Completado
                      </Psmall>
                    </S.Column>
                  </S.Flex>
                </S.CajaDere>
                <S.CajaIzq>
                  <S.Check src="/game/img/check.svg" />
                </S.CajaIzq>
              </S.Cajatransparente>
              <S.Cajatransparente
                row
                border
                onClick={() => {
                  if (fullScreen !== 'true' && CommonUtils.pushToNative) {
                    CommonUtils.pushToNative({
                      body: {
                        action: 'goFullScreen',
                        params: {
                          route: '/tournament/search',
                          querySt: '?q=&full_screen=true',
                          queryParams: {},
                        },
                      },
                    });
                  } else {
                    navigateTo(routes.tournamentSearch, { queryParams: { full_screen: 'true' } });
                  }
                }}
              >
                <S.CajaDere>
                  <S.Flex>
                    <S.Progreso sinmargin src="/game/img/principiante.svg" />
                    <S.Column>
                      <Psmall nowrap marginBottom="5px" color="white">
                        Avanzado
                      </Psmall>
                      <Psmall nowrap color={myExecutedByOrdersCount > 0 ? 'green' : '#b9b9b9'}>
                        Compra una acción
                      </Psmall>
                    </S.Column>
                  </S.Flex>
                </S.CajaDere>
                {myExecutedByOrdersCount > 0 ? (
                  <S.CajaIzq>
                    <S.Check src="/game/img/check.svg" />
                  </S.CajaIzq>
                ) : (
                  <S.CajaIzq>
                    <S.Right src="/game/img/rightwhite.svg" />
                  </S.CajaIzq>
                )}
              </S.Cajatransparente>
              <S.Cajatransparente
                row
                border
                onClick={() => {
                  navigateTo(routes.start);
                }}
              >
                <S.CajaDere>
                  <S.Flex>
                    <S.Progreso sinmargin src="/game/img/experto.svg" />
                    <S.Column>
                      <Psmall nowrap marginBottom="5px" color="white">
                        Experto
                      </Psmall>
                      <Psmall nowrap color={myCreatedTournamentsCount > 0 ? 'green' : '#b9b9b9'}>
                        Crea tu primer torneo
                      </Psmall>
                    </S.Column>
                  </S.Flex>
                </S.CajaDere>
                {myCreatedTournamentsCount > 0 ? (
                  <S.CajaIzq>
                    <S.Check src="/game/img/check.svg" />
                  </S.CajaIzq>
                ) : (
                  <S.CajaIzq>
                    <S.Right src="/game/img/rightwhite.svg" />
                  </S.CajaIzq>
                )}
              </S.Cajatransparente>
              <S.Cajatransparente
                row
                border
                onClick={() => {
                  if (fullScreen !== 'true' && CommonUtils.pushToNative) {
                    CommonUtils.pushToNative({
                      body: {
                        action: 'goFullScreen',
                        params: {
                          route: '/tournament/search',
                          querySt: '?q=&full_screen=true',
                          queryParams: {},
                        },
                      },
                    });
                  } else {
                    navigateTo(routes.tournamentSearch, { queryParams: { full_screen: 'true' } });
                  }
                }}
              >
                <S.CajaDere>
                  <S.Flex>
                    <S.Progreso sinmargin src="/game/img/master.svg" />
                    <S.Column>
                      <Psmall nowrap marginBottom="5px" color="white">
                        Master
                      </Psmall>
                      <Psmall nowrap color={user?.tournamentsWon > 0 ? 'green' : '#b9b9b9'}>
                        Gana tu primer torneo
                      </Psmall>
                    </S.Column>
                  </S.Flex>
                </S.CajaDere>
                {user?.tournamentsWon > 0 ? (
                  <S.CajaIzq>
                    <S.Check src="/game/img/check.svg" />
                  </S.CajaIzq>
                ) : (
                  <S.CajaIzq>
                    <S.Right src="/game/img/rightwhite.svg" />
                  </S.CajaIzq>
                )}
              </S.Cajatransparente>
              <S.Cajatransparente
                row
                border
                onClick={() => {
                  if (fullScreen !== 'true' && CommonUtils.pushToNative) {
                    CommonUtils.pushToNative({
                      body: {
                        action: 'goFullScreen',
                        params: {
                          route: '/tournament/search',
                          querySt: '?q=&full_screen=true',
                          queryParams: {},
                        },
                      },
                    });
                  } else {
                    navigateTo(routes.tournamentSearch, { queryParams: { full_screen: 'true' } });
                  }
                }}
              >
                <S.CajaDere>
                  <S.Flex>
                    <S.Progreso sinmargin src="/game/img/trade.svg" />
                    <S.Column>
                      <Psmall nowrap marginBottom="5px" color="white">
                        Trader
                      </Psmall>
                      <Psmall nowrap color={user?.tournamentsWon >= 5 ? 'green' : '#b9b9b9'}>
                        Gana 5 torneos
                      </Psmall>
                    </S.Column>
                  </S.Flex>
                </S.CajaDere>
                {user?.tournamentsWon >= 5 ? (
                  <S.CajaIzq>
                    <S.Flex>
                      <Psmall color="green" align="right" marginBottom="5px" marginRight="12px">
                        {user?.tournamentsWon}/5
                      </Psmall>
                      <S.Check src="/game/img/check.svg" />
                    </S.Flex>
                  </S.CajaIzq>
                ) : (
                  <S.CajaIzq>
                    <S.Flex>
                      <Psmall color="white" align="right" marginBottom="5px" marginRight="12px">
                        {user?.tournamentsWon || 0}/5
                      </Psmall>
                      <S.Right src="/game/img/rightwhite.svg" />
                    </S.Flex>
                  </S.CajaIzq>
                )}
              </S.Cajatransparente>
              <S.Cajatransparente
                row
                border
                onClick={() => {
                  if (fullScreen !== 'true' && CommonUtils.pushToNative) {
                    CommonUtils.pushToNative({
                      body: {
                        action: 'goFullScreen',
                        params: {
                          route: '/tournament/search',
                          querySt: '?q=&full_screen=true',
                          queryParams: {},
                        },
                      },
                    });
                  } else {
                    navigateTo(routes.tournamentSearch, { queryParams: { full_screen: 'true' } });
                  }
                }}
              >
                <S.CajaDere>
                  <S.Flex>
                    <S.Progreso sinmargin src="/game/img/broker.svg" />
                    <S.Column>
                      <Psmall nowrap marginBottom="5px" color="white">
                        Broker
                      </Psmall>
                      <Psmall nowrap color={user?.tournamentsWon >= 10 ? 'green' : '#b9b9b9'}>
                        Gana 10 torneos
                      </Psmall>
                    </S.Column>
                  </S.Flex>
                </S.CajaDere>
                {user?.tournamentsWon >= 10 ? (
                  <S.CajaIzq>
                    <S.Flex>
                      <Psmall color="green" align="right" marginBottom="5px" marginRight="12px">
                        {user?.tournamentsWon}/10
                      </Psmall>
                      <S.Check src="/game/img/check.svg" />
                    </S.Flex>
                  </S.CajaIzq>
                ) : (
                  <S.CajaIzq>
                    <S.Flex>
                      <Psmall color="white" align="right" marginBottom="5px" marginRight="12px">
                        {user?.tournamentsWon || 0}/10
                      </Psmall>
                      <S.Right src="/game/img/rightwhite.svg" />
                    </S.Flex>
                  </S.CajaIzq>
                )}
              </S.Cajatransparente>
            </S.Lista>
          </S.Caja>
          <S.Caja noactive={selectab !== 'e'}>
            <H1small marginBottom="5px">TORNEOS FINALIZADOS</H1small>
            {loadingEndTournaments ? (
              <CommonStyles.InlineLoadingContainer>
                <ReactLoading type="spinningBubbles" color="#ffffff" height="20%" width="20%" />
              </CommonStyles.InlineLoadingContainer>
            ) :
              <MyTournamentsList tournaments={endTournaments} isFinishedTmt />}
          </S.Caja>
        </MarginWrapper>
      </S.Content>
      {loadingMyTournaments || loadingAvailableTournaments ? (
        <CommonStyles.FullHeightLoadingContainer>
          <ReactLoading type="spinningBubbles" color="#ffffff" height="20%" width="20%" />
        </CommonStyles.FullHeightLoadingContainer>
      ) : null}
    </S.Wrapper>
  );
};

import styled, { css } from 'styled-components/macro';

export const verticalFlex = css`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const FullHeightLoadingContainer = styled.div`
  ${verticalFlex}
  z-index: ${(props) => props.zIndex ? props.zIndex : 1000};
  background-color: #1a1c21df;
  justify-content: center;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

export const InlineLoadingContainer = styled.div`
  ${verticalFlex}
  z-index: 1000;
  background-color: #ffffff00;
  justify-content: center;
  position: relative;
  width: 100%;
`;

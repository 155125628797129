import styled from 'styled-components/macro';
import { space, layout } from 'styled-system';

export const Pbig = styled.p`
  color: ${(props) => props.color};
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: ${(props) => props.align};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: ${(props) => (props.nowrap ? 'nowrap' : 'initial')};
  ${space};
  ${layout};
`;

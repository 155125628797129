import { NavigationWrapper, SessionWrapper } from 'coinscrap-webapp-core-ts';
import React, { useEffect, useState } from 'react';
import * as S from '../Start/styles';
import { MainButton } from '../../components/MainButton/MainButton';
import { Api } from '../../api/api';
import { routes } from '../../routes';
import { navigateToNative } from '../../routes/routes';
import {CommonUtils} from '../../utils/CommonUtils';

export const TournamentActionButtons = ({ isFirstTime = false, style }) => {
  const { navigateTo } = NavigationWrapper.use();
  const [selectAvatar, setSelectAvatar] = useState(false); // comprobar si tiene avatar
  // const location = useLocation();
  // React.useEffect(() => {
  //     console.log('Location changed to', location);
  // }, [location]);
  const fullScreen = new URLSearchParams(window.location.search).get('full_screen') || 'false';

  const { sessionData } = SessionWrapper.use();

  useEffect(() => {
    if (sessionData?.userId) {
      Api.instance.getCurrentUser().then((userFromApi) => {
        //TODO: si tiene avatar, poner selectAvatar a false
        if (userFromApi?.photo) {
          setSelectAvatar(false);
        } else {
          setSelectAvatar(true);
        }
      });
    }
  }, [selectAvatar, sessionData?.userId]);

  useEffect(() => {
    setSelectAvatar(!sessionData?.photo);
  }, [sessionData?.photo]);

    function handleEvent() {
        if (isFirstTime) {
            if (CommonUtils.pushToNative) {
                if (!localStorage.getItem(`sent_emma_event_MKSIM_TUTORIAL`)) {
                    CommonUtils.pushToNative({
                        body: {
                            action: 'sendEmmaEvent',
                            params: {
                                name: 'MKSIM_TUTORIAL',
                            },
                        },
                    });
                    localStorage.setItem(`sent_emma_event_MKSIM_TUTORIAL`, 'true');
                }
            }
        }
    }

    return (
    <S.Div style={style}>
      <MainButton
        pink
        full
        fullWidth
        marginTop="10px"
        onClick={() => {
            handleEvent();
          // TODO: si selectAvatar es false, tiene avatar -> llevar a tournamentCreate
          if (!selectAvatar) {
            navigateToNative(navigateTo, routes.tournamentCreate, fullScreen, {queryParams: { full_screen: 'true' }});
          }
          // TODO: si no tiene avatar -> createAvatar -> tournamentCreate
          else {
            navigateToNative(navigateTo, routes.createAvatar, fullScreen, {
              queryParams: { pageName: routes.tournamentCreate, full_screen: 'true' },
            });
          }
        }}
      >
        CREAR UN TORNEO
      </MainButton>
      <MainButton
        pink
        full
        fullWidth
        marginTop="10px"
        onClick={() => {
            handleEvent();
          // TODO: si selectAvatar es false, tiene avatar -> llevar a tournamentSearch
          if (!selectAvatar) {
            navigateToNative(navigateTo, routes.tournamentSearch, fullScreen, {queryParams: { full_screen: 'true' }});
          }
          // TODO: si no tiene avatar -> createAvatar -> tournamentSearch
          else {
            navigateToNative(navigateTo, routes.createAvatar, fullScreen, {
              queryParams: { pageName: routes.tournamentSearch, full_screen: 'true' },
            });
          }
        }}
      >
        UNIRSE A UN TORNEO
      </MainButton>
    </S.Div>
  );
};

import { MainButton } from 'components/MainButton/MainButton';
import { H3 } from 'components/H3/H3';
import { P } from 'components/P/P';
import { Header } from 'components/Header/header';

import { useEffect, useState } from 'react';
import { NavigationWrapper, UiWrapper } from 'coinscrap-webapp-core-ts';
import { routes } from 'routes';
import * as S from './styles';
import { MarginWrapper } from '../../../layout/MarginWrapper/MarginWrapper';
import {TournamentUtils, useTemporaryTournament} from '../../../utils/TournamentUtils';
import { Api } from '../../../api/api';

export const WalletVisibility = () => {
  const { navigateTo } = NavigationWrapper.use();
  const { useHeader } = UiWrapper.use();

  const [tmpTournament, setTmpTournament] = useTemporaryTournament();

  useHeader(<Header closeRoute={routes.tournamentHomeMain} />);
  return (
    <S.Wrapper>
      <S.Content>
        <MarginWrapper justifyContent="space-between">
          <S.Caja>
            <H3>Elige si la cartera va hacer pública o privada</H3>
            <S.Switch>
              <S.Option
                  active={tmpTournament?.publicWallet === true}
                onClick={(e) => {
                  setTmpTournament({  publicWallet: true});
                }}
              >
                Cartera Pública
              </S.Option>
              <S.Option
                  active={tmpTournament?.publicWallet === false}
                onClick={(e) => {
                  setTmpTournament({  publicWallet: false});
                }}
              >
                Cartera Privada
              </S.Option>
            </S.Switch>
            <P color="white">
              Cuando la Cartera es pública, todos los jugadores del Torneo podrán ver siempre el
              Estado de los Valores de la Cartera de los demás.
            </P>
          </S.Caja>
          <MainButton
            pink
            full
            fullWidth
            marginTop="10px"
            onClick={() => {
              navigateTo(routes.tournamentCreateInitialBalance);
            }}
          >
            SIGUIENTE
          </MainButton>
        </MarginWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

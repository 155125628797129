import * as S from './styles';

export const Psmall = ({
  children,
  className,
  align,
  big,
  absolute,
  marginTop,
  marginBottom,
  marginRight,
  color,
  paddingX,
  style,
  nowrap,
  fontSize,
}) => (
  <S.Psmall
    className={className + ' psmall'}
    align={align}
    big={big}
    fontSize={fontSize}
    absolute={absolute}
    marginTop={marginTop}
    marginBottom={marginBottom}
    marginRight={marginRight}
    color={color}
    nowrap={nowrap}
    paddingX={paddingX}
    style={style}
  >
    {children}
  </S.Psmall>
);

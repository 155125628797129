import { Header } from 'components/Header/header';
import { H1small } from 'components/H1small/H1small';
import { H1 } from 'components/H1/H1';
import { Pbig } from 'components/Pbig/Pbig';
import { Psmall } from 'components/Psmall/Psmall';
import SearchIcon from 'assets/svg/search.svg';

import { NavigationWrapper, UiWrapper, TextField, Image } from 'coinscrap-webapp-core-ts';
import { routes } from 'routes';
import * as S from './styles';
import { MarginWrapper } from '../../../layout/MarginWrapper/MarginWrapper';

export const Chats = () => {
  const { navigateTo } = NavigationWrapper.use();
  const { useHeader } = UiWrapper.use();

  useHeader(<Header closeRoute={routes.tournamentHomeMain} />);
  return (
    <S.Wrapper>
      <S.Content>
        <MarginWrapper justifyContent="space-between">
          <S.Caja>
            <H1small>MIS CHATS</H1small>
            <TextField
              cursorSt
              placeholder="Buscar mis chats..."
              startAdornment={<Image height="24px" width="auto" src={SearchIcon} />}
            />
            <S.Cajatransparente
              onClick={() => {
                navigateTo(routes.tournamentChatDetail);
              }}
            >
              <S.CajaAzulDegradado>
                <S.CajaDere>
                  <S.Ico src="/game/img/usuario1.svg" />
                  <Pbig nowrap color="white" align="left">SENSEIZERO</Pbig>
                </S.CajaDere>
                <S.CajaIzqi>
                  <Psmall align="center" color="white">
                    Privado
                  </Psmall>
                </S.CajaIzqi>
              </S.CajaAzulDegradado>
              <S.CajaInterior>
                <S.CajaDer>
                  <Psmall color="black" marginBottom="15px">
                    Creado: 08/11/2020
                  </Psmall>
                  <Psmall color="red">Último mensaje: hace 2 días</Psmall>
                </S.CajaDer>
                <S.CajaIzq>
                  <H1 black>4</H1>
                  <Psmall align="center" color="black">
                    Nº de jugadores en linea
                  </Psmall>
                </S.CajaIzq>
              </S.CajaInterior>
            </S.Cajatransparente>
            <S.Cajatransparente
              onClick={() => {
                navigateTo(routes.tournamentChatDetail);
              }}
            >
              <S.CajaAzulDegradado>
                <S.CajaDere>
                  <S.Ico src="/game/img/usuario2.svg" />
                  <Pbig nowrap color="white" align="left">True Traders</Pbig>
                </S.CajaDere>
                <S.CajaIzqi>
                  <Psmall align="center" color="white">
                    Privado
                  </Psmall>
                </S.CajaIzqi>
              </S.CajaAzulDegradado>
              <S.CajaInterior>
                <S.CajaDer>
                  <Psmall color="black" marginBottom="15px">
                    Creado: 08/11/2020
                  </Psmall>
                  <Psmall color="red">Último mensaje: hace 2 días</Psmall>
                </S.CajaDer>
                <S.CajaIzq>
                  <H1 black>4</H1>
                  <Psmall align="center" color="black">
                    Nº de jugadores en linea
                  </Psmall>
                </S.CajaIzq>
              </S.CajaInterior>
            </S.Cajatransparente>
            <S.Cajatransparente
              onClick={() => {
                navigateTo(routes.tournamentChatDetail);
              }}
            >
              <S.CajaAzulDegradado>
                <S.CajaDere>
                  <S.Ico src="/game/img/usuario2.svg" />
                  <Pbig nowrap color="white" align="left">Invest</Pbig>
                </S.CajaDere>
                <S.CajaIzqi>
                  <Psmall align="center" color="white">
                    Público
                  </Psmall>
                </S.CajaIzqi>
              </S.CajaAzulDegradado>
              <S.CajaInterior>
                <S.CajaDer>
                  <Psmall color="black" marginBottom="15px">
                    Creado: 08/11/2020
                  </Psmall>
                  <Psmall color="red">Acaba en 21 días</Psmall>
                </S.CajaDer>
                <S.CajaIzq>
                  <H1 black>50</H1>
                  <Psmall align="center" color="black">
                    Nº de jugadores
                  </Psmall>
                </S.CajaIzq>
              </S.CajaInterior>
            </S.Cajatransparente>
          </S.Caja>
        </MarginWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

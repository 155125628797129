import styled from 'styled-components/macro';
import { space, layout } from 'styled-system';

export const H5 = styled.h5`
  color: ${(props) => (props.pink ? 'var(--color-pink)' : 'var(--color-blue)')};
  font-family: 'Roboto', sans-serif;
  font-size: 26px;
  font-weight: 400;
  letter-spacing: 2px;
  line-height: 30px;
  text-transform: uppercase;
  ${space};
  ${layout};
`;

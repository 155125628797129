import styled from 'styled-components/macro';
import { verticalFlex } from 'styles/common';

export const Wrapper = styled.div`
  ${verticalFlex}
  align-items: center;
  justify-content: space-between;
  min-height: 100%;
  width: 100%;
`;

export const Content = styled.div`
  ${verticalFlex}
  flex: 1;
  height: 100%;
  justify-content: space-between;
  padding: 0 20px;
  width: 100%;
`;

export const Caja = styled.div`
  align-items: start;
  display: ${(props) => (props.noactive ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: calc(100% - 40px);
  margin-top: 50px;
  margin-bottom: ${(props) => (props.bottom ? '60px' : '0px')};
  @media (max-width: 990px) {
    justify-content: start;
    height: auto;
  }
`;

export const Cajatransparente = styled.div`
  align-items: center;
  background: transparent;
  border-bottom: ${(props) => (props.border ? '1px solid rgba(255,255,255,0.3)' : '0')};
  border-radius: ${(props) => (props.border ? '0' : '4px')};
  display: flex;
  flex-direction: ${(props) => (props.row ? 'row' : 'column')};
  margin-bottom: 10px;
  margin-right: ${(props) => (props.marginright ? '10px' : '0')};
  max-width: ${(props) => (props.midwith ? '70%' : '100%')};
  padding-bottom: ${(props) => (props.border ? '10px' : '0')};
  width: ${(props) => (props.midwith ? '70%' : '100%')};
  cursor: pointer;
`;

export const CajaAzulDegradado = styled.div`
  align-items: end;
  background: linear-gradient(90deg, #1a1c23 0%, #193c83 100%);
  border-radius: 4px 4px 0px 0px;
  display: flex;
  max-width: 100%;
  padding: 10px;
  position: relative;
  width: 100%;
`;

export const Ico = styled.img`
  border: 2px solid white;
  border-radius: 50px;
  height: 48px;
  margin-bottom: ${(props) => (props.sinmargin ? '0' : '-20px')};
  margin-right: 8px;
  width: 48px;
  z-index: 998;
`;

export const CajaInterior = styled.div`
  background: white;
  border-radius: 0 0 4px 4px;
  display: flex;
  max-width: 100%;
  padding: 15px 20px;
  width: 100%;
`;

export const CajaVerTodos = styled.div`
  border-radius: 4px;
  /* display: flex; */
  height: fit-content;
  padding: 15px 20px;
  align-items: center;
  align-self: center;
  border: 1px solid white;
`;

export const CajaDer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 64%;
`;

export const CajaDere = styled.div`
  align-items: flex-end;
  display: flex;
  width: 60%;
`;

export const CajaIzq = styled.div`
  align-items: flex-end;
  display: ${(props) => (props.hidden ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: center;
  width: 40%;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
`;

export const CajaBlanca = styled.div`
  align-items: center;
  background: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin: 2.5px;
  padding: 10px 5px;
  position: relative;
  width: calc(33.33% - 5px);
`;

export const P = styled.p`
  color: black;
  font-family: HelveticaBold;
  font-size: 12px;
  text-align: center;
`;

export const Icon = styled.img`
  border: 2px solid white;
  border-radius: 50px;
  height: 48px;
  margin-bottom: 5px;
  width: 48px;
`;

export const Img = styled.img`
  position: absolute;
  right: 5px;
  top: -5px;
`;

export const Pblack = styled.p`
  color: black;
  font-family: Helvetica;
  font-size: 15px;
  text-align: left;
`;

export const CajaBlancaG = styled.div`
  background: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  width: 100%;
`;

export const Titulo = styled.p`
  color: #b4b4bb;
  font-family: Helvetica;
  font-size: 16px;
  text-align: left;
`;

export const Tab = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 15px;
  width: 100%;
  position: absolute;
  background-color: var(--color-primary);
  z-index: 999;
`;

export const TabTitle = styled.h2`
  border-bottom: ${(props) => (props.active ? '2px solid white' : '0')};
  color: white;
  font-family: ${(props) => (props.active ? 'EurostileBold' : 'Eurostile')};
  font-size: 20px;
  padding: 0 15px 10px;
  @media (max-width: 990px) {
    font-size: 16px;
  }
`;

export const Span = styled.span`
  color: ${(props) => props.color};
  margin-right: ${(props) => (props.marginright ? '5px' : '0')};
  padding-right: ${(props) => (props.paddingright ? '15px' : '0')};
`;

export const More = styled.img`
  height: 12px;
  padding: 0 5px;
  width: auto;
`;

export const Top = styled.img`
  height: 8px;
  margin-left: ${(props) => (props.marginleft ? '10px' : '0')};
  padding-right: 5px;
  width: auto;
`;

export const Calificacion = styled.img`
  height: auto;
  position: absolute;
  display: ${(props) => (props.hidden ? 'none' : 'flex')};
  right: 10px;
  top: -5px;
  width: 20px;
`;

export const Scroll = styled.div`
  display: flex;
  margin: 0 -20px 0 0;
  max-width: calc(100% + 20px);
  overflow: scroll;
  width: calc(100% + 20px);
`;

export const Responsive = styled.div`
/*max-height: 27vh;
  overflow: scroll;*/
`;

export const Usu = styled.img`
  height: auto;
  margin-left: -7px;
  vertical-align: middle;
  width: 20px;
`;

export const CajaColor = styled.div`
  align-items: start;
  background: ${(props) => props.color};
  border-radius: 4px 4px 0px 0px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 5px 10px 10px 10px;
  position: relative;
  width: 100%;
`;

export const Lista = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /*max-height: 27vh;
  overflow: scroll;*/
`;

export const Flex = styled.div`
  align-items: center;
  display: flex;
  max-width: 100%;
  width: 100%;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: calc(100% - 55px);
`;

export const Trades = styled.img`
  height: 16px;
  margin-left: ${(props) => (props.marginleft ? '10px' : '0')};
  padding-right: 5px;
  vertical-align: middle;
  width: auto;
`;

export const Check = styled.img`
  height: 15px;
  margin-right: 15px;
  width: auto;
`;

export const Right = styled.img`
  height: 22px;
  margin-right: 15px;
  width: auto;
`;

export const Progreso = styled.img`
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50px;
  margin-right: 10px;
  z-index: 999;
`;

export const FabsMain = styled.div`
position: absolute;
bottom: 0;
right: 0;
margin-right: 20px;
margin-bottom: 20px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 999;
`;

export const Fabs = styled.div`
  align-items: center;
  display: ${(props) => (props.active ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
`;
import styled from 'styled-components/macro';
import { verticalFlex } from 'styles/common';

export const Scroll = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  max-width: calc(100% + 20px);
  overflow: scroll;
  width: calc(100% + 20px);
`;

export const Wrapper = styled.div`
  ${verticalFlex}
  align-items: center;
  justify-content: space-between;
  min-height: 100%;
  width: 100%;
`;

export const Content = styled.div`
  ${verticalFlex}
  flex: 1;
  height: 100%;
  justify-content: space-between;
  padding: 0 20px;
  width: 100%;
`;

export const Caja = styled.div`
  ${verticalFlex}
  flex: 1;
  height: 100%;
  justify-content: start;
  max-width: 100%;
  width: 100%;
`;

export const Switch = styled.div`
  border: 1px solid white;
  border-radius: 20px;
  display: flex;
  margin: 15px 0;
  width: 100%;
`;

export const Option = styled.div`
  background: ${(props) => (props.active ? 'var(--color-violet)' : 'transparent')};
  border-radius: 20px;
  color: white;
  display: flex;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: bold;
  justify-content: center;
  margin: -1px;
  padding: 14px;
  width: 52%;
  cursor: pointer;
`;

export const Cajita = styled.div`
  ${verticalFlex}
  flex-direction: row;
  justify-content: space-between;
  max-width: 100%;
`;

export const Divimg = styled.div`
  ${verticalFlex}
  background: white;
  border-radius: 10px;
  margin: 5px 0;
  position: relative;
  width: 100%;
  cursor: pointer;
`;

export const Divimgt = styled.div`
  ${verticalFlex}
  background: white;
  border-radius: 10px;
  margin: 15px 0 5px;
  position: relative;
  width: 100%;
`;

export const Img = styled.img`
  margin: 30px;
  width: 210px;
`;

export const Imgsmall = styled.img`
  margin: 30px 30px 20px;
  width: 105px;
`;

export const Imgsmalls = styled.img`
  margin: 20px;
  width: 105px;
`;

export const Absolute = styled.div`
  background: var(--color-pink);
  color: white;
  font-family: 'Roboto', sans serif;
  font-size: 14px;
  font-weight: 600;
  padding: 5px;
  position: absolute;
  right: -5px;
  text-transform: uppercase;
  top: -5px;
`;

export const Active = styled.div`
  display: ${(props) => (props.activepass ? 'contents' : 'none')};
`;

export const Row = styled.div`
  margin: 0 -20px 0 0;
  overflow: scroll;
  position: relative;
  width: calc(100% + 20px);
`;

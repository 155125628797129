import styled from 'styled-components/macro';
import { space, layout } from 'styled-system';

export const P = styled.p`
  color: ${(props) => props.color};
  font-family: ${(props) => (props.bold ? 'HelveticaBold' : 'Helvetica')};
  font-size: ${(props) => props.fontSize || '14px'};
  min-width: ${(props) => (props.width10 ? '35px' : 'auto')};
  overflow: hidden;
  text-align: ${(props) => props.align};
  text-overflow: ellipsis;
  white-space: ${(props) => (props.nowrap ? 'nowrap' : 'initial')};
  width: ${(props) => (props.width10 ? 'auto' : '100%')};
  ${space};
  ${layout};
  @media (max-width: 990px) {
    font-size: ${(props) => props.fontSize || '13px'};
  }
`;

import { TransparentButton } from 'components/TransparentButton/TransparentButton';
import { MainButton } from 'components/MainButton/MainButton';
import { H1slide } from 'components/H1slide/H1slide';
import { H2slide } from 'components/H2slide/H2slide';
import { P } from 'components/P/P';
import { Header } from 'components/Header/header';

import { MarginWrapper } from 'layout/MarginWrapper/MarginWrapper';
import { NavigationWrapper, UiWrapper } from 'coinscrap-webapp-core-ts';
import { routes } from 'routes';
import * as S from './styles';

export const BettingStart = () => {
  const { navigateTo } = NavigationWrapper.use();
  const { useHeader } = UiWrapper.use();

  useHeader(<Header closeRoute={routes.tournamentHomeMain} />);
  return (
    <S.Wrapper>
      <S.Content>
        <MarginWrapper justifyContent="space-between">
          <S.Caja>
            <H1slide>La Porra:</H1slide>
            <H2slide marginBottom="2vh">Apuesta a ver quién gana el Torneo</H2slide>
            <P align="center">Haz una porra con dinero real y llévate el bote al ganar el torneo</P>
          </S.Caja>
          <S.Img src="/game/img/porra.png" />
          <S.Caja>
            <TransparentButton
              full
              fullWidth
              marginTop="10px"
              onClick={() => {
                navigateTo(routes.tournamentCreateSummary);
              }}
            >
              EN OTRO MOMENTO
            </TransparentButton>
            <MainButton
              pink
              full
              fullWidth
              marginTop="10px"
              onClick={() => {
                navigateTo(routes.bettingCreateAmount);
              }}
            >
              HACER UNA PORRA
            </MainButton>
          </S.Caja>
        </MarginWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

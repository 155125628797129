export class CommonUtils {
  static padded(arg, count, mode = 'pre') {
    while (`${arg}`.length < count) {
      if (mode === 'pre') {
        // eslint-disable-next-line no-param-reassign
        arg = `0${arg}`;
      } else if (mode === 'post') {
        // eslint-disable-next-line no-param-reassign
        arg = `${arg}0`;
      } else {
        console.error('padded() eing?', mode);
      }
    }
    return arg;
  }

  /**
   * @param {[]} list
   * @param {...[(function(*)|string), number][]} fieldDirectionPairs
   *
   * @return {[]} list
   */
  static sortBy(list, ...fieldDirectionPairs) {
    // noinspection PointlessArithmeticExpressionJS
    return list.sort((a, b) => {
      for (let [field, direction] of fieldDirectionPairs) {
        let fieldGetter = field;
        if (typeof fieldGetter === 'string') {
          fieldGetter = (it) => it[field];
        }
        // eslint-disable-next-line no-nested-ternary
        if (fieldGetter(a) < fieldGetter(b)) {
          return -1 * direction;
        }
        if (fieldGetter(a) > fieldGetter(b)) {
          return direction
        }
      }
      return 0;
    });
  }

  /**
   *
   * @param obj
   * @param property
   * @param cb (isEndValue: Boolean) => void
   * @param startValue
   * @param endValue
   * @param timeMillis
   * @param roundDigits
   *
   * @return {NodeJS.Timer} animationId
   */
  static animateProperty(obj, property, cb, startValue, endValue, timeMillis, roundDigits = 2) {
    return this.animateValue(
      (currentValue, isEndValue) => {
        obj[property] = currentValue;
        cb(currentValue, isEndValue);
      },
      startValue,
      endValue,
      timeMillis,
      roundDigits
    );
  }

  /**
   *
   * @param cb (currentValue: Float, isEndValue: Boolean) => void
   * @param startValue Number | () => Number
   * @param endValue Number | () => Number
   * @param timeMillis
   * @param roundDigits
   *
   * @return {NodeJS.Timer} animationId
   */
  static animateValue(
    cb,
    startValue,
    endValue,
    timeMillis,
    roundDigits = 2,
    endCb = (finalValue) => { }
  ) {
    let startValueFn;
    if (typeof startValue !== 'function') {
      startValueFn = () => startValue;
    } else {
      startValueFn = startValue;
    }
    let endValueFn;
    if (typeof endValue !== 'function') {
      endValueFn = () => endValue;
    } else {
      endValueFn = endValue;
    }
    // const deltaValue = () => Math.max(0.001, endValueFn() - startValueFn());
    const deltaValue = () => endValueFn() - startValueFn();
    let animationId = -1;
    if (Math.abs(deltaValue()) > 0.001) {
      const direction = Math.abs(deltaValue()) / deltaValue();
      let animating = true;
      setTimeout(() => (animating = false), timeMillis);
      // const millisPerStep = Math.max(17, timeMillis / deltaValue());

      const millisPerStep = 17;
      const steps = Math.floor(timeMillis / millisPerStep);
      const deltaPerStep = deltaValue() / steps;
      const absDeltaPerStep = Math.abs(deltaPerStep);

      let stepVariation;
      let prevValue = startValueFn();
      for (let n = -1; true; n++) {
        const threshold = 10 ** n;
        if (absDeltaPerStep < threshold) {
          prevValue = Math.round(prevValue / threshold) * threshold;
          stepVariation = 10 ** (n - 1);
          break;
        }
      }
      stepVariation *= direction;

      const startMillis = Date.now();
      // console.log('direction', 'steps', steps, direction, 'stepVariation', stepVariation);
      const coerceFn = endValueFn() >= startValueFn() ? Math.min : Math.max;
      animationId = setInterval(() => {
        const deltaTime = Date.now() - startMillis;
        const coef = Math.min(1, (deltaTime / timeMillis) ** (1 / 5));
        let currentValue;
        if (animating) {
          // const newUnsafeValue = Math.round((startValueFn() + coef * deltaValue()) * 10 ** roundDigits) / 10 ** roundDigits;
          // const newUnsafeValue = prevValue + 1 / (10 ** roundDigits) * direction;
          const newUnsafeValue = prevValue + stepVariation;

          currentValue = coerceFn(
              endValueFn(),
              newUnsafeValue
          );
        } else {
          currentValue = endValueFn();
        }
        // const isEndValue = currentValue === endValueFn();
        const isEndValue = !animating;
        cb(currentValue, isEndValue);
        if (isEndValue) {
          clearInterval(animationId);
          endCb(currentValue);
        }

        prevValue = currentValue;

      }, millisPerStep);
    } else {
      cb(endValueFn());
      endCb(endValueFn());
    }

    return animationId;
  }

  /**
   * Very important NO LOG ANYTHING HERE
   * @returns {null|CommonUtils._cachedNativeMessageHandler}
   */
  static get pushToNative() {
    if (this._cachedNativeMessageHandler) {
      return this._cachedNativeMessageHandler;
    }
    if (
      window.WebGameInterface?.postMessage ||
      window.webkit?.messageHandlers?.WebGameInterface?.postMessage
    ) {
      this._cachedNativeMessageHandler = function (message) {
        if (window.WebGameInterface?.postMessage) {
          window.WebGameInterface.postMessage(JSON.stringify(message));
        } else if (window.webkit?.messageHandlers?.WebGameInterface?.postMessage) {
          window.webkit.messageHandlers.WebGameInterface.postMessage(JSON.parse(JSON.stringify(message)));
        }
      };
      return this._cachedNativeMessageHandler;
    }
    return null;
  }

  static formatTimeInterval(template, millisecondsInterval) {
    let amountN;
    let amountS;
    let amountPlural;
    let verbPlural;

    if (millisecondsInterval < 60 * 1000) {
      amountN = Math.round(millisecondsInterval / 1000);
      amountS = 'segundo';
    } else if (millisecondsInterval < 3600 * 1000 * 2) {
      amountN = Math.round(millisecondsInterval / 60000);
      amountS = 'minuto';
    } else if (millisecondsInterval < 3600 * 1000 * 24 * 2) {
      amountN = Math.round(millisecondsInterval / (3600 * 1000));
      amountS = 'hora';
    } else {
      amountN = Math.round(millisecondsInterval / (86400 * 1000));
      amountS = 'día';
    }
    verbPlural = amountN !== 1 ? 'n' : '';
    amountPlural = amountN !== 1 ? 's' : '';
    return template
        .replace(/%n/gi, amountN)
        .replace(/%s{amountPlural}/gi, `${amountS}${amountPlural}`)
        .replace(/%s/gi, `${amountS}${amountPlural}`)

        .replace(/{verbPlural}/gi, verbPlural)
        .replace(/{amountPlural}/gi, amountPlural)
  }

  static normalizeTournament(tmpTournament) {
    const finalTournament = tmpTournament || {};
    finalTournament.name = finalTournament.name || '';
    if (finalTournament.start) {
      if (typeof finalTournament.start === 'string') {
        finalTournament.start = new Date(finalTournament.start);
      }
    } else {
      finalTournament.start = new Date(Date.now() + 30 * 1000);
    }
    if (finalTournament.end) {
      if (typeof finalTournament.end === 'string') {
        finalTournament.end = new Date(finalTournament.end);
      }
    } else {
      finalTournament.end = new Date(finalTournament.start.getTime() + 86400 * 1000 * 31);
    }
    finalTournament.public =
        typeof finalTournament.public === 'boolean' ? finalTournament.public : true;
    finalTournament.publicWallet =
        typeof finalTournament.publicWallet === 'boolean' ? finalTournament.publicWallet : true;
    finalTournament.commissionOnlyProfit =
        typeof finalTournament.commissionOnlyProfit === 'boolean'
            ? finalTournament.commissionOnlyProfit
            : true;
    finalTournament.initialBalance = finalTournament.initialBalance || 100.0;
    finalTournament.commission = finalTournament.commission || 0.0;

    finalTournament.user = finalTournament.user || {};
    finalTournament.user.photo = finalTournament.user.photo || '/game/img/usuario2.svg';

    finalTournament.wallets = finalTournament.wallets || [];

    if (finalTournament.limitJoinDate) {
      if (typeof finalTournament.limitJoinDate === 'string') {
        finalTournament.limitJoinDate = new Date(finalTournament.limitJoinDate);
      }
    } else {
      finalTournament.limitJoinDate = new Date(finalTournament.start.getTime() + 86400 * 2 *1000);
    }
    finalTournament.limitJoinDateOption = finalTournament.limitJoinDateOption || '2d';
    CommonUtils.sortBy(finalTournament.wallets, ['balance', -1]);

    return finalTournament;
  }

}

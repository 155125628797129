import { NavigationWrapper, SessionWrapper } from 'coinscrap-webapp-core-ts';
import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import jwt from 'jsonwebtoken';
import { Api } from '../../api/api';
import { routes } from '../../routes';
import * as CommonStyles from '../../styles/common';

export const RandomUser = () => {
  const { navigateTo } = NavigationWrapper.use();
  const { startSession, clearSession } = SessionWrapper.use();

  function doRefreshToken(tokenToForce) {
    return Api.instance.refreshToken(tokenToForce).then(newToken => {
      console.log('refreshed token with', newToken);
      const decoded = jwt.decode(newToken.id, { complete: true });
      startSession({
        token: newToken.id,
        userId: decoded.userId,
        jwtToken: decoded,
        created: decoded.created,
        ttl: decoded.ttl,
        roles: decoded.roles,
      }).then(v => {
        console.log('session update result', v);
      }).catch(e => {
        console.error('session update error', e);
      });
    })
  }

  function loginUser(email, password) {
    Api.instance
      .login(email, password)
      .then(async (token) => {
        console.log('token', token);
        const decoded = jwt.decode(token.id, { complete: true });
        await startSession({
          token: token.id,
          userId: decoded.userId,
          jwtToken: decoded,
          created: decoded.created,
          ttl: decoded.ttl,
          roles: decoded.roles,
        }).then(v => {
          console.log('session update result', v);
        }).catch(e => {
          console.error('session update error', e);
        });
        if (token.id) {
          // doRefreshToken(token.id)
          navigateTo(routes.start, {
            routeParams: {
              access_token: token.id
            }
          });
        }
      })
      .catch((reason) => {
        console.error(reason);
      });
  }

  useEffect(() => {
    clearSession();
    const username = `user${1e11 + Math.round(Math.random() * 899999999999)}`;
    const email = `${username}@example.com`;
    const password = '1234';
    Api.instance
      .createRandomUser(username, email, password)
      .then((newUser) => {
        console.log('newRandomUser', newUser);
        loginUser(email, password);
      })
      .catch((reason) => {
        console.error(reason);
      });
  }, []);

  return (
    <CommonStyles.FullHeightLoadingContainer>
      <ReactLoading type="spinningBubbles" color="#ffffff" height="20%" width="20%" />
    </CommonStyles.FullHeightLoadingContainer>
  );
};

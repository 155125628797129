import { Header } from 'components/Header/header';
import { H1small } from 'components/H1small/H1small';
import { H1 } from 'components/H1/H1';
import { Pbig } from 'components/Pbig/Pbig';
import { Psmall } from 'components/Psmall/Psmall';
import SearchIcon from 'assets/svg/search.svg';
import FilterAltOutlinedIcon from 'assets/svg/filter.svg';
import LockIcon from 'assets/svg/lock.svg';

import { NavigationWrapper, UiWrapper, TextField, Image } from 'coinscrap-webapp-core-ts';
import { routes } from 'routes';
import { useEffect, useState } from 'react';
import * as S from './styles';
import { MarginWrapper } from '../../layout/MarginWrapper/MarginWrapper';
import { Api } from '../../api/api';
import { CommonUtils } from '../../utils/CommonUtils';

const AvailableTournamentWrapper = ({fullScreen, tournament, children, ...props}) => {
  const { navigateTo } = NavigationWrapper.use();
  return <div
      className={`${(tournament.start.getTime() < Date.now() && tournament.public) ? 'joinable' : 'unjoinable'}`}
      onClick={() => {
        navigateTo(routes.tournamentJoin, {
          routeParams: {id: tournament.id},
          queryParams: {can_go_back: 'true', full_screen: 'true'},
        });
      }}
  >
    <S.Cajatransparente>
      {children}
    </S.Cajatransparente>
  </div>;
}

function AvailableTournamentsList({ tournaments, children, ...props }) {
  const fullScreen = new URLSearchParams(window.location.search).get('full_screen') || 'false';

  const list = tournaments.map((tournament) =>
      (
          <AvailableTournamentWrapper
              fullScreen={fullScreen}
              tournament={tournament}
              key={tournament.id}
          >
            <S.CajaAzulDegradado>
              <S.CajaDere>
                <S.Ico src={tournament.user?.photo || '/game/img/usuario1.svg'}/>
                <Pbig nowrap align="left" color="white">
                  {tournament.name || 'Torneo sin nombre'}
                </Pbig>
              </S.CajaDere>
              {!tournament.public &&
              <S.CajaIzq>
                <S.Row align="center" content='center'>
                  <S.Privado align="center" color="white">
                    Privado
                  </S.Privado>
                  <Image height="15px" width="auto" src={LockIcon}/>
                </S.Row>
              </S.CajaIzq>
              }
            </S.CajaAzulDegradado>
            <S.CajaInterior>
              <S.CajaDer>
                {(tournament.start.getTime() < Date.now()) ?
                    <Psmall color="black" marginBottom="15px">
                      {CommonUtils.formatTimeInterval('Empezó hace %n %s', Date.now() - tournament.start.getTime())}
                    </Psmall>
                    :
                    <Psmall color="black" marginBottom="15px">
                      Inicio: <span
                        style={{color: '#7f7f7f'}}> {tournament.start.getDate()}/{tournament.start.getMonth() + 1}/
                      {tournament.start.getFullYear()}</span>
                    </Psmall>}
                {(tournament.start.getTime() < Date.now()) && <Psmall color="red">
                  {CommonUtils.formatTimeInterval('Cierra en %n %s', tournament.limitJoinDate.getTime() - Date.now())}
                </Psmall>}
              </S.CajaDer>
              <S.CajaIzq>
                <H1 black>{tournament.wallets.length}</H1>
                <Psmall align="center" color="black">
                  Nº de jugadores
                </Psmall>
              </S.CajaIzq>
            </S.CajaInterior>
          </AvailableTournamentWrapper>
      ));
  return <S.Scroll style={{ width: '100%' }}>{list}</S.Scroll>;
}

export const Search = () => {
  const { navigateTo } = NavigationWrapper.use();
  const { useHeader } = UiWrapper.use();
  const [searchText, setSearchText] = useState('');

  const [availableTournaments, setAvailableTournaments] = useState([]);

  function filterTournaments(tournaments) {
    let searchLowerCase = '';
    if (searchText && searchText.trim().length >= 2) {
      searchLowerCase = searchText.toLowerCase();
    }
    return tournaments.filter(
      (it) =>
        it.name.toLowerCase().indexOf(searchLowerCase) !== -1 ||
        (it.isin || '').toLowerCase().indexOf(searchLowerCase) !== -1
    );
  }

  useEffect(() => {
    Api.instance.findAvailableTournaments().then((tournaments) => {
      if (tournaments) {
        setAvailableTournaments(tournaments);
      }
    });
  }, []);

  useHeader(<Header canGoBack={false} closeRoute={routes.tournamentHomeMain} />);
  return (
    <S.Wrapper>
      <S.Content>
        <MarginWrapper justifyContent="space-between">
          <S.Caja>
            <H1small>BUSCAR TORNEO</H1small>
            <TextField
              placeholder="Buscar torneo"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              startAdornment={<Image height="24px" width="auto" src={SearchIcon} />}
              endAdornment={<Image height="20px" width="auto" src={FilterAltOutlinedIcon} />}
              cursorSt
            />
            {/* Suggest input */}
            {/* <div onClick={(e) => { setSearchText('Torneo 0503') }}>
              <p style={{ color: '#c7c7c7', fontSize: 14, marginBottom: 20, marginLeft: 15 }}>Torneo 0503</p>
            </div> */}
            <AvailableTournamentsList tournaments={filterTournaments(availableTournaments)} />
          </S.Caja>
        </MarginWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

import { NavigationWrapper, Page, View, SessionWrapper } from 'coinscrap-webapp-core-ts';
import 'config/core';

import {BrowserRouter} from 'react-router-dom';
import { GlobalWrapper } from 'components/GlobalWrapper';
import { BasicLayout } from 'layout/BasicLayout';
import { RandomUser } from 'views/Start/RandomUser';
import { Start as TournamentStart } from 'views/Start/Start';
import { CreateAvatar } from 'views/Start/CreateAvatar';
import { Crear } from 'views/Crear/Crear';
import { NombreTorneo } from 'views/NombreTorneo/NombreTorneo';
import { FechasTorneo } from 'views/FechasTorneo/FechasTorneo';

import { routes, routeMapping } from './routes';
import { Visibility } from './views/Tournament/Create/Visibility';
import { WalletVisibility } from './views/Tournament/Create/WalletVisibility';
import { InitialBalance } from './views/Tournament/Create/InitialBalance';
import { Fees } from './views/Tournament/Create/Fees';
import { BettingStart } from './views/Betting/Create/BettingStart';
import { Amount } from './views/Betting/Create/Amount';
import { Market } from './views/Tournament/Create/Market';
import { ChooseMode } from './views/Tournament/Create/ChooseMode';
import { AdvancedMode } from './views/Tournament/Create/AdvancedMode';
import { Summary } from './views/Tournament/Create/Summary';
import { AdvancedSummary } from './views/Tournament/Create/AdvancedSummary';
import { Share } from './views/Tournament/Share';
import { Main } from './views/Tournament/Home/Main';
import { Overview } from './views/Tournament/Detail/Overview';
import { Competitor } from './views/Tournament/Competitor';
import { StockDetail } from './views/Tournament/Trade/StockDetail';
import { TradeSummary } from './views/Tournament/Trade/TradeSummary';
import { InputData } from './views/Tournament/Trade/InputData';
import { ChatDetail } from './views/Tournament/Chat/ChatDetail';
import { Chats } from './views/Tournament/Chat/Chats';
import { Search } from './views/Tournament/Search';
import { Join } from './views/Tournament/Join';
import { CommonUtils } from './utils/CommonUtils';

if (CommonUtils.pushToNative && /(next\.dev\.senseitrade\.com|ngrok\.io|localhost:)/gi.exec(window.__CLIENT_ENV.CLIENT_ENV_API_BASE_URL || '')) {
  const initialLog = console.log;
  window.console.log = (...args) => {
    initialLog(...args);
    CommonUtils.pushToNative({
      body: { action: 'logInfo', params: { args } },
    });
  };
  const initialError = console.error;
  window.console.error = (...args) => {
    initialError(...args);
    CommonUtils.pushToNative({
      body: { action: 'logError', params: { args } },
    });
  };
}

const DefaultRedirect = () => {
  const { navigateTo } = NavigationWrapper.use();
  navigateTo(routes.start, { mode: 'replace' });
  setTimeout(() => {
    window.location.reload();
  }, 67);
  return <View />;
};

const RedirectToOverview = () => {
  const { navigateTo } = NavigationWrapper.use();
  navigateTo(routes.tournamentDetailOverviewFinal);
  return <View />;
}

SessionWrapper.defineSessionChecker(async (sessionData, coinscrapApi, coreFetch, enabledRoles) => {
  console.log('defineSessionChecker', sessionData);
  return true;
});

export const App = () => (
    // <BrowserRouter basename={process.env.PUBLIC_URL}>
    <GlobalWrapper>
      <BasicLayout>
        <Page path={routeMapping[routes.entry]} name={routes.entry} component={DefaultRedirect}/>
        <Page path={routeMapping[routes.start]} name={routes.start} component={TournamentStart}/>
        <Page path={routeMapping[routes.newRandomUser]} name={routes.newRandomUser} component={RandomUser}/>
        <Page
            path={routeMapping[routes.createAvatar]}
            name={routes.createAvatar}
            component={CreateAvatar}
        />
        <Page
            path={routeMapping[routes.tournamentCreate]}
            name={routes.tournamentCreate}
            component={Crear}
        />
        <Page
            path={routeMapping[routes.tournamentCreateName]}
            name={routes.tournamentCreateName}
            component={NombreTorneo}
        />
        <Page
            path={routeMapping[routes.tournamentCreateDates]}
            name={routes.tournamentCreateDates}
            component={FechasTorneo}
        />
        <Page
            path={routeMapping[routes.tournamentCreateVisibility]}
            name={routes.tournamentCreateVisibility}
            component={Visibility}
        />
        <Page
            path={routeMapping[routes.tournamentCreateWalletVisibility]}
            name={routes.tournamentCreateWalletVisibility}
            component={WalletVisibility}
        />
        <Page
            path={routeMapping[routes.tournamentCreateInitialBalance]}
            name={routes.tournamentCreateInitialBalance}
            component={InitialBalance}
        />
        <Page
            path={routeMapping[routes.tournamentCreateFees]}
            name={routes.tournamentCreateFees}
            component={Fees}
        />
        <Page
            path={routeMapping[routes.bettingCreateStart]}
            name={routes.bettingCreateStart}
            component={BettingStart}
        />
        <Page
            path={routeMapping[routes.bettingCreateAmount]}
            name={routes.bettingCreateAmount}
            component={Amount}
        />
        <Page
            path={routeMapping[routes.tournamentCreateMarket]}
            name={routes.tournamentCreateMarket}
            component={Market}
        />
        <Page
            path={routeMapping[routes.tournamentCreateChooseMode]}
            name={routes.tournamentCreateChooseMode}
            component={ChooseMode}
        />
        <Page
            path={routeMapping[routes.tournamentCreateAdvancedMode]}
            name={routes.tournamentCreateAdvancedMode}
            component={AdvancedMode}
        />
        <Page
            path={routeMapping[routes.tournamentCreateSummary]}
            name={routes.tournamentCreateSummary}
            component={Summary}
        />
        <Page
            path={routeMapping[routes.tournamentCreateAdvancedSummary]}
            name={routes.tournamentCreateAdvancedSummary}
            component={AdvancedSummary}
        />
        <Page
            path={routeMapping[routes.tournamentShare]}
            name={routes.tournamentShare}
            component={Share}
        />
        <Page
            path={routeMapping[routes.tournamentHomeMain]}
            name={routes.tournamentHomeMain}
            component={Main}
        />
        <Page
            path={routeMapping[routes.tournamentDetailOverviewFinal]}
            name={routes.tournamentDetailOverviewFinal}
            component={Overview}
        />
        <Page
            path={routeMapping[routes.tournamentDetailOverview]}
            name={routes.tournamentDetailOverview}
            component={RedirectToOverview}
        />
        <Page
            path={routeMapping[routes.tournamentCompetitor]}
            name={routes.tournamentCompetitor}
            component={Competitor}
        />
        <Page
            path={routeMapping[routes.tournamentTradeStockDetail]}
            name={routes.tournamentTradeStockDetail}
            component={StockDetail}
        />
        <Page
            path={routeMapping[routes.tournamentTradeInputData]}
            name={routes.tournamentTradeInputData}
            component={InputData}
        />
        <Page
            path={routeMapping[routes.tournamentTradeSummary]}
            name={routes.tournamentTradeSummary}
            component={TradeSummary}
        />
        <Page
            path={routeMapping[routes.tournamentChats]}
            name={routes.tournamentChats}
            component={Chats}
        />
        <Page
            path={routeMapping[routes.tournamentChatDetail]}
            name={routes.tournamentChatDetail}
            component={ChatDetail}
        />
        <Page
            path={routeMapping[routes.tournamentJoin]}
            name={routes.tournamentJoin}
            component={Join}
        />
        <Page
            path={routeMapping[routes.tournamentSearch]}
            name={routes.tournamentSearch}
            component={Search}
        />
      </BasicLayout>
    </GlobalWrapper>
    // </BrowserRouter>

);

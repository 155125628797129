import {CommonUtils} from './CommonUtils';

export class MemoryState {

    static loadTemporaryTournamentFromMemory(defaultValue = undefined) {
        if (this.tmpTournament) {
            return this.tmpTournament;
        }
        if (defaultValue !== undefined) {
            return defaultValue;
        }
        const tmpTournament = {};
        CommonUtils.normalizeTournament(tmpTournament);
        this.updateTemporaryTournamentInMemory(tmpTournament);
        return tmpTournament;
    }

    static updateTemporaryTournamentInMemory(partialTournament) {
        this.tmpTournament = {...(this.tmpTournament || {}), ...(partialTournament || {})};
    }

}
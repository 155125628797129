import {
  ApplicationWrapper,
  NavigationWrapper,
  UiWrapper,
  BackendWrapper,
  SessionWrapper,
  UserWrapper,
} from 'coinscrap-webapp-core-ts';
import { routes } from 'routes';
import { useEffect } from 'react';
import { EasyApi } from '../contexts/EasyApi';
import { CommonUtils } from '../utils/CommonUtils';

ApplicationWrapper.init({
  projectName: 'senseiwebappzerogame',
  contextTheme: 'dark',
  splashBackground: undefined,
});

const events = {
  // Evento sencillo
  messageDetailClicked: {
    route: 'message',
  },
};

const GlobalRouteListener = () => {
  const { location } = NavigationWrapper.use();

  useEffect(() => {
    if (location && CommonUtils.pushToNative) {
      CommonUtils.pushToNative({
        body: { action: 'locationChange', params: { pathname: location.pathname, search: location.search } },
      });
    }
  }, [location, location?.pathname, location?.search]);

  return null;
};

const NativeManager = () => {
  useEffect(() => {
    window.GameManager = window.GameManager || {

    }
  });
  return null;
}

const EntitiesWrapper = ({ children }) => (
  // const { navigateTo } = NavigationWrapper.use();
  // for (const route of Object.keys(routes)) {
  //   events[`${route}Navigate`] = {
  //     route: () => route,
  //   };
  // }

  <UserWrapper>{children}</UserWrapper>
);
export const GlobalWrapper = ({ children }) => (
  <ApplicationWrapper>
    <BackendWrapper enableNativeBridge>
      <UiWrapper backgroundImages={{}}>
        <NavigationWrapper navigationEvents={events}>
          <GlobalRouteListener />
          <NativeManager />
          <SessionWrapper
            entryPoint={{
              pageIfValid: routes.entry,
              pageIfInvalid: routes.onBoarding,
            }}
          >
            <EntitiesWrapper>
              <EasyApi />
              {children}
            </EntitiesWrapper>
          </SessionWrapper>
        </NavigationWrapper>
      </UiWrapper>
    </BackendWrapper>
  </ApplicationWrapper>
);

import { UiWrapper } from 'coinscrap-webapp-core-ts';

UiWrapper.defineComponent('radioOption', {
  styles: {
    default: {
      radioComponent: {
        display: 'none',
      },
      containerComponent: {
        marginLeft: '0',
        marginRight: '0',
        marginBottom: '15px',
        background: 'transparent',
        display: 'block ruby',
        whiteSpace: 'nowrap',
      },
      textComponent: {
        color: 'white',
        border: '2px solid var(--color-grey)',
        borderRadius: '20px',
        padding: '10px 15px',
        marginRight: '10px',
        fontFamily: 'Roboto, sans-serif !important',
        fontSize: '13px',
        lineHeight: '15px',
        background: 'transparent',
      },
    },
    selectedSt: {
      textComponent: {
        border: '2px solid var(--color-violet)',
        background: 'var(--color-violet)',
        fontWeight: 'bold',
      },
    },
  },
});

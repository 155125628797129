import styled from 'styled-components/macro';
import { space, layout } from 'styled-system';

export const Plabel = styled.p`
  color: var(--color-white);
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 14, 95px;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  width: 100%;
  ${space};
  ${layout};
`;

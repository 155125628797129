import {NavigationWrapper, Page, View, SessionWrapper} from 'coinscrap-webapp-core-ts';
import Modal from '@material-ui/core/Modal';
import {useState} from 'react';
import * as S from '../views/Tournament/styles';

export const BasesModalOpener = ({openModalFn, style}) => <span style={{color: 'white', fontSize: '10px', ...style}}>
            Consulta las <a
    type="button"
    href="#"
    style={{color: '#007eff'}}
    onClick={() => openModalFn()}>
              bases del torneo.
            </a>
      </span>

export const BasesModal = ({isOpenModal, closeModalFn}) =>
    <Modal open={isOpenModal} onClose={() => closeModalFn()}>
        <View style={{/*{paddingTop: '5px', paddingBottom}*/}}>
            <S.Modal style={{
                height: '95%',
                margin: '5px',
                padding: '2px',
                display: 'flex',
                flexDirection: 'column',
                background: 'white'
            }}>
                <iframe
                    style={{height: '100%', borderStyle: 'none', borderWidth: '0', borderRadius: '5px', flex: 'auto'}}
                    title="bases torneo" src="https://senseizero.es/bases-legales-torneo-bolsa/"/>
                <a href="#" style={{color: 'blue', padding: '15px', width: '100%', textAlign: 'end'}}
                   onClick={() => closeModalFn()}>OK</a>
            </S.Modal>
        </View>
    </Modal>;

// export function useBasesModal() {
//
//     const [openBasesModal, setOpenBasesModal] = useState(false);
//
//     return {ModalOpener: BasesModalOpener, BasesModal}
// }
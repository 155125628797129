import { Header } from 'components/Header/header';
import { H1small } from 'components/H1small/H1small';
import { Psmall } from 'components/Psmall/Psmall';
import { P } from 'components/P/P';

import { UiWrapper, TextField } from 'coinscrap-webapp-core-ts';
import * as S from './styles';
import { MarginWrapper } from '../../../layout/MarginWrapper/MarginWrapper';
import { routes } from '../../../routes';

export const ChatDetail = () => {
  const { useHeader } = UiWrapper.use();

  useHeader(<Header closeRoute={routes.tournamentHomeMain} />);
  return (
    <S.Wrapper>
      <S.Content>
        <MarginWrapper justifyContent="space-between">
          <S.Caja fijo>
            <H1small center>CHAT SENSEI</H1small>
            <S.CajaChat margintop>
              <S.IcoChat src="/game/img/usuario1.svg" />
              <S.CajaChat marginleft margin>
                <S.FlechaChat src="/game/img/chat.svg" />
                <S.CajaInteriorChat>
                  <P bold marginBottom="15px">
                    David Conde
                  </P>
                  <P>
                    ¡Chicos! Os quedan 2 días para terminar el torneo, dadle caña que os invito a
                    unas copas al terminar.
                  </P>
                  <Psmall color="black" absolute align="right">
                    11:40
                  </Psmall>
                </S.CajaInteriorChat>
              </S.CajaChat>
            </S.CajaChat>
            <S.CajaChat margintop>
              <S.IcoChat src="/game/img/usuario1.svg" />
              <S.CajaChat marginleft margin>
                <S.FlechaChat src="/game/img/chat.svg" />
                <S.CajaInteriorChat>
                  <P bold marginBottom="15px">
                    David Conde
                  </P>
                  <P>¡Genial!</P>
                  <Psmall color="black" absolute align="right">
                    11:45
                  </Psmall>
                </S.CajaInteriorChat>
              </S.CajaChat>
            </S.CajaChat>
            <S.CajaChat margintop>
              <S.IcoChat src="/game/img/usuario1.svg" />
              <S.CajaChat marginleft margin>
                <S.FlechaChat src="/game/img/chat.svg" />
                <S.CajaInteriorChat>
                  <P bold marginBottom="15px">
                    Teresa LaFuente
                  </P>
                  <P>Yo sí, no me lo podía creer.</P>
                  <Psmall color="black" absolute align="right">
                    11:50
                  </Psmall>
                </S.CajaInteriorChat>
              </S.CajaChat>
            </S.CajaChat>
            <S.CajaChat margintop right>
              <S.CajaChat marginright margin right>
                <S.CajaInteriorChat>
                  <P>
                    ¡Chicos! Os quedan 2 días para terminar el torneo, dadle caña que os invito a
                    unas copas al terminar.
                  </P>
                  <Psmall color="black" absolute align="right">
                    11:40
                  </Psmall>
                </S.CajaInteriorChat>
                <S.FlechaChat src="/game/img/chat.svg" right />
              </S.CajaChat>
              <S.IcoChat src="/game/img/usuario2.svg" />
            </S.CajaChat>
            <S.CajaChat margintop right>
              <S.CajaChat marginright margin right>
                <S.CajaInteriorChat>
                  <P>
                    ¡Chicos! Os quedan 2 días para terminar el torneo, dadle caña que os invito a
                    unas copas al terminar.
                  </P>
                  <Psmall color="black" absolute align="right">
                    11:40
                  </Psmall>
                </S.CajaInteriorChat>
                <S.FlechaChat src="/game/img/chat.svg" right />
              </S.CajaChat>
              <S.IcoChat src="/game/img/usuario2.svg" />
            </S.CajaChat>
          </S.Caja>
          <S.Fijo>
            <TextField
              cursorSt
              placeholder="Escribe el mensaje aquí" /*endAdornment={<Image height="28px" width="auto" src={AudioIcon} />}*/
            />
          </S.Fijo>
        </MarginWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

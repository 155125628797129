import * as S from './styles';

export const H1slide = ({ children, className, marginBottom, center, paddingX }) => (
  <S.H1slide
    className={className + ' h1 '}
    marginBottom={marginBottom}
    center={center}
    paddingX={paddingX}
  >
    {children}
  </S.H1slide>
);

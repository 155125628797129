import { H1regular } from 'components/H1regular/H1regular';
import { P } from 'components/P/P';
import { H5 } from 'components/H5/H5';
import { Header } from 'components/Header/header';

import { MarginWrapper } from 'layout/MarginWrapper/MarginWrapper';
import { NavigationWrapper, UiWrapper } from 'coinscrap-webapp-core-ts';
import { routes } from 'routes';
import * as S from './styles';

export const ChooseMode = () => {
  const { navigateTo } = NavigationWrapper.use();
  const { useHeader } = UiWrapper.use();

  useHeader(<Header closeRoute={routes.tournamentHomeMain} />);
  return (
    <S.Wrapper>
      <S.Content>
        <MarginWrapper justifyContent="space-between">
          <S.Caja>
            <H1regular>Modo de Juego</H1regular>
            <P color="white" align="center" marginBottom="35px" marginTop="10px">
              Elige el Mercado en el que quieras operar durante el Torneo
            </P>
            <S.Divimg
              onClick={() => {
                navigateTo(routes.tournamentCreateSummary);
              }}
            >
              <S.Imgsmall src="/game/img/ibex35.svg" />
              <H5 marginBottom="15px">Modo Básico</H5>
            </S.Divimg>
            <S.Divimgt
              onClick={() => {
                navigateTo(routes.tournamentCreateAdvancedMode);
              }}
            >
              <S.Imgsmall src="/game/img/ibex35.svg" />
              <H5 pink marginBottom="15px">
                Avanzado
              </H5>
              <S.Absolute>para expertos</S.Absolute>
            </S.Divimgt>
          </S.Caja>
        </MarginWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

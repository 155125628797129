import styled from 'styled-components/macro';
import { space, layout } from 'styled-system';

export const H1regular = styled.h1`
  color: var(--color-white);
  font-family: 'Eurostile';
  font-size: 27px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
  ${space};
  ${layout};
`;

import styled from 'styled-components/macro';
import { verticalFlex } from 'styles/common';

export const Wrapper = styled.div`
  ${verticalFlex}
  align-items: center;
  justify-content:space-between;
  width: 100%;
  min-height: 100%;
`;

export const Content = styled.div`
  ${verticalFlex}
  flex: 1;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
  height: 100%;
`;

export const Caja = styled.div`
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  align-items: flex-end;
  display: flex;
`;

export const H1 = styled.h1`
  font-family: EurostileBold;
  font-size: 28px;
  margin-bottom: 15px;
  text-align: right;
  color: white;
`;

export const H2 = styled.h2`
  font-family: Helvetica;
  font-size: 38px;
  margin-bottom: 15px;
  text-align: right;
  color: white;
  font-weight: 100;
`;

export const Small = styled.span`
  font-family: Helvetica;
  font-weight: 400;
  font-size: ${(props) => (props.small ? '8px' : '9px')};
  text-align: ${(props) => (props.center ? 'center' : 'right')};
  color: white;
  margin-bottom: ${(props) => (props.marginBottom ? '20px' : '0')};
  text-transform: ${(props) => (props.uppercase ? 'uppercase' : 'initial')};
  letter-spacing: 0.25px;
  display: ${(props) => (props.small ? 'block' : 'initial')};
  margin-top: ${(props) => (props.marginTop ? '10px' : '0')};
`;

export const Verde = styled.span`
  font-family: Helvetica;
  font-size: 9px;
  margin-bottom: 25px;
  text-align: right;
  text-transform: uppercase;
  color: ${(props) => (props.color || '#9EF2AD')};
`;

export const IcoVerde = styled.span`
  margin-right: 5px;
  text-align: right;
  color: #9EF2AD;
`;

export const IcoAzul = styled.span`
  margin-left: 10px;
  color: #01A8FA;
  vertical-align: middle;
`;

export const Row = styled.div`
  margin: 0 -20px;
  width: calc(100% + 40px);
  margin-bottom: ${(props) => (props.marginBottom)};
  position: relative;
`;

export const Div = styled.div`
  padding: 50px 20px 0;
  width: 100%;
  // border-top-width: 1px;
  // border-top-color: #02BCFA;
  // border-top-style: dashed;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const P = styled.span`
  font-family: HelveticaBold;
  font-size: 10px;
  text-align: left;
  color: white;
  width: 100%;
  margin-bottom: 20px;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => (props.padding ? '0 20px' : 'initial')};
`;

export const ProgressBar = styled.div`
  width: 80px;
  height: 12px;
  border-radius: 20px;
  background: linear-gradient(to right, #dc3545 0% ${(props) => (props.red)}%, #9EF2AD ${(props) => (props.red)}% 100%);
  margin-left: 10px;
  position: relative;
`;

export const ProgressBarLabel = styled.span`
  position: absolute;
  font-family: Helvetica;
  font-size: 8px;
  color: white;
  top: -15px;
  right: 0;
  letter-spacing: 0.25px;
`;

export const Texto = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  padding: 0 20px;
`;

export const H3 = styled.h3`
  font-family: EurostileBold;
  font-size: 17px;
  margin-bottom: 15px;
  text-align: center;
  color: white;
  background: ${(props) => (props.active ? '#02BCFA' : 'initial')};
  border-radius: ${(props) => (props.active ? '50px' : 'initial')};
  padding: ${(props) => (props.active ? '5px 4px' : 'initial')};
`;

export const Resumen = styled.div`
  width: 100%;
`;

export const Compra = styled.div`
  padding: 0 20px;
`;

export const Fila = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
`;

export const Columna = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Fondo = styled.div`
  padding: 10px;
  background: rgba(255,255,255,0.16);
  border-radius: 10px;
  margin-bottom: 10px;
`;

export const H4 = styled.h4`
  font-family: EurostileBold;
  font-size: 22px;
  margin-bottom: 15px;
  text-align: center;
  color: white;
`;

export const H5 = styled.h5`
  font-family: HelveticaBold;
  font-size: 11px;
  margin-bottom: 20px;
  text-align: center;
  color: white;
`;

export const H6 = styled.h6`
  font-family: EurostileBold;
  font-size: 11px;
  margin-bottom: ${(props) => (props.sinmargin ? '0' : '20px')};
  text-align: justify;
  color: ${(props) => (props.blue ? '#02BCFA' : 'rgba(255,255,255,0.6)')};
`;
import styled from 'styled-components/macro';
import { space, layout } from 'styled-system';

export const H2small = styled.h1`
  color: var(--color-pink);
  font-family: 'Eurostile';
  font-size: 13px;
  line-height: 3.5vh;
  text-align: left;
  text-transform: uppercase;
  ${space};
  ${layout};
  @media (max-width: 990px) {
    font-size: 10px;
  }
`;

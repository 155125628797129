import { MainButton } from 'components/MainButton/MainButton';
import { TransparentSmallButton } from 'components/TransparentSmallButton/TransparentSmallButton';
import { H3 } from 'components/H3/H3';
import { P } from 'components/P/P';
import { Header } from 'components/Header/header';

import { useState } from 'react';
import { MarginWrapper } from 'layout/MarginWrapper/MarginWrapper';
import {
  NavigationWrapper,
  Input,
  UiWrapper,
  InputRadio,
  RadioOption,
} from 'coinscrap-webapp-core-ts';
import { routes } from 'routes';
import * as S from './styles';

export const Amount = () => {
  const { navigateTo } = NavigationWrapper.use();
  const [selectfees, setSelectfees] = useState('');
  const { useHeader } = UiWrapper.use();

  useHeader(<Header closeRoute={routes.tournamentHomeMain} />);
  return (
    <S.Wrapper>
      <S.Content>
        <MarginWrapper justifyContent="space-between">
          <S.Caja>
            <H3>¿De cuanto es la Porra?</H3>
            <Input type="number" placeholder="Ej: 10€" style={{ cursor: 'pointer' }} />
            <P color="white" marginBottom="35px">
              El importe que tendrá que aportar a la porra cada persona que participe en el Torneo.
            </P>
            <P color="white" marginBottom="15px">
              Importes populares:
            </P>
            <S.Cajita>
              <InputRadio
                value={selectfees}
                onChange={(e) => {
                  setSelectfees(e.target.value);
                }}
              >
                <RadioOption value="1" selectedSt={selectfees === '1'}>
                  1€
                </RadioOption>
                <RadioOption value="2" selectedSt={selectfees === '2'}>
                  2€
                </RadioOption>
                <RadioOption value="5" selectedSt={selectfees === '5'}>
                  5€
                </RadioOption>
                <RadioOption value="10" selectedSt={selectfees === '10'}>
                  10€
                </RadioOption>
                <RadioOption value="20" selectedSt={selectfees === '20'}>
                  20€
                </RadioOption>
              </InputRadio>
            </S.Cajita>
          </S.Caja>
          <S.Div>
            <TransparentSmallButton
              full
              fullWidth
              marginBottom="15px"
              marginTop="10px"
              onClick={() => {
                navigateTo(routes.bettingCreateStart);
              }}
            >
              Saber más
            </TransparentSmallButton>
            <MainButton
              pink
              full
              fullWidth
              marginTop="10px"
              onClick={() => {
                navigateTo(routes.tournamentCreateMarket);
              }}
            >
              SIGUIENTE
            </MainButton>
          </S.Div>
        </MarginWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

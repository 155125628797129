import { Header } from 'components/Header/header';
import { Pbig } from 'components/Pbig/Pbig';
import { MainButton } from 'components/MainButton/MainButton';

import { NavigationWrapper, UiWrapper, Input, Page } from 'coinscrap-webapp-core-ts';
import { routes } from 'routes';
import { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import * as S from './styles';
import { MarginWrapper } from '../../../layout/MarginWrapper/MarginWrapper';
import { Api } from '../../../api/api';
import { TournamentUtils } from '../../../utils/TournamentUtils';
import * as CommonStyles from '../../../styles/common';

export const InputData = () => {
  const { navigateTo } = NavigationWrapper.use();
  const { useHeader } = UiWrapper.use();

  const { params, query } = Page.use();
  const { instrumentId, tournamentId, orderType } = query;

  const [instrument, setInstrument] = useState(null);
  const [wallet, setWallet] = useState(null);
  const [remainingTitles, setRemainingTitles] = useState(0);
  const [amount, setAmount] = useState(0);
  const [estimated, setEstimated] = useState(0);

  const [loadingData, setLoadingData] = useState(false);

  function updateAmount(value) {
    if (value && value.trim()) {
      try {
        const newAmount = parseInt(value);
        setAmount(newAmount);
      } catch (e) {
        console.error(e);
        setAmount(1);
      }
    }
  }

  useEffect(() => {
    if (instrumentId) {
      setLoadingData(true);
      // eslint-disable-next-line no-shadow
      Api.instance.findInstrumentById(instrumentId).then((instrument) => {
        if (instrument) {
          setInstrument({
            ...instrument,
          });
        }
      }).finally(setLoadingData(false));
    }
  }, [instrumentId]);

  useEffect(() => {
    if (tournamentId) {
      setLoadingData(true);
      // eslint-disable-next-line no-shadow
      Api.instance.findMyWalletInTournament(tournamentId).then((wallet) => {
        console.log('wallet', wallet);
        if (wallet) {
          setWallet({
            ...wallet,
          });
        }
      }).finally(setLoadingData(false));
    }
  }, [tournamentId]);

  useEffect(() => {
    setEstimated(amount * instrument?.last);
  }, [instrument, amount]);

  useEffect(() => {
    if (amount) {
      localStorage.setItem('trade_amount', amount);
    }
  }, [amount]);

  useEffect(() => {
    if (instrument && wallet) {
      if (orderType === '12') {
        setLoadingData(true);
        Api.instance
          .findRemainingOrdersByWalletAndIsin('buy', wallet.id, instrument.isin)
          .then((buyOrders) => {
            if (buyOrders) {
              let newRemaining = 0;
              for (let i = 0; i < buyOrders.length; i++) {
                const buyOrder = buyOrders[i];
                newRemaining += buyOrder.remainingTitles;
              }
              setRemainingTitles(newRemaining);
            }
          }).finally(setLoadingData(false));
      }
    }
  }, [instrument, wallet]);

  useEffect(() => {
    if (instrument) {
      Api.instance.socketHandler.subscribeInstrument(
        instrument.ticker,
        instrument.marketId,
        (updatedInstrument) => {
          instrument.last = updatedInstrument.last;
          instrument.var = updatedInstrument.var;
          instrument.vol = updatedInstrument.vol;
          instrument.lastSeen = updatedInstrument.lastSeen;
        }
      );
    }
  }, [instrument]);

  useEffect(() => {
    updateAmount(localStorage.getItem('trade_amount') || '1');
  }, []);

  useHeader(<Header closeRoute={routes.tournamentHomeMain} />);

  return loadingData ? (
    <CommonStyles.InlineLoadingContainer>
      <ReactLoading type="spinningBubbles" color="#ffffff" height="20%" width="20%" />
    </CommonStyles.InlineLoadingContainer>
  ) : (
    <S.Wrapper>
      <S.Content>
        <MarginWrapper justifyContent="space-between">
          <S.Caja>
            <S.H1>{instrument?.ticker}</S.H1>
            <S.Small uppercase marginBottom>
              {instrument?.isin} - {instrument?.name}
            </S.Small>
            <S.H2>{TournamentUtils.formatCurrency(instrument?.last, 2, true, wallet?.tournament?.market?.currency)}</S.H2>
          </S.Caja>
          <S.Compra>
            <Pbig color="white" align="center">
              ¿Cuántas acciones quieres {orderType === '11' ? 'comprar' : 'vender'}?
            </Pbig>
            <Input
              type="number"
              placeholder="Ej: 100"
              value={amount}
              onChange={(e) => updateAmount(e.target.value)}
              style={{ cursor: 'pointer' }}
            />
            <S.Fila>
              <S.Columna>
                <Pbig color="white" align="center" marginBottom="15px">
                  Disponible
                </Pbig>
                <Pbig color="white" align="center">
                  {orderType === '11'
                    ? TournamentUtils.formatCurrency(wallet?.balance, 2, true, wallet?.tournament?.market?.currency)
                    : `${remainingTitles} Acciones`}
                </Pbig>
              </S.Columna>
              <S.Columna>
                <Pbig color="white" align="center" marginBottom="15px">
                  Efectivo estimado
                </Pbig>
                <Pbig
                  align="center"
                  color={
                    orderType === '11'
                      ? TournamentUtils.signedColor(wallet?.balance - estimated)
                      : TournamentUtils.signedColor(remainingTitles - amount)
                  }
                >
                  {TournamentUtils.formatCurrency(estimated, 2, true, wallet?.tournament?.market?.currency)}
                </Pbig>
              </S.Columna>
            </S.Fila>
          </S.Compra>
          <MainButton
            fullWidth
            blue
            disabled={!wallet || !(orderType === '11' && estimated <= wallet?.balance || orderType === '12' && amount <= remainingTitles)}
            onClick={() => {
              navigateTo(routes.tournamentTradeSummary, {
                queryParams: {
                  instrumentId,
                  tournamentId,
                  amount,
                  walletId: wallet.id,
                  orderType,
                },
              });
            }}
          >
            REVISAR ORDEN
          </MainButton>
        </MarginWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

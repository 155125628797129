import styled from 'styled-components/macro';
import { verticalFlex } from 'styles/common';

export const Wrapper = styled.div`
  ${verticalFlex}
  align-items: center;
  background: var(--background-global);
  background-position: center;
  justify-content:space-between;
  background-size: cover;
  width: 100%;
  min-height: 100%;
`;

export const Content = styled.div`
  ${verticalFlex}
  flex: 1;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
  height: 100%;
`;

export const Lista = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 10;
`;

export const Caja = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 14px 0;
  margin: 0 20px;
  border-bottom: ${(props) => (props.linea ? '1px solid var(--color-white)' : '0px')};
`;

export const Cajita = styled.div`
  width: 85%;
`;

export const Div = styled.div`
  width: 100%;
`;


export const Img = styled.img`
  padding-right: 15px;
`;

export const Scroll = styled.div`
  max-height: 48vh;
  overflow: scroll;
`;

import { MainButton } from 'components/MainButton/MainButton';
import { H3 } from 'components/H3/H3';
import { P } from 'components/P/P';
import { Header } from 'components/Header/header';

import { useEffect, useState } from 'react';
import { MarginWrapper } from 'layout/MarginWrapper/MarginWrapper';
import {
  NavigationWrapper,
  Input,
  UiWrapper,
  InputRadio,
  RadioOption,
} from 'coinscrap-webapp-core-ts';
import { routes } from 'routes';
import * as S from './styles';
import { TournamentUtils, useTemporaryTournament } from '../../../utils/TournamentUtils';
import { Api } from '../../../api/api';

export const Fees = () => {
  const { navigateTo } = NavigationWrapper.use();
  const { useHeader } = UiWrapper.use();
  const [selectcommission, setSelectcommission] = useState('');
  const [selectcommissionOnlyProfit, setSelectcommissionOnlyProfit] = useState('yes');

  const [tmpTournament, setTmpTournament] = useTemporaryTournament();

  useEffect(() => {
    let toSet;
    switch (selectcommission) {
      case 'sc':
        toSet = 0;
        break;
      case '1':
      case '2':
      case '5':
      case '10':
      case '20':
      case '50':
        toSet = parseInt(selectcommission);
        break;
      default:
        return;
    }
    setTmpTournament({

      commission: toSet,
    });
  }, [selectcommission]);

  useEffect(() => {
    let toSet;
    switch (selectcommissionOnlyProfit) {
      case 'yes':
        toSet = true;
        break;
      case 'no':
        toSet = false;
        break;
      default:
        return;
    }
    setTmpTournament({

      commissionOnlyProfit: toSet,
    });
  }, [selectcommissionOnlyProfit]);

  useEffect(() => {
    if (typeof tmpTournament.commission === 'number') {
      const commission = Math.round(tmpTournament.commission);
      switch (commission) {
        case 0:
          setSelectcommission('sc');
          break;
        case 1:
        case 2:
        case 5:
        case 10:
        case 20:
        case 50:
          setSelectcommission(commission.toFixed(0));
          break;
        default:
          break;
      }
    }
  }, [tmpTournament]);

  function updateTmpTournamentCommission(e) {
    let parsed = parseFloat(e.target.value.replace(',', '.'));
    if (Number.isNaN(parsed)) {
      parsed = tmpTournament.commission;
    }
    const newInitialCommission = Math.min(100e6, Math.max(0.0, parsed));
    setTmpTournament({

      commission: newInitialCommission,
    });
  }

  useHeader(<Header closeRoute={routes.tournamentHomeMain} />);
  return (
    <S.Wrapper>
      <S.Content>
        <MarginWrapper justifyContent="space-between">
          <S.Caja>
            <H3>Valor de las comisiones</H3>
            <Input
              type="number"
              placeholder="Ej: 0€"
              value={tmpTournament.commission}
              onChange={(e) => updateTmpTournamentCommission(e)}
              style={{ cursor: 'pointer' }}
            />
            <P color="white" marginBottom="15px">
              Las comisiones se aplicarán en cada venta independientemente del importe de la
              transacción o del balance de la misma.
            </P>
            <P color="white" marginBottom="35px">
              Déjalo a 0€ si no quieres que se aplique ninguna comisión.
            </P>
            <P color="white" marginBottom="15px">
              Comisiones populares:
            </P>
            <S.Row marginBottom="30px">
              <S.Cajita>
                <InputRadio
                  value={selectcommission}
                  onChange={(e) => {
                    setSelectcommission(e.target.value);
                  }}
                >
                  <RadioOption value="sc" selectedSt={selectcommission === 'sc'}>
                    Sin comisiones
                  </RadioOption>
                  <RadioOption value="1" selectedSt={selectcommission === '1'}>
                    1€
                  </RadioOption>
                  <RadioOption value="2" selectedSt={selectcommission === '2'}>
                    2€
                  </RadioOption>
                  <RadioOption value="5" selectedSt={selectcommission === '5'}>
                    5€
                  </RadioOption>
                  <RadioOption value="10" selectedSt={selectcommission === '10'}>
                    10€
                  </RadioOption>
                  <RadioOption value="20" selectedSt={selectcommission === '20'}>
                    20€
                  </RadioOption>
                  {/*<RadioOption value="50" selectedSt={selectcommission === '50'}>*/}
                  {/*  50€*/}
                  {/*</RadioOption>*/}
                </InputRadio>
              </S.Cajita>
            </S.Row>
            {tmpTournament?.commission > 0 ? (
              <P color="white" marginBottom="15px">
                ¿Quieres comisionar sólo las ventas con beneficio? (Como en SenseiZero):
              </P>
            ) : null}
            {tmpTournament?.commission > 0 ? (
              <InputRadio
                value={tmpTournament?.commissionOnlyProfit?.toString()}
                onChange={(e) => {
                  // setSelectcommissionOnlyProfit(e.target.value);
                  setTmpTournament({ commissionOnlyProfit: e.target.value === 'true' })
                }}
              >
                <RadioOption value="true" selectedSt={tmpTournament?.commissionOnlyProfit}>
                  Sí
                </RadioOption>
                <RadioOption value="false" selectedSt={!tmpTournament?.commissionOnlyProfit}>
                  No, todas las ventas
                </RadioOption>
              </InputRadio>
            ) : null}
          </S.Caja>
          <MainButton
            pink
            full
            fullWidth
            disabled={tmpTournament.commission > 20}
            marginTop="10px"
            onClick={() => {
              // navigateTo(routes.bettingCreateStart);
              navigateTo(routes.tournamentCreateMarket);
            }}
          >
            SIGUIENTE
          </MainButton>
        </MarginWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

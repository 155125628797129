import * as S from './styles';

export const H3 = ({ children, className, marginBottom, marginTop, center, paddingX }) => (
  <S.H3
    className={className + ' h3'}
    marginBottom={marginBottom}
    marginTop={marginTop}
    center={center}
    paddingX={paddingX}
  >
    {children}
  </S.H3>
);

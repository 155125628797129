import { Header } from 'components/Header/header';
import { H1small } from 'components/H1small/H1small';
import { P } from 'components/P/P';
import { H1 } from 'components/H1/H1';
import { Psmall } from 'components/Psmall/Psmall';
import SearchIcon from 'assets/svg/search.svg';
import { H1bold } from 'components/H1bold/H1bold';
import { Pbig } from 'components/Pbig/Pbig';
import ShareIcon from '@material-ui/icons/Share';

import { useEffect, useState } from 'react';
import { NavigationWrapper, UiWrapper, Image, TextField, Page } from 'coinscrap-webapp-core-ts';
import { routes, routeMapping } from 'routes';
import ReactLoading from 'react-loading';
import * as queryString from 'querystring';
import * as S from './styles';
import { MarginWrapper } from '../../../layout/MarginWrapper/MarginWrapper';
import { Api } from '../../../api/api';
import { H2 } from '../../../components/H2/styles';
import {TournamentUtils, useInterval} from '../../../utils/TournamentUtils';
import * as CommonStyles from '../../../styles/common';
import { CommonUtils } from '../../../utils/CommonUtils';
import { LineChart } from './LineChart';
import { BasesModal, BasesModalOpener } from '../../../components/BasesModal';

function CompetitorsList({
  isMyWallet = false,
  isTopList = true,
  isFirst = false,
  isLast = false,
  wallets,
  children,
  market,
  isFinished,
  navigateTo,
  ...props
}) {
  const fullScreen = new URLSearchParams(window.location.search).get('full_screen') || 'false';

  const topList = wallets.map((wallet) =>
    <S.Cajatransparente
      key={`${wallet.id}`}
      row
      onClick={() => {
        if (!isFinished) {
          navigateTo(routes.tournamentCompetitor, {
            routeParams: { id: wallet.user.id },
            queryParams: { full_screen: 'true' }
          });
        }
      }}
    >
      <S.CajaDere>
        <S.Flex>
          <P nowrap color="white" width10>
            {wallet.hasPositions ? wallet.position + 1 + 'º' : ''}
          </P>
          <S.Ranquing
            src={wallet.user?.photo ? wallet.user?.photo : '/game/img/avatars/avatar001.png'}
          />
          <P nowrap color="white" width10>
            {isMyWallet ? 'Tú' : wallet.user?.username}
          </P>
        </S.Flex>
      </S.CajaDere>
      <S.CajaIzq>
        <Psmall nowrap marginBottom="5px" align="right" color="white">
          {wallet.profitability > 0 ? <S.Top src="/game/img/top.svg" /> : null}
          <S.Span color={TournamentUtils.signedColor(wallet.profitability)} paddingright>
            {TournamentUtils.formatDecimals(wallet.profitability * 100, 1)}%
          </S.Span>
          {TournamentUtils.formatCurrency(wallet.balance + wallet.remainingValue, 2, true, market?.currency)}
        </Psmall>
      </S.CajaIzq>
    </S.Cajatransparente>);
  return (
    <S.Lista>
      {isMyWallet && !isFirst ? <S.More src="/game/img/more.svg" /> : null}
      {topList}
      {isMyWallet && !isLast ? <S.More src="/game/img/more.svg" /> : null}
    </S.Lista>
  );
}

function BuyExecutionsList({
  buyExecutions,
  displayUserInfo = true,
  tournament,
  children,
  market,
  navigateTo,
  ...props
}) {
  const list = buyExecutions.map((buyExecution) => {

    const buyValue = buyExecution.remainingTitles * buyExecution.executionPrice;
    const currentMarketValue =
      buyExecution.remainingTitles * (buyExecution.buyOrder?.instrument?.last || 0);
    const absProfit = currentMarketValue - buyValue;

    return displayUserInfo ? (
      <S.Cajatransparente
        row
        border
        key={buyExecution.id}
        onClick={() => {
          navigateTo(routes.tournamentCompetitor, {
            routeParams: { id: buyExecution.buyOrder.wallet.user.id },
          });
        }}
      >
        <S.CajaDere>
          <S.Flex>
            <S.Ico sinmargin src={buyExecution.buyOrder?.wallet?.user?.photo || '/game/img/usuario2.svg'} />
            <S.Column>
              <Psmall nowrap marginBottom="5px" color="white">
                {buyExecution.buyOrder?.wallet?.user?.username}
              </Psmall>
              <Psmall nowrap color="#b9b9b9">
                <span>{buyExecution.buyOrder?.instrument?.displayName}</span> (
                {buyExecution.buyOrder?.instrument?.ticker})
              </Psmall>
            </S.Column>
          </S.Flex>
        </S.CajaDere>
        <S.CajaIzq>
          <Psmall
            nowrap
            marginBottom="5px"
            align="right"
            color={TournamentUtils.signedColor(
              buyExecution.buyOrder?.instrument?.last - buyExecution.executionPrice
            )}
          >
            {buyExecution.buyOrder?.instrument?.last - buyExecution.executionPrice > 0 ? (
              <S.Top src="/game/img/top.svg" />
            ) : null}
            <S.Span
              color={TournamentUtils.signedColor(
                buyExecution.buyOrder?.instrument?.last - buyExecution.executionPrice
              )}
            >
              {TournamentUtils.formatDecimals(
                (buyExecution.buyOrder?.instrument?.last / buyExecution.executionPrice - 1) * 100,
                2
              )}
              %
            </S.Span>
          </Psmall>
          <Psmall nowrap align="right" color="black">
            <S.Trades src="/game/img/trades.svg" />
            <S.Span color="#b9b9b9">{buyExecution.executedTitles}</S.Span>
          </Psmall>
        </S.CajaIzq>
      </S.Cajatransparente>
    ) : (
      <div
        className="sellable-instrument"
        style={{ width: '100%' }}
        key={`${buyExecution.id}${buyExecution.buyOrder.instrument.last}`}
        onClick={() => {
          navigateTo(routes.tournamentTradeStockDetail, {
            routeParams: {
              id: buyExecution.buyOrder?.instrument?.id,
            },
            queryParams: { tournamentId: tournament.id, ticker: buyExecution.buyOrder?.instrument?.ticker, marketName: market?.name },
          });
        }}
      >

        <S.Cajatransparente
          row
          border
        >
          <S.CajaDere>
            <S.Flex>
              {/* <S.Ico sinmargin src="/game/img/usuario2.svg" /> */}
              <S.Column>
                <P className="instrument-name" nowrap marginBottom="5px" color="white" fontSize="10px">
                  {buyExecution.buyOrder?.instrument?.displayName} ({buyExecution.buyOrder?.instrument?.ticker})
                </P>
                <Psmall nowrap color="#b9b9b9">
                  <S.Trades src="/game/img/trades.svg" />
                  <S.Span color="#b9b9b9">
                    {buyExecution.remainingTitles} (
                    {TournamentUtils.formatCurrency(
                      buyExecution.remainingTitles * buyExecution.executionPrice, 2, true, market?.currency
                    )}
                    )
                  </S.Span>
                </Psmall>
              </S.Column>
            </S.Flex>
          </S.CajaDere>
          <S.CajaIzq>
            <P
              nowrap
              marginBottom="5px"
              color={TournamentUtils.signedColor(absProfit)}
              // color={TournamentUtils.signedColor(
              //     // eslint-disable-next-line no-nested-ternary
              //     buyExecution.buyOrder?.instrument?.lastDirection === 'up'
              //         ? 1
              //         : buyExecution.buyOrder?.instrument?.lastDirection === 'down'
              //             ? -1
              //             : 0,
              //     false
              // )}
              align="right"
            >
              {!Number.isNaN(absProfit) && absProfit >= 0 && '+'}
              {TournamentUtils.formatCurrency(absProfit, 2, true, market?.currency)}
            </P>
            <Psmall
              nowrap
              marginBottom="5px"
              align="right"
              color={TournamentUtils.signedColor(
                buyExecution.buyOrder?.instrument?.last - buyExecution.executionPrice
              )}
            >
              {buyExecution.buyOrder?.instrument?.last - buyExecution.executionPrice > 0 ? (
                <S.Top src="/game/img/top.svg" />
              ) : null}
              <S.Span
                color={TournamentUtils.signedColor(
                  buyExecution.buyOrder?.instrument?.last - buyExecution.executionPrice
                )}
              >
                {TournamentUtils.formatDecimals(
                  (buyExecution.buyOrder?.instrument?.last / buyExecution.executionPrice - 1) * 100,
                  2
                )}
                %
              </S.Span>
            </Psmall>
          </S.CajaIzq>
        </S.Cajatransparente>
      </div>
    );
  });
  return <S.Lista style={{ width: '100%' }}>{list}</S.Lista>;
}

function InstrumentsList({ tabName, tournament, instruments, active, children, market, navigateTo, ...props }) {
  const list = instruments.map((instrument) => {
    Api.instance.socketHandler.subscribeInstrument(
      instrument.ticker,
      instrument.marketId,
      (updatedInstrument) => {
        instrument.last = updatedInstrument.last;
        instrument.var = updatedInstrument.var;
        instrument.vol = updatedInstrument.vol;
        instrument.lastSeen = updatedInstrument.lastSeen;
      }
    );
    return (
      <div
        className={`${tabName}-purchasable-instrument`}
        style={{ width: '100%' }}
        key={`${instrument.id || instrument._id}${instrument.last}`}
        onClick={() => {
          //TODO: navigate to trade
          navigateTo(routes.tournamentTradeStockDetail, {
            routeParams: { id: instrument.id || instrument._id },
            queryParams: { tournamentId: tournament.id, ticker: instrument?.ticker, marketName: market?.name },
          });
          // setTimeout(() => {
          //   navigateTo(routes.tournamentCreate);
          // }, 128)
          // // /**
          // //  *
          // //  * @type {{
          // //  *     mode?: 'push' | 'replace';
          // //  *     routeParams?: {
          // //  *         [k: string]: string;
          // //  *     };
          // //  *     queryParams?: QueryParams;
          // //  *     handleRedirection?: boolean;
          // //  *     clearItems?: string[];
          // //  *     resetHistory?: boolean;
          // //  * }}
          // //  */
          // // let navigateToProps = {
          // //   queryParams: { id: instrument.id || instrument._id, tournamentId: tournament.id, ticker: instrument?.ticker, marketName: market?.name, full_screen: true },
          // //   resetHistory: false,
          // //   mode: 'push',
          // // };
          // // // navigateTo(routes.tournamentTradeStockDetail, navigateToProps);
          // // let route = `/game${routeMapping[routes.tournamentTradeStockDetail]}?${queryString.stringify(navigateToProps.queryParams)}`;
          // // console.log('push route', route);
          // // pushRoute(route, false);
        }}
      >

        <S.Cajatransparente
          row
          border
        >
          <S.CajaDere>
            <S.Flex>
              {/* <S.Ico sinmargin src="/game/img/usuario2.svg" /> */}
              <S.Column>
                <P nowrap marginBottom="5px" color="white" fontSize="12px">
                  {instrument.ticker}
                </P>
                <P nowrap color="white" fontSize="10px">
                  {instrument.displayName}
                </P>
              </S.Column>
            </S.Flex>
          </S.CajaDere>
          <S.CajaIzq>
            <P nowrap marginBottom="5px" color="white" align="right">
              {TournamentUtils.formatCurrency(instrument.last, 2, true, market?.currency)}
            </P>
            <Psmall
              nowrap
              marginBottom="5px"
              align="right"
              color={TournamentUtils.signedColor(instrument.var)}
            >
              {instrument.var > 0 ? <S.Top src="/game/img/top.svg" /> : null}
              <S.Span color={TournamentUtils.signedColor(instrument.var)}>
                {TournamentUtils.formatDecimals(
                  (instrument.var / (instrument.last - instrument.var)) * 100.0,
                  2
                )}
                %
              </S.Span>
            </Psmall>
          </S.CajaIzq>
        </S.Cajatransparente>
      </div>
    );
  });
  return (
    <S.ListTabs active={active} style={{ width: '100%' }}>
      {list}
    </S.ListTabs>
  );
}

let listeningInstruments = false;
export const Overview = () => {
  const { navigateTo } = NavigationWrapper.use();
  const [selectab, setSelecttab] = useState('o');
  const [selectTabMercado, setSelectTabMercado] = useState('recientes');
  const [setMore, setselectMore] = useState('n');
  const { useHeader } = UiWrapper.use();

  const { params, query } = Page.use();
  const { tab: newselectab, isEndTmt: isFinished } = query;
  const { id: tournamentId  /*otroParametroCualquiera*/ } = params;

  const [intervalStep] = useInterval(5000);

  const [tournament, setTournament] = useState(null);
  const [myWallet, setMyWallet] = useState(null);
  const [market, setMarket] = useState(null);
  const [wallets, setWallets] = useState([]);
  const [topWallets, setTopWallets] = useState([]);
  const [aboveWallets, setAboveWallets] = useState([]);
  const [bottomWallets, setBottomWallets] = useState([]);
  const [belowWallets, setBelowWallets] = useState([]);

  const [lastBuyExecutions, setLastBuyExecutions] = useState([]);
  const [remainingValue, setRemainingValue] = useState(0);
  const [latentProfitability, setLatentProfitability] = useState(0);
  const [remainingBuyExecutions, setRemainingBuyExecutions] = useState([]);

  const [lastBoughtInstruments, setLastBoughtInstruments] = useState([]);
  const [allInstruments, setAllInstruments] = useState([]);
  const [searchText, setSearchText] = useState('');

  const [loadingTournament, setLoadingTournament] = useState(true);
  const [loadingAllInstruments, setLoadingAllInstruments] = useState(true);
  const [requestingTournament, setRequestingTournament] = useState(false);
  const [loadingBuyExecutions, setLoadingBuyExecutions] = useState(false);

  const [openBasesModal, setOpenBasesModal] = useState(false);

  const [isCopied, setIsCopied] = useState(false);

  const copyTextToClipboard = () => {
    const route =
      'https://senseizero.powlink.io/mksim-join-trn?entw=e9c6e8f3b657ff1d52ba7eef13f2954b&tourn_id=' +
      tournament.id +
      '&share_code=' +
      tournament.shareCode;
    if (CommonUtils.pushToNative) {
      CommonUtils.pushToNative({
        body: {
          action: 'shareTournament',
          params: {
            route,
          },
        },
      });
    } else {
      navigator.clipboard
        .writeText(route)
        .then(() => {
          // If successful, update the isCopied state value
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, 3000);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  function filterInstruments(instruments) {
    let searchLowerCase = '';
    if (searchText && searchText.trim().length >= 2) {
      searchLowerCase = searchText.toLowerCase();
    }
    return instruments.filter(
      (it) =>
        it.ticker.toLowerCase().indexOf(searchLowerCase) !== -1 ||
        it.name.toLowerCase().indexOf(searchLowerCase) !== -1 ||
        (it.isin || '').toLowerCase().indexOf(searchLowerCase) !== -1
      // TODO: pendiente revisar, bloqueado por alto coste de computación para NASDAQ y otros
      //     if (!result) {
      //   for (const key of (it.keywords || [])) {
      //     if (key.toLowerCase().indexOf(searchLowerCase) !== -1) {
      //       result = true;
      //       break;
      //     }
      //   }
      // }
    );
  }

  function reloadTournament(skipComputing = false, syncComputing = false, skipCache = false) {
    if (requestingTournament) {
      return;
    }
    if (!skipCache) {
      setRequestingTournament(true);
    }
    Api.instance
      .findTournamentById(tournamentId, skipComputing, syncComputing, skipCache)
      .then((tournamentFromApi) => {

        if (tournamentFromApi) {
          const remainingDays = Math.floor(
            (tournamentFromApi.end.getTime() - Date.now()) / (86400 * 1000)
          );

          setTournament({
            ...tournamentFromApi,
            remainingDays,
          });

          setWallets(tournamentFromApi.wallets);

          setLoadingTournament(false);
          // if (!skipCache) {
          //   reloadTournament(false, true, true);
          // }
        }

        setRequestingTournament(false);
      })
      .catch((e) => {
        setRequestingTournament(false);
      });
  }

  useEffect(() => {
    if (newselectab !== null && newselectab !== undefined) {
      setSelecttab(newselectab);
    } else {
      const selectedTab = localStorage.getItem('overviewTab');
      if (selectedTab === null) {
        setSelecttab('o')
      } else {
        setSelecttab(selectedTab)
      }
    }
  }, [newselectab]);

  useEffect(() => {
    console.log('interval step', intervalStep);
    if (tournament?.id) {
      Api.instance.findTournamentById(tournament.id, false, true, true).then((tournamentFromApi0) => {
        if (tournamentFromApi0) {
          Api.instance.findTournamentById(tournament.id, false, true, true).then((tournamentFromApi1) => {
            if (tournamentFromApi1) {
              console.log('requested tournament again', tournamentFromApi1);
              // setTournament(tournamentFromApi1);
              setMyWallet(tournamentFromApi1.myWallet);
              setRemainingValue(tournamentFromApi1.myWallet.remainingValue);
            }
          });
        }
      });
    }
  }, [selectab, newselectab, query.tab, query.pageName, intervalStep]);

  useEffect(() => {
    if (wallets.length) {
      setTopWallets(wallets.slice(0, Math.min(3, tournament.myPosition)));
      setBottomWallets(wallets.slice(Math.max(tournament.myPosition + 1, Math.min(tournament.lastWalletWithPositions + 1, wallets.length) - 3)));

      setAboveWallets(wallets.slice(0, tournament.myPosition));
      setBelowWallets(wallets.slice(tournament.myPosition + 1));

      let allBuyExecutions = [];

      const boughtInstrumentsMap = {};

      for (let i = 0; i < wallets.length; i++) {
        const wallet = wallets[i];
        CommonUtils.sortBy(wallet.buyOrders, ['date', -1]);
        for (let j = 0; j < wallet.buyOrders.length; j++) {
          const buyOrder = wallet.buyOrders[j];
          console.log('put order in map', buyOrder.ISIN, buyOrder.instrument, buyOrder);
          boughtInstrumentsMap[buyOrder.ISIN] =
            boughtInstrumentsMap[buyOrder.ISIN] || buyOrder.instrument;
          TournamentUtils.processBuyOrderExecutions(buyOrder, wallet);
          allBuyExecutions = [...allBuyExecutions, ...buyOrder.buyExecutions];
        }
      }

      if (tournament?.publicWallet) {
        CommonUtils.sortBy(allBuyExecutions, ['executionDate', -1]);
        setLastBuyExecutions(allBuyExecutions.slice(0, 5));
      }

      const allBoughtInstruments = [];
      for (const [, instrument] of Object.entries(boughtInstrumentsMap)) {
        allBoughtInstruments.push(instrument);
      }

      console.log('allBoughtInstruments', allBoughtInstruments);
      setLastBoughtInstruments(allBoughtInstruments);
      if (allBoughtInstruments.length <= 0) {
        setSelectTabMercado('todas');
      }
    }
  }, [wallets]);

  useEffect(() => {
    if (tournament && tournament.marketName && tournament.marketName.trim()) {
      setLoadingAllInstruments(true);
      Api.instance.findMarketByNameWithInstruments(tournament.marketName).then((marketFromApi) => {

        if (marketFromApi) {
          setMarket(marketFromApi);
          setAllInstruments(marketFromApi.instruments);
          setLoadingAllInstruments(false);
        }
      });
    }
  }, [tournament]);

  useEffect(() => {
    if (myWallet) {
      setLatentProfitability(
        (myWallet?.balance + remainingValue) / tournament?.initialBalance - 1
      );
    }
  }, [myWallet, remainingValue]);

  useEffect(() => {
    if (tournament?.myWallet) {
      setMyWallet(tournament.myWallet);
      setRemainingValue(tournament.myWallet?.remainingValue);
      setLoadingBuyExecutions(true)
      Api.instance
        .findRemainingOrdersByWalletAndIsin('buy', tournament.myWallet.id, undefined)
        .then((buyOrders) => {
          if (buyOrders) {
            let newRemainingBuyExecutions = [];
            for (let i = 0; i < buyOrders.length; i++) {
              const buyOrder = buyOrders[i];
              TournamentUtils.processBuyOrderExecutions(buyOrder, tournament.myWallet);
              newRemainingBuyExecutions = [...newRemainingBuyExecutions, ...buyOrder.buyExecutions];
            }
            setRemainingBuyExecutions(newRemainingBuyExecutions);
          }
          setLoadingBuyExecutions(false);
        }).catch((e) => {
          setLoadingBuyExecutions(false);
        });
    }
  }, [tournament?.myWallet]);

  useEffect(() => {
    if (remainingBuyExecutions.length && !listeningInstruments) {
      listeningInstruments = true;
      const marketAndInstrumentMap = {};
      for (let i = 0; i < remainingBuyExecutions.length; i++) {
        const { buyOrder } = remainingBuyExecutions[i];
        const { instrument } = buyOrder;
        marketAndInstrumentMap[instrument.marketId] =
          marketAndInstrumentMap[instrument.marketId] || {};
        marketAndInstrumentMap[instrument.marketId][instrument.ticker] =
          marketAndInstrumentMap[instrument.marketId][instrument.ticker] || instrument;
      }
      for (const [marketId, instrumentMap] of Object.entries(marketAndInstrumentMap)) {
        for (const [ticker, _] of Object.entries(instrumentMap)) {
          const subs = Api.instance.socketHandler.subscribeInstrument(
            ticker,
            marketId,
            async (updatedInstrument) => {
              console.log('updated instrument', updatedInstrument);
              const { last, var: _var, vol, lastSeen } = updatedInstrument;
              for (let i = 0; i < remainingBuyExecutions.length; i++) {
                const buyExecution = remainingBuyExecutions[i];
                if (
                  buyExecution.buyOrder.instrument.ticker === updatedInstrument.ticker &&
                  buyExecution.buyOrder.instrument.marketId === updatedInstrument.marketId
                ) {
                  if (last >= buyExecution.buyOrder.instrument.last) {
                    buyExecution.buyOrder.instrument.lastDirection = 'up';
                  } else {
                    buyExecution.buyOrder.instrument.lastDirection = 'down';
                  }
                  buyExecution.buyOrder.instrument.last = last;
                  buyExecution.buyOrder.instrument.var = _var;
                  buyExecution.buyOrder.instrument.vol = vol;
                  buyExecution.buyOrder.instrument.lastSeen = lastSeen;
                }
              }
              const buyOrders = myWallet?.buyOrders || [];
              for (let i = 0; i < buyOrders.length; i++) {
                const buyOrder = buyOrders[i];
                if (
                  buyOrder.instrument.ticker === updatedInstrument.ticker &&
                  buyOrder.instrument.marketId === updatedInstrument.marketId
                ) {
                  buyOrder.instrument.last = last;
                  buyOrder.instrument.var = _var;
                  buyOrder.instrument.vol = vol;
                  buyOrder.instrument.lastSeen = lastSeen;
                }
              }
              if (myWallet) {
                Api.instance.findTournamentById(tournament.id, false, true, true).then((tournamentFromApi) => {
                    if (tournamentFromApi) {
                      console.log('requested tournament again', tournamentFromApi);
                      // setTournament(tournamentFromApi);
                      // setRemainingValue(tournamentFromApi.myWallet.remainingValue);
                    }
                });
              //   tournament.myWallet.prevRemainingValue = tournament?.myWallet?.remainingValue;
              //   const [newRemainingValue] = await Api.instance.computeWalletsRemainingValue([tournament.myWallet]);
              //   console.log('newRemainingValue', newRemainingValue)
              //   tournament.myWallet.targetRemainingValue = newRemainingValue;
              //   tournament.myWallet.shouldColor = true;
              //   setTimeout(() => {
              //     if (!tournament.myWallet.animating) {
              //       tournament.myWallet.shouldColor = false;
              //     }
              //     //   if (tournament?.myWallet?.remainingValue === newRemainingValue) {
              //     //     tournament.myWallet.prevRemainingValue = tournament?.myWallet?.remainingValue;
              //     //   }
              //     setRemainingValue(tournament?.myWallet?.targetRemainingValue);
              //   }, 1600);
              //   clearInterval(tournament?.myWallet?.animationId || -1);
              //   tournament.myWallet.animationId = CommonUtils.animateProperty(
              //     tournament.myWallet,
              //     'remainingValue',
              //     (currentValue, isEndValue) => {
              //       console.log(
              //         'changed remainingValue',
              //         tournament.myWallet.prevRemainingValue,
              //         '-->',
              //         currentValue
              //       );
              //       setTimeout(() => {
              //         tournament.myWallet.prevRemainingValue = tournament?.myWallet?.remainingValue;
              //       });
              //       tournament.myWallet.ephemeralRemainingValue = currentValue
              //       setRemainingValue(currentValue);
              //       tournament.myWallet.animating = !isEndValue;
              //     },
              //     tournament?.myWallet?.ephemeralRemainingValue || tournament?.myWallet?.remainingValue,
              //     newRemainingValue,
              //     1500,
              //     2
              //   );
              }
              setRemainingBuyExecutions(
                remainingBuyExecutions.map((buyExecution) => ({
                  ...buyExecution,
                  buyOrder: {
                    ...buyExecution.buyOrder,
                    instrument: {
                      ...buyExecution.buyOrder.instrument,
                    },
                  },
                }))
              );
              setTimeout(() => {
                for (let i = 0; i < remainingBuyExecutions.length; i++) {
                  const buyExecution = remainingBuyExecutions[i];
                  if (
                    buyExecution.buyOrder.instrument.ticker === updatedInstrument.ticker &&
                    buyExecution.buyOrder.instrument.marketId === updatedInstrument.marketId
                  ) {
                    delete buyExecution.buyOrder.instrument.lastDirection;
                  }
                }
                // setRemainingBuyExecutions(
                //   remainingBuyExecutions.map((buyExecution) => ({
                //     ...buyExecution,
                //     buyOrder: {
                //       ...buyExecution.buyOrder,
                //       instrument: {
                //         ...buyExecution.buyOrder.instrument,
                //       },
                //     },
                //   }))
                // );
              }, 2000);
              // reloadTournament();
            }
          );
          console.log('subscribe to', ticker, marketId, subs);
        }
      }
    }
  }, [remainingBuyExecutions]);

  useEffect(() => {
    setLoadingTournament(true);
    window.GameManager.reloadTournament = () => {
      // eslint-disable-next-line no-shadow
      reloadTournament();
    }
    window.GameManager.reloadTournament(); // Call multiple times from app
  }, []);

  useHeader(<Header canGoBack={false} closeRoute={routes.tournamentHomeMain} />);

  return loadingTournament ? (
    <CommonStyles.FullHeightLoadingContainer>
      <ReactLoading type="spinningBubbles" color="#ffffff" height="20%" width="20%" />
    </CommonStyles.FullHeightLoadingContainer>
  ) : (
    <S.Wrapper>
      <S.Content>
        <MarginWrapper justifyContent="start">
          <S.Tab>
            <S.TabTitle
              active={selectab === 'o'}
              onClick={(e) => {
                setSelecttab('o');
                localStorage.setItem('overviewTab', 'o');
              }}
            >
              <Image
                height="24px"
                width="auto"
                marginBottomSt
                fillwhiteSt={selectab !== 'o'}
                src="/game/img/overview.svg"
              />
              Overview
            </S.TabTitle>
            <S.TabTitle
              active={selectab === 'c'}
              onClick={(e) => {
                setSelecttab('c');
                localStorage.setItem('overviewTab', 'c');
              }}
            >
              <Image
                height="24px"
                width="auto"
                marginBottomSt
                fillwhiteSt={selectab !== 'c'}
                src="/game/img/cartera.svg"
              />
              Cartera
            </S.TabTitle>
            {!isFinished && <S.TabTitle
              className="tab-mercado"
              active={selectab === 'm'}
              onClick={(e) => {
                setSelecttab('m');
                localStorage.setItem('overviewTab', 'm');
              }}
            >
              <Image
                height="24px"
                width="auto"
                marginBottomSt
                fillwhiteSt={selectab !== 'm'}
                src="/game/img/mercado.svg"
              />
              Mercado
            </S.TabTitle>}
            <S.TabTitle
              active={selectab === 'r'}
              onClick={(e) => {
                setSelecttab('r');
                localStorage.setItem('overviewTab', 'r');
              }}
            >
              <Image
                height="24px"
                width="auto"
                marginBottomSt
                fillwhiteSt={selectab !== 'r'}
                src="/game/img/ranking.svg"
              />
              Ranking
            </S.TabTitle>
          </S.Tab>
          <S.Caja active={selectab === 'o'} style={{ flexGrow: '1' }}>
            <div style={{ width: '100%' }}>
              <H2 style={{ color: 'white', width: '100%', textAlign: 'center' }}>
                {tournament.name || 'Torneo sin nombre'}
              </H2>
            </div>
            <S.Cajatransparente row style={{ marginTop: '8px' }}>
              <S.CajaDere>
                <S.Column>
                  <Psmall nowrap marginBottom="5px" color="#01A8FA" width10>
                    Mi Cartera
                  </Psmall>
                  <H1
                    color={myWallet?.shouldColor ? (TournamentUtils.signedColor(
                      myWallet?.remainingValue -
                      (myWallet?.prevRemainingValue || remainingValue),
                      false
                    )) : 'white'}
                    width10
                  >
                    {TournamentUtils.formatCurrency(remainingValue, 2, true, market?.currency)}
                  </H1>
                </S.Column>
              </S.CajaDere>
              <S.CajaIzq>
                <Psmall
                  nowrap
                  big
                  marginBottom="5px"
                  align="right"
                  color={TournamentUtils.signedColor(latentProfitability)}
                >
                  {latentProfitability > 0 ? <S.TopBig src="/game/img/topbig.svg" /> : null}
                  <S.Span color={TournamentUtils.signedColor(latentProfitability)} paddingright>
                    {TournamentUtils.formatDecimals((latentProfitability || 0.0) * 100, 1)}%
                  </S.Span>
                </Psmall>
                <Psmall nowrap big marginBottom="5px" align="right" color="#01A8FA">
                  <S.TopBig src="/game/img/money.svg" />
                  <S.Span color="#01A8FA" paddingright>
                    {TournamentUtils.formatCurrency(myWallet?.balance, 2, true, market?.currency)}
                  </S.Span>
                </Psmall>
              </S.CajaIzq>
            </S.Cajatransparente>
            {!isFinished && <LineChart walletId={myWallet?.id} />}
            <H1small marginBottom="5px">RANKING</H1small>
            {topWallets.length ? (
              <CompetitorsList navigateTo={navigateTo} market={market} isMyWallet={false} isTopList wallets={topWallets} />
            ) : null}
            {myWallet ? <CompetitorsList navigateTo={navigateTo} market={market}
              isMyWallet
              isFirst={tournament.myPosition === 0}
              isLast={tournament.myPosition === wallets?.length - 1}
              isTopList={false}
              wallets={[myWallet]}
            /> : null}

            <S.Separator marginBottom="5px">
              {setMore !== 'y' ? <S.Line /> : null}
              <S.SpanSeparator
                noactive={setMore === 'y'}
                onClick={(e) => {
                  setselectMore('y');
                }}
              >
                + VER TODO
              </S.SpanSeparator>
              {setMore !== 'y' ? <S.Line /> : null}
            </S.Separator>
            {setMore === 'y' ? (
              <CompetitorsList navigateTo={navigateTo} market={market} isMyWallet={false} isTopList={false} wallets={bottomWallets} />
            ) : null}
            {tournament.publicWallet && <H1small marginBottom="5px">ÚLTIMOS TRADES</H1small>}
            {tournament.publicWallet && (
              <BuyExecutionsList navigateTo={navigateTo} market={market}
                displayUserInfo
                tournament={tournament}
                buyExecutions={lastBuyExecutions}
              />
            )}

          </S.Caja>
          {loadingBuyExecutions ? (
            <CommonStyles.FullHeightLoadingContainer>
              <ReactLoading type="spinningBubbles" color="#ffffff" height="20%" width="20%" />
            </CommonStyles.FullHeightLoadingContainer>
          ) : (
            <S.Caja active={selectab === 'c'} style={{ flexGrow: '1' }}>
              <div style={{ width: '100%' }}>
                <H2 style={{ color: 'white', width: '100%', textAlign: 'center' }}>
                  {tournament.name || 'Torneo sin nombre'}
                </H2>
              </div>
              <S.Cajatransparente row>
                <S.CajaDere>
                  <S.Column>
                    <Psmall nowrap marginBottom="5px" color="#01A8FA" width10>
                      Mi Cartera
                    </Psmall>
                    <H1
                      color={myWallet?.shouldColor ? (TournamentUtils.signedColor(
                        myWallet?.remainingValue -
                        (myWallet?.prevRemainingValue || remainingValue),
                        false
                      )) : 'white'}
                      width10
                    >
                      {TournamentUtils.formatCurrency(remainingValue, 2, true, market?.currency)}
                    </H1>
                  </S.Column>
                </S.CajaDere>
                <S.CajaIzq>
                  <Psmall
                    nowrap
                    big
                    marginBottom="5px"
                    align="right"
                    color={TournamentUtils.signedColor(latentProfitability)}
                  >
                    {latentProfitability >= 0 ? <S.TopBig src="/game/img/topbig.svg" /> : null}
                    <S.Span color={TournamentUtils.signedColor(latentProfitability)} paddingright>
                      {TournamentUtils.formatDecimals(latentProfitability * 100.0, 2)}%
                    </S.Span>
                  </Psmall>
                  <Psmall nowrap big marginBottom="5px" align="right" color="#01A8FA">
                    <S.TopBig src="/game/img/money.svg" />
                    <S.Span color="#01A8FA" paddingright>
                      {TournamentUtils.formatCurrency(myWallet?.balance, 2, true, market?.currency)}
                    </S.Span>
                  </Psmall>
                </S.CajaIzq>
              </S.Cajatransparente>
              {!isFinished && <LineChart isWallet walletId={myWallet?.id} />}
              {!isFinished && <H1small marginBottom="5px">POSICIONES ABIERTAS</H1small>}
              {!isFinished && <BuyExecutionsList navigateTo={navigateTo} market={market}
                displayUserInfo={false}
                tournament={tournament}
                buyExecutions={remainingBuyExecutions}
              />}
            </S.Caja>
          )}
          <S.Caja active={selectab === 'm'} style={{ flexGrow: '1' }}>
            <H1small blue center>
              SALDO DISPONIBLE
            </H1small>
            <H1>{TournamentUtils.formatCurrency(myWallet?.balance, 2, true, market?.currency)}</H1>
            <TextField
              /*sinmarginSt*/
              cursorSt
              placeholder="Buscar valores..."
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                setSelectTabMercado('todas');
              }}
              startAdornment={<Image height="24px" width="auto" src={SearchIcon} />}
            />
            {/*
            <S.Flex>
              <H1small
                marginBottom="30px"
                marginRight
                blue={selecbuscador === 't'}
                onClick={(e) => {
                  setSelectbuscador('t');
                  console.log('t');
                }}
              >
                TODOS
              </H1small>
              <H1small
                marginBottom="30px"
                marginRight
                blue={selecbuscador === 'i'}
                onClick={(e) => {
                  setSelectbuscador('i');
                  console.log('i');
                }}
              >
                IBEX35
              </H1small>
              <H1small
                marginBottom="30px"
                marginRight
                blue={selecbuscador === 'n'}
                onClick={(e) => {
                  setSelectbuscador('n');
                  console.log('n');
                }}
              >
                NASDAQ
              </H1small>
              <H1small
                marginBottom="30px"
                blue={selecbuscador === 'd'}
                onClick={(e) => {
                  setSelectbuscador('d');
                  console.log('d');
                }}
              >
                DOWJONES
              </H1small>
            </S.Flex>
            */}
            <S.Tab>
              <S.TabTitleMercado
                active={selectTabMercado === 'recientes'}
                onClick={(e) => {
                  setSelectTabMercado('recientes');
                }}
              >
                Recientes
              </S.TabTitleMercado>
              <S.TabTitleMercado
                active={selectTabMercado === 'populares'}
                onClick={(e) => {
                  setSelectTabMercado('populares');
                }}
              >
                Acciones populares
              </S.TabTitleMercado>
              <S.TabTitleMercado
                active={selectTabMercado === 'todas'}
                onClick={(e) => {
                  setSelectTabMercado('todas');
                }}
              >
                Todas las acciones
              </S.TabTitleMercado>
            </S.Tab>
            <InstrumentsList navigateTo={navigateTo} market={market}
              tabName="recientes"
              tournament={tournament}
              instruments={filterInstruments(lastBoughtInstruments).slice(0, 5)}
              active={selectTabMercado === 'recientes'}
            />
            <InstrumentsList navigateTo={navigateTo} market={market}
              tabName="populares"
              tournament={tournament}
              instruments={filterInstruments(lastBoughtInstruments).slice(0, 5)}
              active={selectTabMercado === 'populares'}
            />
            {loadingAllInstruments && (
              <CommonStyles.InlineLoadingContainer>
                <ReactLoading type="spinningBubbles" color="#ffffff" height="20%" width="20%" />
              </CommonStyles.InlineLoadingContainer>
            )}
            <InstrumentsList navigateTo={navigateTo} market={market}
              tabName="todas"
              tournament={tournament}
              instruments={filterInstruments(allInstruments).slice(0, 100)}
              active={selectTabMercado === 'todas'}
            />
            {allInstruments.length > 100 && searchText.trim().length < 2 ? (
              <p
                style={{
                  marginTop: '5px',
                  width: '100%',
                  textAlign: 'center',
                  color: 'white',
                  fontSize: '10px',
                }}
              >
                Se muestran 100 acciones, usa la búsqueda para filtrar los demás resultados
              </p>
            ) : null}
          </S.Caja>
          <S.Caja active={selectab === 'r'} style={{ flexGrow: '1' }}>
            {topWallets.length ? (
              <CompetitorsList navigateTo={navigateTo} market={market} isMyWallet={false} isTopList wallets={aboveWallets} />
            ) : null}
            {myWallet ? <CompetitorsList navigateTo={navigateTo} market={market}
              isMyWallet
              isTopList={false}
              isFirst={tournament.myPosition === 0}
              isLast={tournament.myPosition === wallets?.length - 1}
              wallets={[myWallet]}
            /> : null}
            <CompetitorsList navigateTo={navigateTo} market={market} isMyWallet={false} isTopList={false} wallets={belowWallets} />

            {!tournament.public && tournament.shareCode && (
              <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 'auto' }}>
                <H2 bold>
                  Código del torneo
                </H2>
                <S.Password onClick={copyTextToClipboard}>
                  <H2 bold>
                    {tournament.shareCode}
                    <ShareIcon htmlColor="#fff" style={{ marginLeft: 10, fontSize: '15px' }} />
                  </H2>
                </S.Password>
                {isCopied && (
                  <Pbig marginBottom="10px" color="white" align="center">
                    ¡Enlace copiado!
                  </Pbig>
                )}
              </div>
            )}
          </S.Caja>

          <BasesModalOpener openModalFn={() => setOpenBasesModal(true)} style={{ marginTop: '5px', marginBottom: '10px' }} />
          <BasesModal isOpenModal={openBasesModal} closeModalFn={() => setOpenBasesModal(false)} />

        </MarginWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

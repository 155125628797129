import * as S from './styles';

export const TransparentButton = ({
  children,
  className,
  onClick,
  full,
  disabled,
  marginTop,
  fullWidth,
}) => (
  <S.Button
    className={className + ' transparentbutton'}
    onClick={onClick}
    full={full}
    disabled={disabled}
    marginTop={marginTop}
    fullWidth={fullWidth}
  >
    {children}
  </S.Button>
);

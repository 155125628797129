import styled from 'styled-components/macro';
import { space, layout } from 'styled-system';

export const H6 = styled.h6`
  color: white;
  font-family: Eurostile;
  font-size: 5.7vw;
  font-weight: 400;
  line-height: 25px;
  text-transform: uppercase;
  ${space};
  ${layout};
`;

import { MainButton } from 'components/MainButton/MainButton';
import { H3 } from 'components/H3/H3';
import { P } from 'components/P/P';
import { Header } from 'components/Header/header';

import { MarginWrapper } from 'layout/MarginWrapper/MarginWrapper';
import { NavigationWrapper, Input, UiWrapper } from 'coinscrap-webapp-core-ts';
import { routes } from 'routes';
import { useEffect, useState } from 'react';
import * as S from './styles';
import {TournamentUtils, useTemporaryTournament} from '../../../utils/TournamentUtils';
import { Api } from '../../../api/api';
import { Psmall } from '../../../components/Psmall/Psmall';

export const InitialBalance = () => {
  const { navigateTo } = NavigationWrapper.use();
  const { useHeader } = UiWrapper.use();

  const [tmpTournament, setTmpTournament] = useTemporaryTournament();

  useHeader(<Header closeRoute={routes.tournamentHomeMain} />);

  function updateTmpTournamentBalance(e) {
    let parsed = parseFloat(e.target.value.replace(',', '.'));
    if (Number.isNaN(parsed)) {
      parsed = tmpTournament.initialBalance;
    }
    // const newInitialBalance = Math.min(1e6, Math.max(100, parsed));
    const newInitialBalance = parsed;
    setTmpTournament({
      initialBalance: newInitialBalance,
    });
  }

  return (
    <S.Wrapper>
      <S.Content>
        <MarginWrapper justifyContent="space-between">
          <S.Caja>
            <H3>Indica con cuanto dinero empiezan los participantes</H3>
            <Input
              type="number"
              placeholder="Ej: 1000€"
              value={tmpTournament.initialBalance}
              onChange={(e) => updateTmpTournamentBalance(e)}
              style={{
                cursor: 'pointer',
                fontWeight: '600',
                color: tmpTournament.initialBalance >= 100 && tmpTournament.initialBalance <= 1e6 ? '#FFFFFF' : '#cb5767'
              }}
            />
            <P color="white">
              Indica con cuanto dinero va a empezar cada jugador que invites o se una al torneo.
            </P>
            <P fontSize="10px" color="white" marginTop="12px">Debe estar entre 100 y 1.000.000 €</P>
          </S.Caja>
          <MainButton
            pink
            full
            fullWidth
            disabled={!(tmpTournament.initialBalance >= 100 && tmpTournament.initialBalance <= 1e6)}
            marginTop="10px"
            onClick={() => {
              if (tmpTournament.initialBalance >= 100 && tmpTournament.initialBalance <= 1e6) {
                navigateTo(routes.tournamentCreateFees);
              }
            }}
          >
            SIGUIENTE
          </MainButton>
        </MarginWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

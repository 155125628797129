import React, {useEffect, useState} from 'react';
import {NavigationWrapper, Page} from 'coinscrap-webapp-core-ts';
import * as S from './styles';
import { CommonUtils } from '../../utils/CommonUtils';

export const Header = ({
  canGoBack = true,
  canClose = true,
  backRoute,
  closeRoute,
  children,
  className,
  ...props
}) => {
  const { navigateTo, goBack, location } = NavigationWrapper.use();
  const { params, query } = Page.use();

  const [effectiveCanGoBack, setEffectiveCanGoBack] = useState(canGoBack);
  const [showHeader, setShowHeader] = useState(false);

  useEffect(() => {
      const fullScreen = new URLSearchParams(location.search).get('full_screen') || (navigator.platform === 'Win32' && 'true') || 'false';
      if (fullScreen === 'true') {
          setShowHeader(true)
      } else {
          setShowHeader(false)
      }
  }, [location.search])

  useEffect(() => {
    setEffectiveCanGoBack(location.key && location.key !== 'default' && canGoBack || (!location.key && canGoBack));
  }, [location.key])


    return showHeader ? <S.Header {...props}>
        <S.Img
            src="/game/img/right.svg"
            style={{cursor: 'pointer', opacity: effectiveCanGoBack ? 1 : 0.0, padding: 8, height: 32, width: 'auto'}}
            onClick={() => {
                if (effectiveCanGoBack) {
                    goBack();
                }
            }}
        />
        <S.Img src="/game/img/senseizero.svg"/>
        <S.Img
            src="/game/img/cross.svg"
            style={{cursor: 'pointer', opacity: canClose ? 1 : 0.0, padding: 8, height: 32, width: 'auto'}}
            onClick={() => {
                if (!canClose) {
                    return;
                }
                if (CommonUtils.pushToNative) {
                    CommonUtils.pushToNative({
                        body: {action: 'close', params: {}},
                    });
                } else if (closeRoute) {
                    navigateTo(closeRoute, {
                        clearItems: ['ticker', 'marketName', 'id', 'instrumentId', 'tournamentId'],
                    });
                }
            }}
        />
    </S.Header> : null;
};

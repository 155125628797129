import { BackendWrapper } from 'coinscrap-webapp-core-ts';
import delay from 'delay';
import _ from 'lodash';
import { CommonUtils } from '../utils/CommonUtils';
import { MemoryState } from '../utils/MemoryState';

const SocketState = () => { };

SocketState.use = () => { };

export const DATE_REGEX = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2})(?::\d{2})?/;
const INSTRUMENT_CACHE_FIELDS = ['id', 'name', 'displayName', 'ticker', 'isin', 'marketId', 'last', 'var', 'vol', 'lastSeen', 'tickerId', 'tickerKey', 'market'];
let _removingRandoms = false;
const _requestingMarketMap = {};
const _scheduledMarketRefresh = {};

export class Api {
  static get instance() {
    if (!this._i) {
      this._i = new Api();
    }
    return this._i;
  }

  set token(value) {
    this._token = value;
    this._instrumentsByKey = this._instrumentsByKey || {};
    this._instrumentsById = this._instrumentsById || {};
    this._marketsByName = this._marketsByName || {};

    if (!_removingRandoms) {
      _removingRandoms = true;
      setInterval(() => {
        const randomN = Math.floor(Math.random() * localStorage.length);
        const key = localStorage.key(randomN);
        if (key && /instrumentBy|marketBy/gi.test(key)) {
          try {
            const cached = JSON.parse(localStorage.getItem(key));
            if (!cached.dt || cached.dt.falsePositive || cached.dt.keywords || /instrumentBy/gi.test(key) && !(cached.dt.market) || cached.exp < Date.now() / 1000) {
              console.log('removing random expired cache', key, cached.exp);
              localStorage.removeItem(key);
            } else {
              // console.log('keeping random cache', key, cached.exp);
            }
          } catch (e) {
            console.error(e);
          }
        }
      }, 3000);
    }
  }

  // Risky involve a user value in a this low level
  set user(value) {
    this._user = value;
  }

  set baseUrl(value) {
    this._baseUrl = ((value || '').trim() + '//').replace('///', '');
  }

  async createRandomUser(username, email, password) {
    return this._call('POST', `/api/users`, {
      username,
      email,
      password
    });
  }

  async login(email, password) {
    return this._call('POST', `/api/users/login`, {
      email,
      password
    });
  }

  async getCurrentUser() {
    return this._call('GET', `/api/users/me`);
  }

  // TODO: actualizar avatar, antes estaba en getCurrentUser
  async updateUserPhoto(avatar) {
    let user = await this._call('GET', `/api/users/me`);
    user = await this._call('PATCH', `/api/users/${user.id}`, {
      photo: avatar,
    });
    return user;
  }

  async findOrCreateTmpTournament() {
    if (this._findingTournament || !this._user) {
      return;
    }
    this._findingTournament = true;
    let current = await this._call('GET', `/api/tournaments`, {
      filter: {
        where: { status: 'CREATING', userId: this._user },
      },
    });
    if (!current || !current[0]) {
      current = await this._call('POST', `/api/tournaments`, {
        status: 'CREATING',
        userId: this._user,
        created: new Date(),
      });
      CommonUtils.normalizeTournament(current);
      console.log('created temporary', current);
      localStorage.setItem('new_tournament_password', current.shareCode);
    } else {
      // eslint-disable-next-line prefer-destructuring
      current = current[0];
      CommonUtils.normalizeTournament(current);
      console.log('found existing', current);
    }

    this._findingTournament = false;

    if (current && current.start) {
      current.start = new Date(current.start);
    }

    if (current && current.start) {
      current.end = new Date(current.end);
    }

    return current;
  }

  async updateTmpTournament(tmpTournament) {
    const updatedTournament = await this._call(
      'PATCH',
      `/api/tournaments/${tmpTournament.id}`,
      tmpTournament
    );
    console.log('updated temporary', updatedTournament);
    return updatedTournament;
  }

  async deleteTmpTournament(tmpTournament) {
    await this._call(
      'DELETE',
      `/api/tournaments/${tmpTournament.id}`
    );
    MemoryState.tmpTournament = null;
  }

  async findTournamentsForMyWallets() {
    const myWallets = (
      await this._call('GET', `/api/wallets`, {
        filter: {
          where: { active: true, userId: this._user },
          order: ['created ASC', 'balance DESC'],
          include: [
            { relation: 'user' },
            {
              relation: 'tournament',
              scope: {
                where: {
                  // start: { $lte: new Date() },
                  // end: { $gt: new Date() },
                  status: 'CREATED',
                },
                include: [
                  { relation: 'user' },
                  {
                    relation: 'wallets',
                    scope: {
                      order: ['balance DESC'],
                      include: [{ relation: 'user' }, { relation: 'buyOrders', scope: { where: { status: { $nin: ['REJECTED', 'PARTIALLY_REJECTED'] } } } }],
                    },
                  },
                ],
              },
            },
            { relation: 'buyOrders', scope: { where: { status: { $nin: ['REJECTED', 'PARTIALLY_REJECTED'] } } } },
          ],
        },
      })
    ).map((wallet) => ({ ...wallet, remainingValue: wallet.remainingValue || 0.0 }));
    const myTournaments = [];
    for (let i = myWallets.length - 1; i >= 0; i--) {
      const myWallet = myWallets[i];
      if (!myWallet.tournament || !myWallet.tournament.marketName) {
        myWallets.splice(i, 1);
      }
    }
    let randomIdxToCompute;
    if (myWallets.length) {
      randomIdxToCompute = Math.floor(Math.random() * myWallets.length);
    }
    for (let i = 0; i < myWallets.length; i++) {
      const myWallet = myWallets[i];
      // let myWallet;
      if (myWallet.tournament) {

        if (!myWallet.tournament.market) {
          myWallet.tournament.market = await this.findMarketByNameWithInstruments(myWallet.tournament.marketName);
        }

        CommonUtils.normalizeTournament(myWallet.tournament);
        // const allWallets = await this.findAllWalletsInTournament(myWallet.tournament.id);
        const allWallets = myWallet.tournament.wallets.map((otherWallet) => {
          otherWallet.tournament = { ...myWallet.tournament, wallets: [] };
          return otherWallet;
        })
        if (allWallets.length && i === randomIdxToCompute) {
          const nestedRandomIdxToCompute = Math.floor(Math.random() * allWallets.length);
          const walletToCompute = allWallets[nestedRandomIdxToCompute];
          this.computeWalletsRemainingValue([walletToCompute]).catch(console.error);
        }
        CommonUtils.sortBy(allWallets, [it => it.hasPositions ? 0 : 1, 1], [it => it.balance + it.remainingValue, -1]);
        let myPosition = 0;
        let myFullWallet;
        for (let j = 0; j < allWallets.length; j++) {
          const otherWallet = allWallets[j];

          otherWallet.profitability =
            otherWallet.profitability ||
            Math.round(
              ((otherWallet.balance + otherWallet.remainingValue) /
                (otherWallet.tournament?.initialBalance || 100.0) -
                1.0) *
              1000.0
            ) / 1000.0;

          if (otherWallet.userId === this._user) {
            myFullWallet = otherWallet;
            myPosition = j;
            // break;
          }
        }
        let fullTournament;
        if (!myWallet.tournament || !myWallet.tournament.user) {
          fullTournament = await this.findTournamentById(myWallet.tournamentId);
        } else {
          fullTournament = myWallet.tournament;
        }
        // if (myPosition >= 0) {
        myTournaments.push({
          ...myWallet.tournament,
          user: { ...fullTournament.user, photo: fullTournament.user.photo || '/game/img/usuario2.svg' },
          wallets: allWallets,
          myWallet: myFullWallet,
          myPosition,
        });
      }
      // }
    }

    CommonUtils.sortBy(
      myTournaments,
      [it => Math.round(it.end.getTime() / 1000 / 86400) * 86400, 1],
      [it => (it.myWallet?.balance || 0) + (it.myWallet?.remainingValue || 0), -1]
    )
    return myTournaments;
  }

  async findEndTournaments() {
    const myWallets = (
      await this._call('GET', `/api/wallets`, {
        filter: {
          where: { active: false, userId: this._user },
          order: ['created ASC', 'balance DESC'],
          include: [
            { relation: 'user' },
            {
              relation: 'tournament',
              scope: {
                where: {
                  // end: { $gt: new Date() },
                  status: 'FINISHED',
                },
                include: [
                  { relation: 'user' },
                  {
                    relation: 'wallets',
                    scope: {
                      order: ['balance DESC'],
                      include: [{ relation: 'user' }, { relation: 'buyOrders', scope: { where: { status: { $nin: ['REJECTED', 'PARTIALLY_REJECTED'] } } } }],
                    },
                  },
                ],
              },
            },
            { relation: 'buyOrders', scope: { where: { status: { $nin: ['REJECTED', 'PARTIALLY_REJECTED'] } } } },
          ],
        },
      })
    ).map((wallet) => ({ ...wallet, remainingValue: wallet.remainingValue || 0.0 }));
    const myTournaments = [];
    for (let i = myWallets.length - 1; i >= 0; i--) {
      const myWallet = myWallets[i];
      if (!myWallet.tournament || !myWallet.tournament.marketName) {
        myWallets.splice(i, 1);
      }
    }
    for (let i = 0; i < myWallets.length; i++) {
      const myWallet = myWallets[i];
      // let myWallet;
      if (myWallet.tournament) {

        if (!myWallet.tournament.market) {
          myWallet.tournament.market = await this.findMarketByNameWithInstruments(myWallet.tournament.marketName);
        }

        CommonUtils.normalizeTournament(myWallet.tournament);
        // const allWallets = await this.findAllWalletsInTournament(myWallet.tournament.id);
        const allWallets = myWallet.tournament.wallets.map((otherWallet) => {
            otherWallet.tournament = { ...myWallet.tournament, wallets: [] };
            return otherWallet;
          })
        CommonUtils.sortBy(allWallets, [it => it.hasPositions ? 0 : 1, 1], [it => it.balance, -1]);
        let myPosition = 0;
        let myFullWallet;
        for (let j = 0; j < allWallets.length; j++) {
          const otherWallet = allWallets[j];

          otherWallet.profitability =
            otherWallet.profitability ||
            Math.round(
              ((otherWallet.balance) /
                (otherWallet.tournament?.initialBalance || 100.0) -
                1.0) *
              1000.0
            ) / 1000.0;

          if (otherWallet.userId === this._user) {
            myFullWallet = otherWallet;
            myPosition = j;
            // break;
          }
        }
        let fullTournament;
        if (!myWallet.tournament || !myWallet.tournament.user) {
          fullTournament = await this.findTournamentById(myWallet.tournamentId);
        } else {
          fullTournament = myWallet.tournament;
        }
        // if (myPosition >= 0) {
        myTournaments.push({
          ...myWallet.tournament,
          user: { ...fullTournament.user, photo: fullTournament.user.photo || '/game/img/usuario2.svg' },
          wallets: allWallets,
          myWallet: myFullWallet,
          myPosition,
        });
      }
      // }
    }

    CommonUtils.sortBy(
        myTournaments,
        [it => it.end.getFullYear(), -1],
        [it => it.end.getMonth(), -1],
        [it => it.end.getDate(), -1],
        [it => (it.myWallet?.balance || 0), -1]
    )
    return myTournaments;
  }

  async findMyCreatedTournaments() {
    return this._call('GET', `/api/tournaments`, {
      filter: {
        where: {
          userId: this._user,
          status: { $nin: ['CREATING'] },
        },
      },
    });
  }

  async findMyExecutedBuyOrders() {
    return this._call('GET', `/api/buy-orders`, {
      filter: {
        where: {
          userId: this._user,
          totalBuyValue: { $gt: 0 },
        },
      },
    });
  }

  async findAvailableTournaments() {
    const availableTournaments = await this._call('GET', `/api/tournaments`, {
      filter: {
        where: {
          status: 'CREATED',
          // start: {
          //   $gt: new Date(Date.now() - 48 * 3600 * 1000),
          // },
          // end: { $gt: new Date() },
          // $or: [ { limitJoinDate: {$exists: false} }, { limitJoinDate: null }, { limitJoinDate: { $gt: new Date() } } ]
          limitJoinDate: { $gt: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()) },
        },
        include: [
          { relation: 'user' },
          {
            relation: 'wallets',
            scope: { include: [{ relation: 'user' }, { relation: 'tournament' }] },
          },
        ],
        order: ['limitJoinDate ASC'],
      },
    });
    const resultTournaments = [];
    for (let i = availableTournaments.length - 1; i >= 0; i--) {
      const resultTournament = availableTournaments[i];
      for (let j = 0; j < resultTournament.wallets.length; j++) {
        const wallet = resultTournament.wallets[j];
        if (wallet.userId === this._user) {
          availableTournaments.splice(i, 1);
          break;
        }
      }
    }
    for (let i = 0; i < availableTournaments.length; i++) {
      const tournament = availableTournaments[i];
      CommonUtils.normalizeTournament(tournament);
      // const allWallets = await this.findAllWalletsInTournament(tournament.id);
      const allWallets = tournament.wallets.map((it) => ({
        ...it,
        remainingValue: it.remainingValue || 0.0,
      }));
      CommonUtils.sortBy(allWallets, [it => it.hasPositions ? 0 : 1, 1], ['balance', -1]);
      // if (allWallets && allWallets.length) {
      resultTournaments.push({
        ...tournament,
        wallets: allWallets,
      });
      // }
    }
    return resultTournaments;
  }

  async findLastBuyExecutions(limit = 5) {
    const resultExecutions = [];
    let buyExecutionsBatch;
    let skip = 0;
    do {
      buyExecutionsBatch = await this._call('GET', `/api/buy-executions`, {
        filter: {
          // TODO: Order by executionDate DESC and limit 3
          // where: { status: { $nin: ['CREATING'] }, userId: { $ne: this._user } },
          include: [
            {
              relation: 'buyOrder',
              scope: {
                include: [
                  { relation: 'user' },
                  { relation: 'buyExecutions' },
                  {
                    relation: 'wallet',
                    scope: {
                      include: [
                        { relation: 'tournament', scope: { where: { publicWallet: true } } },
                      ],
                    },
                  },
                ],
              },
            },
          ],
          order: ['executionDate DESC'],
          // TOyDO: Implement in westack
          skip,
          limit,
        },
      });
      const filtered = buyExecutionsBatch
        .filter(
          (it) =>
            it.buyOrder &&
            it.buyOrder.wallet &&
            it.buyOrder.wallet.tournament &&
            it.buyOrder.wallet.tournament.publicWallet
        )
        .sort((a, b) =>
          // eslint-disable-next-line no-nested-ternary
          a.executionDate < b.executionDate ? 1 : a.executionDate > b.executionDate ? -1 : 0
        );

      const instrumentRequests = filtered.map((buyExecution) =>
        (async () => {
          const { buyOrder } = buyExecution;
          if (buyOrder) {
            buyOrder.instrument = await this.findInstrumentByTickerAndMarket(
              buyOrder.ticker,
              buyOrder.market
            );
          }
        })()
      );

      await Promise.all(instrumentRequests);

      for (let i = 0; i < filtered.length && resultExecutions.length < limit; i++) {
        const buyExecution = filtered[i];
        const { buyOrder } = buyExecution;
        if (buyOrder) {
          if (buyOrder.instrument) {
            let newName = buyOrder.instrument.name;
            if (newName.length > 18) {
              newName = buyOrder.instrument.name.substring(0, 18).trim() + '...';
            }
            buyOrder.instrument = {
              ...buyOrder.instrument,
              displayName: newName,
            };
          }

          console.log('fullBuyOrder', buyOrder);
          if (buyOrder.user && buyOrder.instrument) {
            resultExecutions.push({
              ...buyExecution,
              buyOrder,
            });
          }
        }
      }
      skip += limit;
    } while (buyExecutionsBatch.length && resultExecutions.length < limit);
    return resultExecutions;
  }

  async findInstrumentByTickerAndMarket(ticker, marketName, skipCache = false) {
    let cachedInstrument;
    if (!skipCache) {
      ({ cachedInstrument } = this.getCachedInstrumentByTickerAndMarket(marketName, ticker));
      if (cachedInstrument) {
        return cachedInstrument;
      }
    }

    const market = await this.findMarketByNameWithInstruments(marketName, skipCache);
    const instrument = (
      await this._call('GET', `/api/instruments`, {
        filter: {
          where: { ticker, marketId: market.id },
          include: [{ relation: 'market' }],
        },
      })
    )[0];
    this.saveInstrumentToCache(marketName, instrument);
    return instrument;
  }

  getCachedInstrumentByTickerAndMarket(marketName, ticker) {
    const instrumentKey = `${marketName.replaceAll(/\W+/gi, '_').toUpperCase()}__${ticker}`;
    let cachedInstrument = this._instrumentsByKey[instrumentKey];

    if (cachedInstrument) {
      return { instrumentKey, cachedInstrument };
    }

    let key = `instrumentByKey${instrumentKey}`;
    const saved = localStorage.getItem(key);
    if (saved) {
      try {
        cachedInstrument = JSON.parse(saved);
        if (!cachedInstrument || !cachedInstrument.dt || !cachedInstrument.dt.market || !cachedInstrument.exp || cachedInstrument.exp < Date.now() / 1000) {
            console.log('W: instrument expired', instrumentKey);
            cachedInstrument = null;
            localStorage.removeItem(key);
        } else {
            cachedInstrument = cachedInstrument.dt;
            this._loadInstrumentToCache(instrumentKey, cachedInstrument);
            return { instrumentKey, cachedInstrument };
        }
        // eslint-disable-next-line no-empty
      } catch (e) {
        console.error(e);
      }
    }

    return { instrumentKey, cachedInstrument };
  }

  /**
   * @param {string} method
   * @param {string} url
   * @param {object} [data]
   * @param options
   */
  async _call(method, url, data, options = { baseUrl: this._baseUrl, skipCache: false }) {
    if (typeof options === 'undefined' && typeof data === 'object' && ['get', 'head', 'options'].includes(method.toLowerCase())) {
      // eslint-disable-next-line no-param-reassign
      options = data;
    }
    options.baseUrl = options.baseUrl || this._baseUrl;
    const coreFetch = BackendWrapper.getCoreFetch();

    const betterMethod = method.toLowerCase().replace(' ', '').trim();
    let headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };
    if (options?.skipCache) {
      headers['X-Skip-Cache'] = 'true';
    }
    if (this._token && options.baseUrl === this._baseUrl) {
      headers.Authorization = `Bearer ${this._token}`;
    }
    headers = {
      ...headers,
      ...(options.headers || {}),
    };

    try {
      const { data: response } = await coreFetch(
        betterMethod,
        options.baseUrl + url,
        {
          headers,
        },
        { [betterMethod === 'get' ? 'queryParams' : 'bodyParams']: data }
      );
      return response;
    } catch (error) {
      console.error('API error', error);
    }
  }

  async findMyWalletInTournament(tournamentId) {
    const wallet = (
      await this._call('GET', `/api/wallets`, {
        filter: {
          where: { userId: this._user, tournamentId },
          include: [{ relation: 'tournament' }]
        },
      })
    )[0];
    if (wallet && wallet.tournament) {
      wallet.tournament.market = await this.findMarketByNameWithInstruments(wallet.tournament.marketName);
    }
    return wallet;
  }

  /**
   *
   * @param orderType "buy" or "sale"
   * @param id
   * @returns {Promise<*>}
   */
  async findOrderById(orderType, id) {
    const buyOrder = await this._call('GET', `/api/${orderType}-orders/${id}`, {
      filter: {
        // TODO: Solve instrument inclusion. Not available with different datasource due to $lookups
        include: [{ relation: 'user' }, { relation: 'buyExecutions' }],
      },
    });
    // for (let i = 0; i < buyOrders.length; i++) {
    //   const buyOrder = buyOrders[i];
    // eslint-disable-next-line prefer-destructuring

    // }
    return buyOrder;
  }

  async findTournamentById(id, skipComputing = false, syncComputing = false, skipCache = false) {
    const tournament = await this._call('GET', `/api/tournaments/${id}`, {
      filter: {
        // TODO: Solve instrument inclusion. Not available with different datasource due to $lookups
        include: [
          { relation: 'user' },
          {
            relation: 'wallets',
            scope: {
              include: [
                { relation: 'user' },
                { relation: 'tournament' },
                {
                  relation: 'buyOrders',
                  scope: {
                    where: { status: { $nin: ['REJECTED', 'PARTIALLY_REJECTED'] } },
                    include: [/*{ relation: 'user' },*/ { relation: 'instrument' }, { relation: `buyExecutions` }]
                  },
                },
                {
                  relation: 'saleOrders',
                  scope: { include: [/*{ relation: 'user' },*/ { relation: 'instrument' }, { relation: `saleExecutions` }] },
                },
              ],
            },
          },
        ],
      },
    }, { skipCache });
    if (tournament) {
      CommonUtils.normalizeTournament(tournament);
    } else {
      return tournament;
    }
    tournament.myPosition = 0;
    tournament.market = await this.findMarketByNameWithInstruments(tournament.marketName);
    for (let i = tournament.wallets.length - 1; i >= 0; i--) {
      const wallet = tournament.wallets[i];

      if (!wallet.user) {
        tournament.wallets.splice(i, 1);
        // eslint-disable-next-line no-continue
        continue;
      }

      wallet.profitability =
        Math.round(
          (wallet.profitability ||
            (wallet.balance + wallet.remainingValue - tournament.initialBalance) /
            tournament.initialBalance) * 1000.0
        ) / 1000.0;
    }

    CommonUtils.sortBy(tournament.wallets, [it => it.hasPositions ? 0 : 1, 1], [(wallet) => wallet.balance + wallet.remainingValue, -1]);

    tournament.lastWalletWithPositions = 0;
    let randomIdxToCompute = -1;
    if (tournament.wallets.length > 0) {
      randomIdxToCompute = Math.floor(Math.random() * tournament.wallets.length);
    }
    for (let i = 0; i < tournament.wallets.length; i++) {
      const wallet = tournament.wallets[i];

      wallet.position = i;
      wallet.tournament = { ...tournament, wallets: [] };
      if (wallet.userId === this._user) {
        tournament.myWallet = wallet;
        tournament.myPosition = i;
        if (!skipComputing) {
          console.log('computing my wallet', JSON.parse(JSON.stringify(tournament.myWallet)));
          const response = this.computeWalletsRemainingValue([{...tournament.myWallet, tournament: {...(tournament.myWallet.tournament || {}), initialBalance: tournament.initialBalance}}]).catch(console.error);
          if (syncComputing) {
            const computed = await response.catch(e => {
              console.error(e);
              return [];
            });
            console.log('sync computed', computed);
            if (computed && computed[0] && computed[0].remainingValue !== undefined) {
              tournament.myWallet.remainingValue = computed[0].remainingValue;
              tournament.myWallet.currentProfit = computed[0].currentProfit;
              tournament.myWallet.currentProfitability = computed[0].currentProfitability;
            }
          }
        }
      } else if (!skipComputing && i === randomIdxToCompute) {
        this.computeWalletsRemainingValue([{...wallet, tournament: {...(wallet.tournament || {}), initialBalance: tournament.initialBalance}}]).catch(console.error);
      }

      if (wallet.hasPositions) {
        tournament.lastWalletWithPositions = i;
      }
    }
    return tournament;
  }

  /**
   *
   * @param wallets
   * @return {Promise<unknown[]>} newRemainingValues
   */
  async computeWalletsRemainingValue(wallets) {
    const computed = await this._call('POST', `/api/wallets/compute-remaining-value`, wallets);
    console.log('computed', computed.length, computed[0]);
    return computed;
  }

  async findInstrumentById(id, skipCache = false) {
    if (this._instrumentsById[id] && !skipCache) {
      return this._instrumentsById[id];
    }
    const instrumentCacheKey = `instrumentById${id}`;
    if (!skipCache) {
      const saved = localStorage.getItem(instrumentCacheKey);
      if (saved) {
        try {
          let instrumentFromStorage = JSON.parse(saved);
          if (!instrumentFromStorage.dt || !instrumentFromStorage.dt.market || instrumentFromStorage.exp < Date.now() / 1000) {
            console.log('WARNING: Expired instrument cache', instrumentFromStorage);
            instrumentFromStorage = null;
            localStorage.removeItem(instrumentCacheKey);
          } else {
            instrumentFromStorage = instrumentFromStorage.dt;
            this._instrumentsById[id] = instrumentFromStorage;
            Api.instance.socketHandler.subscribeInstrument(
              instrumentFromStorage.ticker,
              instrumentFromStorage.marketId,
              (updatedInstrument) => {
                instrumentFromStorage.last = updatedInstrument.last;
                instrumentFromStorage.var = updatedInstrument.var;
                instrumentFromStorage.vol = updatedInstrument.vol;
                instrumentFromStorage.lastSeen = updatedInstrument.lastSeen;
                if (instrumentFromStorage.name) {
                  try {
                    localStorage.setItem(instrumentCacheKey, JSON.stringify({
                      dt: _.pick({...instrumentFromStorage, market: instrumentFromStorage.market && {name: instrumentFromStorage.market.name}}, INSTRUMENT_CACHE_FIELDS),
                      exp: Date.now() / 1000 + 3600 * 24 * 7,
                    }));
                  } catch (e) {
                    console.log('WARNING: Failed to save instrument cache', e);
                  }
                }
              }
            );
            return instrumentFromStorage;
          }
          // eslint-disable-next-line no-empty
        } catch (e) { }
      }
    }

    const instrument =
      (await this._call('GET', `/api/instruments/${id}`, {
        filter: {
          // where: { _id: id },
          // TODO: Solve instrument inclusion. Not available with different datasource due to $lookups
          include: [{ relation: 'market' }],
        },
      })) || {};

    if (instrument && instrument.id) {
      Api.instance.socketHandler.subscribeInstrument(
        instrument.ticker,
        instrument.marketId,
        (updatedInstrument) => {
          instrument.last = updatedInstrument.last;
          instrument.var = updatedInstrument.var;
          instrument.vol = updatedInstrument.vol;
          instrument.lastSeen = updatedInstrument.lastSeen;
          if (instrument.name) {
            try {
              localStorage.setItem(instrumentCacheKey, JSON.stringify({
                dt: _.pick({
                  ...instrument,
                  market: instrument.market && {name: instrument.market.name}
                }, INSTRUMENT_CACHE_FIELDS),
                exp: Date.now() / 1000 + 3600 * 24 * 7,
              }));
            } catch (e) {
              console.log('WARNING: Failed to save instrument cache', e);
            }
          }
        }
      );
      this._instrumentsById[instrument.id] = this._instrumentsById[instrument.id] || instrument;
      if (instrument.name) {
        try {
          localStorage.setItem(instrumentCacheKey, JSON.stringify({
            dt: _.pick({...instrument, market: instrument.market && {name: instrument.market.name}}, INSTRUMENT_CACHE_FIELDS),
            exp: Date.now() / 1000 + 3600 * 24 * 7,
          }));
        } catch (e) {
          console.log('WARNING: Failed to save instrument cache', e);
        }
      }
    }
    return instrument;
  }

  async findMarketByNameWithInstruments(marketName, skipCache = false) {
    let cachedMarket;

    if (!skipCache) {
      if (!_scheduledMarketRefresh[marketName]) {
        _scheduledMarketRefresh[marketName] = true;
        setTimeout(() => {
          this.findMarketByNameWithInstruments(marketName, true).then(v => {
            console.log('Updated market', marketName, v && true);
          }).catch(e => {
            console.error(e);
          })
        }, 30000 + Math.random() * 1000);
      }
      cachedMarket = this.getCachedMarket(marketName);
      if (cachedMarket) {
        return cachedMarket;
      }
    }

    while (_requestingMarketMap[marketName]) {
      await delay(128 + Math.random() * 128);
    }

    if (!skipCache) {
      cachedMarket = this.getCachedMarket(marketName);
      if (cachedMarket) {
        return cachedMarket;
      }
    }

    _requestingMarketMap[marketName] = true;
    const market = (
      await this._call('GET', `/api/markets`, {
        filter: {
          where: { name: marketName },
          include: [
            {
              relation: 'instruments',
              scope: { where: { ticker: { $gt: '' }, name: { $gt: '' }, enabled: true } },
            },
          ],
        },
      }).catch((e) => {
        console.error(e);
        delete _requestingMarketMap[marketName];
        return { error: e };
      })
    )[0];
    if (market && market.id) {
      for (let i = market.instruments.length - 1; i >= 0; i--) {
        const instrument = market.instruments[i];
        if (instrument.ticker === 'INDI') {
          market.instruments.splice(i, 1);
          break;
        }
      }
      for (let i = 0; i < market.instruments.length; i++) {
        const instrument = market.instruments[i];

        let newName = instrument.name;
        if (newName.length > 18) {
          newName = instrument.name.substring(0, 18).trim() + '...';
        }
        instrument.market = { ...market, instruments: [] };
        market.instruments[i] = {
          ...instrument,
          displayName: newName,
        };
        this.saveInstrumentToCache(marketName, instrument, false);
      }
      // eslint-disable-next-line no-nested-ternary
      CommonUtils.sortBy(market.instruments, ['ticker', 1]);

      this._marketsByName[marketName] = this._marketsByName[marketName] || market;
      try {
        localStorage.setItem(`marketByNameWithInstruments${marketName}`, JSON.stringify({
          dt: {
            ...(_.pick(market, ['id', 'name', 'currency'])),
            instruments: market.instruments.map(it => {
              const toSave = _.pick(it, INSTRUMENT_CACHE_FIELDS)
              if (toSave.market) {
                toSave.market = { name: toSave.market.name }
              }
              return toSave
            })
          },
          exp: Date.now() / 1000 + 3600 * 24 * 7,
        }));
      } catch (e) {
        console.log('WARNING: Failed to save market cache', e);
      }
    }
    delete _requestingMarketMap[marketName];
    return market;
  }

  getCachedMarket(marketName) {
    let cachedMarket = this._marketsByName[marketName];
    if (!cachedMarket) {
      let marketCacheKey = `marketByNameWithInstruments${marketName}`;
      const saved = localStorage.getItem(marketCacheKey);
      if (saved) {
        try {
          cachedMarket = JSON.parse(saved);
          if (!cachedMarket.dt || cachedMarket.exp < Date.now() / 1000) {
            console.log('Cached market expired', marketName);
            cachedMarket = null;
            localStorage.removeItem(marketCacheKey);
          } else {
            cachedMarket = cachedMarket.dt;
            cachedMarket.instruments = cachedMarket.instruments.map(it => ({
              ...it,
              market: { ...cachedMarket, instruments: [] }
            }))
            this._marketsByName[marketName] = cachedMarket;
          }
          // eslint-disable-next-line no-empty
        } catch (e) { }
      }
    }
    return cachedMarket;
  }

  saveInstrumentToCache(marketName, instrument, overwrite = true) {
    if (instrument) {
      const instrumentKey = `${marketName.replaceAll(/\W+/gi, '_').toUpperCase()}__${instrument.ticker
        }`;
      this._loadInstrumentToCache(instrumentKey, instrument);

      let instrumentCacheKey = `instrumentByKey${instrumentKey}`;
      let cachedInstrument;
      if (!overwrite) {
        cachedInstrument = localStorage.getItem(instrumentCacheKey);
        try {
          cachedInstrument = JSON.parse(cachedInstrument);
          if (cachedInstrument && (!cachedInstrument.dt || !cachedInstrument.dt.market || cachedInstrument.exp < Date.now() / 1000)) {
            console.log('Cached instrument expired', instrumentKey, (cachedInstrument?.exp || 0) - Date.now() / 1000, cachedInstrument);
            cachedInstrument = null;
            // localStorage.removeItem(instrumentCacheKey);
          }
        } catch (e) {
            console.error('Failed to parse instrument cache', e);
            cachedInstrument = null;
        }
      }
      if (
        overwrite ||
        !cachedInstrument && instrument.name
      ) {
        try {
          localStorage.setItem(instrumentCacheKey, JSON.stringify({
              dt: { ..._.pick({...instrument, market: instrument.market && {name: instrument.market.name}}, INSTRUMENT_CACHE_FIELDS), lastDirection: undefined },
              exp: Date.now() / 1000 + 3600 * 24 * 7,
            })
          );
        } catch (e) {
            console.log('WARNING: Failed to save instrument cache', e);
        }
      }
    }
  }

  _loadInstrumentToCache(instrumentKey, instrument) {
    this._instrumentsByKey[instrumentKey] = instrument;
    this.socketHandler.subscribeInstrument(
      instrument.ticker,
      instrument.marketId,
      (updatedInstrument) => {
        // console.log('received new instrument', updatedInstrument);
        instrument.last = updatedInstrument.last;
        instrument.var = updatedInstrument.var;
        instrument.vol = updatedInstrument.vol;
        instrument.lastSeen = updatedInstrument.lastSeen;
        setTimeout(() => {
          if (instrument.name) {
            try {
              localStorage.setItem(`instrumentByKey${instrumentKey}`, JSON.stringify({dt: _.pick({...instrument, market: instrument.market && {name: instrument.market.name}}, INSTRUMENT_CACHE_FIELDS), exp: Date.now() / 1000 + 3600 * 24 * 7}));
            } catch (e) {
                console.log('WARNING: Failed to save instrument cache', e);
            }
          }
        }, 67);
      }
    );
  }

  async findUserById(id) {
    const user =
      (await this._call('GET', `/api/users/${id}`, {
        filter: {
          // TODO: Solve instrument inclusion. Not available with different datasource due to $lookups
          include: [
            // { relation: 'buyOrders', scope: { where: { status: { $nin: ['REJECTED', 'PARTIALLY_REJECTED'] } } } },
            // { relation: 'saleOrders' },
            {
              relation: 'wallets',
              scope: {
                include: [
                  {
                    relation: 'buyOrders',
                    scope: {
                      where: { status: { $nin: ['REJECTED', 'PARTIALLY_REJECTED'] } },
                      include: [{ relation: 'user' }, { relation: 'instrument' }, { relation: `buyExecutions` }]
                    },
                  },
                  {
                    relation: 'saleOrders',
                    scope: { include: [{ relation: 'user' }, { relation: 'instrument' }, { relation: `saleExecutions` }] },
                  },
                ],
              },
            },
          ],
        },
      })) || {};
    CommonUtils.sortBy(user.wallets, ['balance', -1]);
    user.totalProfit = 0;
    user.buyOrders = []
    user.saleOrders = []
    user.totalBuyVolume = 0;
    user.totalSellVolume = 0;
    user.totalRemainingValue = 0;
    // if (user.wallets.length) {
    //   const randomIdxToCompute = Math.floor(Math.random() * user.wallets.length);
    //   this.computeWalletsRemainingValue([user.wallets[randomIdxToCompute]]).catch(console.error);
    // }
    for (let i = user.wallets.length - 1; i >= 0; i--) {
      const wallet = user.wallets[i];

      user.buyOrders.splice(0, 0, ...wallet.buyOrders);
      user.saleOrders.splice(0, 0, ...wallet.saleOrders);

      wallet.tournament = await this.findTournamentById(wallet.tournamentId, true);

      if (!wallet.tournament) {
        user.wallets.splice(i, 1);
        // eslint-disable-next-line no-continue
        continue;
      }

      wallet.absProfit =
        wallet.balance + (wallet.remainingValue || 0) - wallet.tournament.initialBalance;
      user.totalProfit += wallet.absProfit;

      for (const key of ['buy', 'sale']) {
        // wallet[`${key}Orders`] = await this.findAllOrdersInWallet(key, wallet.id);
        for (let j = 0; j < wallet[`${key}Orders`].length; j++) {
          const order = wallet[`${key}Orders`][j];

          if (key === 'sale') {
            for (let k = 0; k < order.saleExecutions.length; k++) {
              const saleExecution = order.saleExecutions[k];
              if (saleExecution.totalBuyValue) {
                saleExecution.absProfit =
                  saleExecution.executionPrice * saleExecution.executedTitles -
                  saleExecution.totalBuyValue;
                saleExecution.profitability = saleExecution.absProfit / saleExecution.totalBuyValue;
              } else {
                saleExecution.absProfit = 0;
                saleExecution.profitability = 0.0;
              }
              user.totalSellVolume += saleExecution.executedTitles * saleExecution.executionPrice;
            }
          }/* else {
            CommonUtils.sortBy(order.buyExecutions, ['executionDate', 1]);
            CommonUtils.processBuyOrderExecutions(order);
            for (let k = 0; k < order.buyExecutions.length; k++) {
              user.totalBuyVolume += order.buyExecutions[k].executedTitles * order.buyExecutions[k].executionPrice;
              if (order.instrument) {
                user.totalRemainingValue += order.buyExecutions[k].remainingTitles * order.instrument.last;
              }
            }
          }*/
          CommonUtils.sortBy(order[`${key}Executions`],
            ['profitability', -1],
            ['absProfit', -1],
            ['idExecution', -1],
            ['executionDate', -1],
            ['id', -1],
          );
          const initialInstrument = await this.findInstrumentByTickerAndMarket(
            order.ticker,
            order.market
          );

          for (let k = 0; k < user[`${key}Orders`].length; k++) {
            const userOrder = user[`${key}Orders`][k];
            if (userOrder.id === order.id) {
              user[`${key}Orders`][k] = order;
            }
          }

          if (initialInstrument) {
            let newName = initialInstrument.name;
            if (newName.length > 18) {
              newName = initialInstrument.name.substring(0, 18).trim() + '...';
            }
            order.instrument = {
              ...initialInstrument,
              displayName: newName,
            };
          }
        }
      }

      wallet.position = i;

      wallet.profitability =
        Math.round(
          (wallet.profitability || (wallet.balance - user.initialBalance) / user.initialBalance) *
          1000.0
        ) / 1000.0;
    }
    user.totalEarnings = user.totalSellVolume + user.totalRemainingValue - user.totalBuyVolume;
    return user;
  }

  // async findAllWalletsInTournament(tournamentId) {
  //   return (
  //     await this._call('GET', `/api/wallets`, {
  //       filter: {
  //         where: { tournamentId },
  //         include: [{ relation: 'user' }, { relation: 'tournament' }],
  //       },
  //     })
  //   );
  // }

  async findAllOrdersInWallet(orderType, walletId) {
    return this._call('GET', `/api/${orderType}-orders`, {
      filter: {
        where: { walletId },
        include: [{ relation: 'user' }, { relation: `${orderType}Executions` }],
      },
    });
  }

  async findRemainingOrdersByWalletAndIsin(orderType, walletId, isin) {
    const orders = await this._call('GET', `/api/${orderType}-orders`, {
      filter: {
        where: { walletId, ISIN: isin, remainingTitles: { $gt: 0 } },
        include: [/*{ relation: 'user' },*/ { relation: `${orderType}Executions` }],
      },
    });
    CommonUtils.sortBy(orders, ['date', 1]);

    const requests = [];
    for (let i = 0; i < orders.length; i++) {
      const order = orders[i];
      requests.push(
        (async () => {
          const initialInstrument = await this.findInstrumentByTickerAndMarket(
            order.ticker,
            order.market
          );
          if (initialInstrument) {
            let newName = initialInstrument.name;
            if (newName.length > 18) {
              newName = initialInstrument.name.substring(0, 18).trim() + '...';
            }
            order.instrument = {
              ...initialInstrument,
              displayName: newName,
            };
            setTimeout(() => {
              this.findInstrumentByTickerAndMarket(order.ticker, order.market, true).then(v => {
                console.log('Updated instrument', order.market, order.ticker, v && true)
              }).catch(reason => {
                console.error(reason);
              })
            }, 30000)
          }
        })()
      );
    }
    await Promise.all(requests);
    return orders;
  }

  async createWalletInTournament(tournament, shareCode = undefined) {
    return this._call('POST', `/api/wallets`, {
      created: new Date(),
      userId: this._user,
      tournamentId: tournament.id,
      balance: tournament.initialBalance,
      remainingValue: 0,
      currentProfit: 0,
      currentProfitability: 0,
      profitability: 0.0,
      active: true,
      hasPositions: false,
      shareCode,
    });
  }

  async getFormattedRateHistoryForTicker(instrumentId, timeRange) {
    const baseUrl = this._baseUrl
      .replace(/next[^.]*\./g, 'markets.')
      .replace(/^http:\/\/localhost(?::\d+)?/, 'https://markets.dev.senseitrade.com');
    console.log('markets baseUrl', baseUrl);
    const initialData =
      (await this._call(
        'GET',
        `/api/historic?instrument=${instrumentId}&time=${timeRange}`,
        {},
        { baseUrl }
      )) || [];

    const outData = [];
    let min = 1e6;
    let max = 0;
    if (initialData !== null) {
      for (let i = initialData.length - 1; i >= 0; i--) {
        const rate = initialData[i];
        const { volume, last, date } = rate;
        let dateSt = new Date(date).toISOString();
        const r = DATE_REGEX.exec(dateSt)
        outData.push({ x: `${r[3]}/${r[2]}/${r[1]} ${r[4]}:${r[5]}`, y: last });
        min = Math.min(min, last);
        max = Math.max(max, last);
      }
    }
    if (min === 1e6) {
      min = 0;
    }

    return { min, max, data: outData };
  }

  async getWalletEntries(dateRange, walletId) {
    const walletEntries = await this._call(
      'GET',
      `/api/wallet-entries`,
      dateRange !== 'all'
        ? {
          filter: JSON.stringify({
            where: {
              walletId,
              date: {
                $gte: new Date(dateRange),
                // $lte: new Date(),
              },
            },
            order: ['date ASC'],
          }),
        }
        : {
          filter: JSON.stringify({
            where: {
              walletId,
            },
            order: ['date ASC'],
          }),
        }
    );

    const outData = [];
    let min = 1e6;
    let max = 0;
    for (let i = 0; i < walletEntries.length; i++) {
      const walletEntry = walletEntries[i];
      const last = walletEntry.balance + walletEntry.remainingValue;

      // let dateSt = new Date(walletEntry.date).toISOString();
      // const r = DATE_REGEX.exec(dateSt)
      const r = DATE_REGEX.exec(walletEntry.date)

      outData.push({ x: `${r[3]}/${r[2]}/${r[1]}`, y: last });
      min = Math.min(min, last);
      max = Math.max(max, last);
    }
    if (min === 1e6) {
      min = 0;
    }

    return { min, max, data: outData };
  }

  async visitWallet(walletId) {
    return this._call('POST', `/api/update-wallet-last-access?walletId=${walletId}`, {});
  }

  async createOrder(order, tournamentId) {
    // eslint-disable-next-line no-nested-ternary
    const orderPrefix = order.type === '11' ? `buy` : order.type === '12' ? 'sale' : null;
    const createdOrder = await this._call('POST', `/api/${orderPrefix}-orders`, { ...order, userId: this._user });
    console.log('created order', createdOrder);

    // Force recalculate of tournament positions
    const cachedTournament = await this.findTournamentById(tournamentId, false, true, true);
    console.log('ignore cached tournament', cachedTournament)
    const newTournament = await this.findTournamentById(tournamentId, false, true, true);
    console.log('new tournament', newTournament)

    return createdOrder;
  }

  get socketHandler() {
    if (this._marketSocketHandler) {
      return this._marketSocketHandler;
    }

    // let [open, setOpen] = [false, (value) => (open = value) && false];
    // let [message, setMessage] = [null, (value) => (message = value) && false];

    const socketUrl = `${this._baseUrl
      .replace(/^https?:\/\/(.*)next(?=\W)/, 'wss://$1marketssocket')
      .replace(/^http:\/\/localhost(?::\d+)?/, 'wss://marketssocket.dev.senseitrade.com')
      .replace(/^https:\/\/.*ngrok\.io.*?/, 'wss://marketssocket.dev.senseitrade.com')}`;
    console.log('socket url', socketUrl);
    let socket;

    // const events = new EventEmitter();

    // eslint-disable-next-line no-shadow
    const subscribeInstrument = (ticker, marketId, cb) =>
      socket.addEventListener('instrument', (e) => {
        if (e.instrument.ticker === ticker && e.instrument.marketId === marketId) {
          // console.log('received instrument', e.instrument);
          cb(e.instrument);
        } else {
          // console.log('different', [e.instrument.ticker, ticker], [e.instrument.marketId, marketId]);
        }
      });

    const self = this;
    const openSocket = () => {
      socket = new WebSocket(
        socketUrl /*{
        headers: {
          Authorization: self._token,
        },
      }*/
      );

      socket.onopen = (...args) => {
        console.log('socket open', args);
      };

      socket.onmessage = (msg) => {
        // console.log('socket message', msg);
        try {
          const event = new Event('instrument');
          // event.name = 'instrument';
          // event.instrument = JSON.parse(msg.data);
          // socket.dispatchEvent(event);
          const parsed = JSON.parse(msg.data);
          // console.log('socket message', parsed);
          try {
            event.instrument = parsed;
            // events.emit('instrument', parsed);
            socket.dispatchEvent(event);
          } catch (e) {
            console.error(e);
          }
        } catch (e) {
          // console.error(e);
        }
      };

      socket.onerror = (err) => {
        console.error(err);
      };

      socket.onclose = (...args) => {
        console.log('socket close', args);
        setTimeout(openSocket, 5000);
      };

      console.log('socket', socket);
    };

    openSocket();

    this._marketSocketHandler = {
      // onOpen: () => [open, setOpen],
      // messageState: () => [message, setMessage],
      subscribeInstrument,
    };

    return this._marketSocketHandler;
  }

  refreshToken(tokenToForce = undefined) {
    return this._call('POST', '/api/users/refreshToken', {}, { headers: { Authorization: `Bearer ${tokenToForce || this._token}` } })
  }
}

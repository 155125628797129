import { UiWrapper } from 'coinscrap-webapp-core-ts';

UiWrapper.defineComponent('selectItem', {
  styles: {
    default: {
      textComponent: {
        fontSize: '14px',
        color: '#979797',
      },
    },
  },
});

import styled from 'styled-components/macro';
import { space, layout } from 'styled-system';

export const Button = styled.button`
  align-items: center;
  font-family: EurostileBold;
  background: ${(props) => (props.full ? 'linear-gradient(180deg, var(--color-pink) 0%, var(--color-violet) 100%)' : 'linear-gradient(180deg, #02BCFA 0%, #0059FA 100%)')};
  border: ${(props) =>
    props.pink ? '0' : '0'};
  border-top-left-radius: ${(props) => (props.right ? '0' : '10px')};
  border-top-right-radius: ${(props) => (props.left ? '0' : '10px')};
  border-bottom-left-radius: ${(props) => (props.right ? '0' : '10px')};
  border-bottom-right-radius: ${(props) => (props.left ? '0' : '10px')};
  color: var(--color-white);
  display: flex;
  font-size: 18px;
  font-weight: lighter;
  justify-content: center;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  padding: 15px;
  text-transform: uppercase;
  transition: all 0.2s ease;
  width: ${(props) => (props.fullWidth ? '100%' : '240px')};
  margin-left: ${(props) => (props.right ? '2px' : '0')};
  margin-right: ${(props) => (props.left ? '2px' : '0')};
  cursor: pointer;
  ${space};
  ${layout};

  &:active {
    transform: translateY(3px);
  }
`;
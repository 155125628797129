import * as S from './styles';

export const Plabel = ({ children, className, center, marginTop, marginBottom, paddingX }) => (
  <S.Plabel
    className={className + ' plabel'}
    center={center}
    marginTop={marginTop}
    marginBottom={marginBottom}
    paddingX={paddingX}
  >
    {children}
  </S.Plabel>
);

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import { useEffect, useState } from 'react';
import * as S from './styles';
import { Api } from '../../../api/api';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const LineChart = ({ isWallet, walletId }) => {
  const [today, setToday] = useState(new Date());
  const [selecfiltro, setSelectfiltro] = useState('1s');

  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [walletEntries, setWalletEntries] = useState([]);

  useEffect(() => {
    let dateRange;
    switch (selecfiltro) {
      case '1s':
        dateRange = Date.now() - 86400 * 7 * 1000;
        break;
      case '1m':
        dateRange = Date.now() - 86400 * 31 * 1000;
        break;
      case '3m':
        dateRange = Date.now() - 86400 * 31 * 3 * 1000;
        break;
      case '12m':
        dateRange = Date.now() - 86400 * 31 * 366 * 1000;
        break;
      case 't':
        dateRange = 'all';
        break;
      default:
        console.error('unknown interval', selecfiltro);
        break;
    }
    if (walletId) {
      Api.instance
          .getWalletEntries(dateRange, walletId)
          .then(({ min: newMin, max: newMax, data: formattedData }) => {
            setWalletEntries(formattedData);
            setMin(newMin);
            setMax(newMax);
          });
    }
  }, [selecfiltro, walletId]);

  const [overViewOptions, setOverViewOptions] = useState({
    responsive: true,
    animation: {
      duration: 0,
    },
    aspectRatio: 4,
    layout: {
      padding: '0',
      autoPadding: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      xAxis: {
        display: false,
      },
      yAxis: {
        display: false,
      },
    },
    elements: {
      point: {
        borderWidth: '0',
        pointStyle: 'line',
      },
      line: {
        borderWidth: '0',
      },
    },
  });

  const [walletOptions, setWalletOptions] = useState({
    responsive: true,
    animation: {
      duration: 0,
    },
    aspectRatio: 1,
    layout: {
      padding: '0',
      autoPadding: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      xAxis: {
        display: false,
      },
      yAxis: {
        display: false,
      },
    },
    elements: {
      point: {
        borderWidth: '0',
        pointStyle: 'line',
      },
      line: {
        borderWidth: '0',
      },
    },
  });
  useEffect(() => {
    overViewOptions.scales.yAxis.suggestedMin = (min * 0.99) - 0.1;
    overViewOptions.scales.yAxis.min = (min * 0.99) - 0.1;
    walletOptions.scales.yAxis.suggestedMin = (min * 0.99) - 0.1;
    walletOptions.scales.yAxis.min = (min * 0.99) - 0.1;
    overViewOptions.scales.yAxis.suggestedMax = (max * 1.01) || 0.1;
    overViewOptions.scales.yAxis.max = (max * 1.01) || 0.1;
    walletOptions.scales.yAxis.suggestedMax = (max * 1.01) || 0.1;
    walletOptions.scales.yAxis.max = (max * 1.01) || 0.1;
    setOverViewOptions(overViewOptions);
    setWalletOptions(walletOptions);
  }, [min, max]);

  const data = (canvas) => {
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 150);
    gradient.addColorStop(0, '#02BCFA');
    gradient.addColorStop(1, '#0059FA');
    return {
      labels: walletEntries.map((entry) => entry.x),
      datasets: [
        {
          data: walletEntries,
          fill: true,
          backgroundColor: gradient,
          tension: 0.5,
        },
      ],
    };
  };

  return walletEntries.length >= 3 ? (
    <S.Row marginBottom="30px">
      <Line
        options={isWallet ? walletOptions : overViewOptions}
        data={data}
        width="100%"
        height="auto"
      />
      <S.CajaBlanca>
        <S.Filtro
          active={selecfiltro === '1s'}
          onClick={(e) => {
            setToday(new Date());
            setSelectfiltro('1s');
          }}
        >
          1S
        </S.Filtro>
        <S.Filtro
          active={selecfiltro === '1m'}
          onClick={(e) => {
            setToday(new Date());
            setSelectfiltro('1m');
          }}
        >
          1M
        </S.Filtro>
        <S.Filtro
          active={selecfiltro === '3m'}
          onClick={(e) => {
            setToday(today);
            setSelectfiltro('3m');
          }}
        >
          3M
        </S.Filtro>
        <S.Filtro
          active={selecfiltro === '12m'}
          onClick={(e) => {
            setToday(new Date());
            setSelectfiltro('12m');
          }}
        >
          12M
        </S.Filtro>
        <S.Filtro
          active={selecfiltro === 't'}
          onClick={(e) => {
            setToday(new Date());
            setSelectfiltro('t');
          }}
        >
          Todo
        </S.Filtro>
      </S.CajaBlanca>
    </S.Row>
  ) : null;
};

import * as S from './styles';

export const H1small = ({
  children,
  className,
  marginBottom,
  blue,
  center,
  marginRight,
  paddingX,
  onClick,
}) => (
  <S.H1small
    className={className + ' h1small'}
    marginBottom={marginBottom}
    blue={blue}
    center={center}
    marginRight={marginRight}
    paddingX={paddingX}
    onClick={onClick}
  >
    {children}
  </S.H1small>
);

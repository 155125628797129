import { MainButton } from 'components/MainButton/MainButton';
import { H3 } from 'components/H3/H3';
import { P } from 'components/P/P';
import { Header } from 'components/Header/header';

import { MarginWrapper } from 'layout/MarginWrapper/MarginWrapper';
import { Input, NavigationWrapper, UiWrapper } from 'coinscrap-webapp-core-ts';
import { routes } from 'routes';
import { useEffect, useState } from 'react';
import * as S from './styles';
import {TournamentUtils, useTemporaryTournament} from '../../utils/TournamentUtils';
import { Api } from '../../api/api';

export const NombreTorneo = () => {
  const { navigateTo } = NavigationWrapper.use();
  const { useHeader } = UiWrapper.use();

  const [tmpTournament, setTmpTournament] = useTemporaryTournament();

  useEffect(() => {
    localStorage.removeItem('should_restart_creation');
  }, [])

  useHeader(<Header closeRoute={routes.tournamentHomeMain} />);
  return (
    <S.Wrapper>
      <S.Content>
        <MarginWrapper justifyContent="space-between">
          <S.Caja>
            <H3>Ponle un nombre a tu Torneo</H3>
            <Input
              type="text"
              placeholder="Ej: SENSEIZERO"
              value={tmpTournament.name}
              onChange={(e) => setTmpTournament({ name: e.target.value })}
              style={{ cursor: 'pointer' }}
            />
            <P color="white">
              El nombre del Torneo servirá para que tus amigos puedan encontrarlo y unirse a él.
            </P>
          </S.Caja>
          <MainButton
            pink
            full
            fullWidth
            disabled={!tmpTournament.name || !tmpTournament.name.trim()}
            marginTop="10px"
            onClick={() => {
              // if (tmpTournament.name.trim()) {
              console.log('go to dates');
              navigateTo(routes.tournamentCreateDates);
              // }
            }}
          >
            SIGUIENTE
          </MainButton>
        </MarginWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

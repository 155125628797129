import styled from 'styled-components/macro';
import { space, layout } from 'styled-system';

export const H1 = styled.h1`
  color: ${(props) => (props.black ? 'black' : 'var(--color-white)')};
  font-size: 23px;
  line-height: 33px;
  text-align: center;
  text-transform: uppercase;
  font-family: EurostileBold;
  width: 100%;
  ${space};
  ${layout};
`;
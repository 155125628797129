import { UiWrapper } from 'coinscrap-webapp-core-ts';

UiWrapper.defineComponent('textField', {
  styles: {
    default: {
      containerComponent: {
        width: '100%',
        background: 'white',
        padding: '0',
        margin: '15px 0 20px',
        borderRadius: '4px',
        border: '0',
      },
      inputContainerComponent: {
        background: 'white',
        padding: '15px',
        borderRadius: '4px',
        border: '0',
      },
      inputComponent: {
        fontSize: '13px',
        color: '#1A1B21',
        fontFamily: 'Helvetica',
        padding: '0',
        paddingLeft: '10px',
      },
    },
    cursorSt: {
      inputComponent: { cursor: 'pointer' }
    },
    transparentSt: {
      containerComponent: {
        background: 'rgba(255,255,255,0.16)',
      },
      inputContainerComponent: {
        background: 'transparent',
      },
      inputComponent: {
        color: 'white',
      },
    },
    borderBottomSt: {
      containerComponent: {
        background: 'transparent',
        width: '100%',
        borderRadius: '0',
        border: '0',
        borderBottom: '1px solid white',
        margin: '10px 0',
      },
      inputContainerComponent: {
        background: 'transparent !important',
        padding: '10px 0',
      },
      inputComponent: {
        fontSize: '18px',
        color: 'white',
        fontFamily: 'EurostileBold',
        fontWeight: '100',
        padding: '0',
        paddingLeft: '0',
      },
      labelComponent: {
        fontSize: '14px',
        color: 'white',
        fontFamily: 'Helvetica',
        transform: 'translate(0px, -10px) scale(0.85) !important',
      },
    },
    sinmarginSt: {
      containerComponent: {
        margin: '15px 0 5px',
      },
    },
    whiteSt: {
      containerComponent: {
        width: '100%',
        background: 'white',
        padding: '0',
        margin: '15px 0 20px',
        borderRadius: '4px',
        border: '0',
      },
      inputContainerComponent: {
        background: 'white',
        padding: '15px',
        borderRadius: '4px',
        border: '0',
      },
      inputComponent: {
        fontSize: '16px',
        color: '#6586D4',
        fontFamily: 'Helvetica',
        padding: '0',
        paddingLeft: '10px',
      },
      labelComponent: {
        color: 'white',
        fontSize: '14px',
        fontFamily: 'Helvetica',
        transform: 'translate(0px, -20px) scale(0.85) !important',
      },
    },
  },
});

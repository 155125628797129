import styled from 'styled-components/macro';
import { space, layout } from 'styled-system';

export const H1regular = styled.h1`
  color: ${(props) => props.color || 'var(--color-white)'};
  font-family: HelveticaBold;
  font-size: ${(props) => (props.big ? '36px' : '29px')};
  font-weight: lighter;
  line-height: 9vw;
  text-align: center;
  width: 100%;
  ${space};
  ${layout};
`;

import styled from 'styled-components/macro';
import { verticalFlex } from 'styles/common';

export const Scroll = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 -20px 0 0;
  max-width: calc(100% + 20px);
  overflow: scroll;
  width: calc(100% + 20px);
  height: 70vh;
  @media (max-width: 990px) {
    height: auto;
  }
`;

export const Wrapper = styled.div`
  ${verticalFlex}
  align-items: center;
  justify-content: space-between;
  min-height: 100%;
  width: 100%;
`;

export const Content = styled.div`
  ${verticalFlex}
  flex: 1;
  height: 100%;
  justify-content: space-between;
  padding: 0 20px;
  width: 100%;
`;

export const Caja = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
`;

export const CajaTop = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  margin-bottom: auto;
`;

export const Cajatransparente = styled.div`
  align-items: center;
  background: transparent;
  border-bottom: ${(props) => (props.border ? '1px solid rgba(255,255,255,0.3)' : '0')};
  border-radius: ${(props) => (props.border ? '0' : '4px')};
  display: flex;
  flex-direction: ${(props) => (props.row ? 'row' : 'column')};
  margin-bottom: 10px;
  margin-right: ${(props) => (props.marginright ? '10px' : '0')};
  max-width: ${(props) => (props.midwith ? '70%' : '100%')};
  padding-bottom: ${(props) => (props.border ? '10px' : '0')};
  width: ${(props) => (props.midwith ? '70%' : '100%')};
`;

export const CajaAzulDegradado = styled.div`
  align-items: end;
  background: linear-gradient(90deg, #1a1c23 0%, #193c83 100%);
  border-radius: 4px 4px 0px 0px;
  display: flex;
  max-width: 100%;
  padding: 10px;
  width: 100%;
`;

export const Ico = styled.img`
  border: 2px solid white;
  border-radius: 50px;
  height: 48px;
  margin-bottom: ${(props) => (props.sinmargin ? '0' : '-20px')};
  margin-right: 8px;
  width: 48px;
  z-index: 999;
`;

export const CajaInterior = styled.div`
  background: white;
  border-radius: 0 0 4px 4px;
  display: flex;
  max-width: 100%;
  padding: 20px 0px 20px 20px;
  width: 100%;
`;

export const CajaDer = styled.div`
  align-items: start;
  border-right: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  width: 60%;
`;

export const CajaDere = styled.div`
  align-items: end;
  display: flex;
  width: 64%;
`;

export const CajaIzq = styled.div`
  align-items: end;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 36%;
`;

export const Row = styled.div`
  align-items: ${(props) => (props.align === 'center' ? 'center' : 'initial')};
  justify-content: ${(props) => (props.content === 'center' ? 'center' : 'initial')};
  display: flex;
  width: 100%;
`;

export const Privado = styled.p`
  color: white;
  font-family: 'Helvetica';
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  text-overflow: ellipsis;
  margin: 0 5px;
`;

export const CajaBlanca = styled.div`
  align-items: center;
  background: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin: 2.5px;
  padding: 10px 5px;
  position: relative;
  width: calc(33.33% - 5px);
`;

export const P = styled.p`
  color: black;
  font-family: HelveticaBold;
  font-size: 12px;
  text-align: center;
`;

export const Icon = styled.img`
  border: 2px solid white;
  border-radius: 50px;
  height: 48px;
  margin-bottom: 5px;
  width: 48px;
`;

export const Img = styled.img`
  position: absolute;
  right: 5px;
  top: -5px;
`;

export const Pblack = styled.p`
  color: black;
  font-family: Helvetica;
  font-size: 15px;
  text-align: left;
`;

export const CajaBlancaG = styled.div`
  background: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  width: 100%;
`;

export const Titulo = styled.p`
  color: #b4b4bb;
  font-family: Helvetica;
  font-size: 16px;
  text-align: left;
`;

export const Span = styled.span`
  color: ${(props) => props.color};
  margin-right: ${(props) => (props.marginright ? '5px' : '0')};
  padding-right: ${(props) => (props.paddingright ? '5px' : '0')};
`;

export const Top = styled.img`
  height: 8px;
  margin-left: ${(props) => (props.marginleft ? '10px' : '0')};
  padding-right: 5px;
  width: auto;
`;

export const Lista = styled.div`
  align-items: start;
  display: ${(props) => (props.active ? 'flex' : 'none')};
  flex-direction: column;
  margin-bottom: ${(props) => (props.marginBottom ? '15px' : '0')};
  width: 100%;
`;

export const Password = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  padding: 5px 0;
  cursor: pointer;
`;

export const Flex = styled.div`
  align-items: center;
  display: flex;
  max-width: 100%;
  width: 100%;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: calc(100% - 55px);
`;

export const Trades = styled.img`
  height: 16px;
  margin-left: ${(props) => (props.marginleft ? '10px' : '0')};
  padding-right: 5px;
  vertical-align: middle;
  width: auto;
`;

export const IcoG = styled.img`
  border: 2px solid white;
  border-radius: 70px;
  height: 110px;
  margin: 10px auto;
  width: 110px;
  z-index: 999;
`;

export const Tab = styled.div`
  display: flex;
  justify-content: centern;
  margin-bottom: 40px;
  width: 100%;
`;

export const TabTitle = styled.h2`
  color: ${(props) => (props.active ? '#01A8FA' : 'white')};
  font-size: 12px;
  flex: 1;
  align-self: center;
  text-align: center;
  text-transform: uppercase;
  /* background: ${(props) => (props.active ? '#01A8FA' : 'white')};
  border-radius: 4px;
  color: ${(props) => (props.active ? 'white' : '#01A8FA')};
  display: flex;
  flex-direction: column; */
  font-family: Helvetica;
  font-size: 11px;
  justify-content: center;
  margin: 0 2px;
  padding: 10px 13px 6px;
  cursor: pointer;
`;

export const Modal = styled.div`
  background: var(--background-global);
  background-position: center;
  border-radius: 20px;
  margin: 0 20px;
  padding: 30px 20px;
`;

export const Cerrar = styled.img`
  float: right;
`;

export const White = styled.p`
  display: ${(props) => (props.active ? 'flex' : 'none')};
  color: #ffffff;
  font-family: Helvetica;
  font-size: 16px;
  text-align: center;
`;

export const Fecha = styled.p`
  color: #ffffff;
  font-family: Helvetica;
  font-size: 20px;
  text-align: center;
  line-height: bold;
`;
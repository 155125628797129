import styled from 'styled-components/macro';
import { space, layout } from 'styled-system';

export const Header = styled.div`
  align-items: center;
  display: ${(props) => props.display || 'flex'};
  justify-content: space-between;
  padding: 30px;
  width: 100%;
  ${space};
  ${layout};
  z-index: 2;
  @media (max-width: 990px) {
    background: #1a1b21;
  }
`;

export const Img = styled.img`
  ${space};
  ${layout};
`;

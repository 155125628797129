import styled from 'styled-components/macro';
import { verticalFlex } from 'styles/common';

export const Wrapper = styled.div`
  ${verticalFlex}
  align-items: center;
  justify-content: space-between;
  min-height: 100%;
  width: 100%;
`;

export const Content = styled.div`
  ${verticalFlex}
  flex: 1;
  height: 100%;
  justify-content: space-between;
  padding: 0 20px;
  width: 100%;
`;

export const Caja = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;
  height: ${(props) => (props.fijo ? 'calc(100vh - 195px)' : 'auto')};
  justify-content: start;
  overflow: ${(props) => (props.fijo ? 'scroll' : 'initial')};
  width: 100%;
`;

export const CajaAzulDegradado = styled.div`
  align-items: end;
  background: linear-gradient(90deg, #1a1c23 0%, #193c83 100%);
  border-radius: 4px 4px 0px 0px;
  display: flex;
  max-width: 100%;
  width: 100%;
`;

export const Ico = styled.img`
  border: 2px solid white;
  border-radius: 50px;
  height: 48px;
  margin-bottom: ${(props) => (props.sinmargin ? '0' : '-30px')};
  margin-right: 8px;
  width: 48px;
  z-index: 999;
`;

export const CajaDer = styled.div`
  align-items: start;
  border-right: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 0;
  padding: 0 20px;
  width: 60%;
`;

export const CajaDere = styled.div`
  align-items: end;
  display: flex;
  margin: 13px 0;
  padding: 0 10px;
  width: 64%;
`;

export const CajaIzq = styled.div`
  align-items: end;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px 0;
  padding: 0 20px;
  width: 40%;
`;

export const CajaIzqi = styled.div`
  align-items: end;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 13px 0;
  padding: 0 20px;
  width: 36%;
`;

export const CajaInterior = styled.div`
  background: white;
  border-radius: 0 0 4px 4px;
  display: flex;
  max-width: 100%;
  width: 100%;
`;

export const Cajatransparente = styled.div`
  align-items: center;
  background: transparent;
  border-bottom: ${(props) => (props.border ? '1px solid rgba(255,255,255,0.3)' : '0')};
  border-radius: ${(props) => (props.border ? '0' : '4px')};
  display: flex;
  flex-direction: ${(props) => (props.row ? 'row' : 'column')};
  margin-bottom: 10px;
  margin-right: ${(props) => (props.marginright ? '10px' : '0')};
  max-width: ${(props) => (props.midwith ? '70%' : '100%')};
  padding-bottom: ${(props) => (props.border ? '10px' : '0')};
  width: ${(props) => (props.midwith ? '70%' : '100%')};
`;

export const CajaChat = styled.div`
  align-items: start;
  display: flex;
  justify-content: ${(props) => (props.right ? 'end' : 'start')};
  margin-top: ${(props) => (props.margintop ? '20px' : '0')};
  max-width: 100%;
  padding-left: ${(props) => (props.marginleft ? '20px' : '0')};
  padding-right: ${(props) => (props.marginright ? '20px' : '0')};
  width: ${(props) => (props.margin ? 'calc(100% - 20px)' : '100%')};
`;

export const IcoChat = styled.img`
  border: 2px solid white;
  border-radius: 50px;
  height: 48px;
  width: 48px;
`;

export const FlechaChat = styled.img`
  height: 23px;
  margin-right ${(props) => (props.right ? 'initial' : '-1px')};
  margin-left ${(props) => (props.right ? '-1px' : 'initial')};
  margin-top: -1px;
  transform ${(props) => (props.right ? 'rotate(180deg)' : 'initial')};
`;

export const CajaInteriorChat = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 20px;
  padding-bottom: 30px;
  position: relative;
  width: calc(100% - 40px);
`;

export const Fijo = styled.div`
  background: #d2d2d2;
  bottom: 0;
  left: 0;
  max-width: 100%;
  padding: 5px 10px 0;
  position: fixed;
  width: 100%;
`;

import { H1eurobold } from 'components/H1eurobold/H1eurobold';
import { H2 } from 'components/H2/H2';
import { H4 } from 'components/H4/H4';
import { P } from 'components/P/P';

import { MarginWrapper } from 'layout/MarginWrapper/MarginWrapper';
import { NavigationWrapper, SessionWrapper, UiWrapper } from 'coinscrap-webapp-core-ts';
import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import * as S from './styles';
import { TournamentActionButtons } from '../Crear/TournamentActionButtons';
import { Api } from '../../api/api';
import { routes } from '../../routes';
import * as CommonStyles from '../../styles/common';
import { Header } from '../../components/Header/header';
import { CommonUtils } from '../../utils/CommonUtils';

export const Start = () => {
  const initialLog = window.console.log;

  const { navigateTo } = NavigationWrapper.use();
  const { useHeader } = UiWrapper.use();

  const [loadedTournaments, setLoadedTournamentsTournaments] = useState(false);
  const [loadingMyTournaments, setLoadingMyTournaments] = useState(true);
  const [isFirstTime, setIsFirstTime] = useState(false);

  const { sessionData } = SessionWrapper.use();

  useEffect(() => {
    if (sessionData?.userId) {
      Api.instance
        .findTournamentsForMyWallets()
        .then((tournaments) => {
          setLoadingMyTournaments(false);
          if (tournaments) {
            setLoadedTournamentsTournaments(true);
            if (tournaments.length) {
              navigateTo(routes.tournamentHomeMain);
            } else {
              setIsFirstTime(true);
            }
          } else {
            setIsFirstTime(true);
          }
        })
        .catch((reason) => {
          console.error(reason);
          setLoadingMyTournaments(false);
          setIsFirstTime(true);
        });
    }
  }, [sessionData?.token, sessionData?.userId]);

  const fullScreen = new URLSearchParams(window.location.search).get('full_screen') || 'false';

  useHeader(
    fullScreen === 'true' ? (
      <Header canGoBack={false} canClose={false} closeRoute={routes.start} />
    ) : null
  );

  return loadingMyTournaments ? (
    <CommonStyles.FullHeightLoadingContainer>
      <ReactLoading type="spinningBubbles" color="#ffffff" height="20%" width="20%" />
    </CommonStyles.FullHeightLoadingContainer>
  ) : (
    <S.Wrapper>
      <S.Content>
        <MarginWrapper justifyContent="space-between">
          <div>
            <H1eurobold marginBottom="2vh">CREA Y PARTICIPA EN TORNEOS CON AMIGOS</H1eurobold>
            <H2 marginBottom="1.5vh">
              Aprende sobre la Compra/Venta de acciones jugando en la bolsa con dinero virtual
            </H2>
          </div>
          <S.Scroll>
            <S.Caja linea>
              <S.Img src="/game/img/aprende.svg" />
              <S.Cajita>
                <H4 marginBottom="10px">Aprende a invertir jugando</H4>
                <P color="white">Registrate sólo con tu correo electrónico y comienza a jugar.</P>
              </S.Cajita>
            </S.Caja>
            <S.Caja linea>
              <S.Img src="/game/img/dinero.svg" />
              <S.Cajita>
                <H4 marginBottom="10px">Dinero virtual</H4>
                <P color="white">
                  Te ofrecemos dinero ficticio para que aprendas a invertir, no te preocupes de
                  perder y puedas aprender.
                </P>
              </S.Cajita>
            </S.Caja>
            <S.Caja>
              <S.Img src="/game/img/crea.svg" />
              <S.Cajita>
                <H4 marginBottom="10px">Crea y participa en torneos de bolsa</H4>
                <P color="white">
                  Compite con tus amigos o compañeros de trabajo para ganar tu lugar en la cima de
                  la clasificación.
                </P>
              </S.Cajita>
            </S.Caja>
          </S.Scroll>
          <TournamentActionButtons isFirstTime={isFirstTime} style={{ marginBottom: '16px' }} />
        </MarginWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

import { MainButton } from 'components/MainButton/MainButton';
import { H3 } from 'components/H3/H3';
import { P } from 'components/P/P';
import { Header } from 'components/Header/header';
import LockIcon from 'assets/svg/lock.svg';

import { useEffect, useState } from 'react';
import { NavigationWrapper, UiWrapper, TextField, Image } from 'coinscrap-webapp-core-ts';
import { routes } from 'routes';
import * as S from './styles';
import { MarginWrapper } from '../../../layout/MarginWrapper/MarginWrapper';
import {TournamentUtils, useTemporaryTournament} from '../../../utils/TournamentUtils';
import { Api } from '../../../api/api';

export const Visibility = () => {
  const { navigateTo } = NavigationWrapper.use();
  const { useHeader } = UiWrapper.use();

  const [tmpTournament, setTmpTournament] = useTemporaryTournament();

  useHeader(<Header closeRoute={routes.tournamentHomeMain} />);
  return (
    <S.Wrapper>
      <S.Content>
        <MarginWrapper justifyContent="space-between">
          <S.Caja>
            <H3>Tipo de Torneo</H3>
            <S.Switch>
              <S.Option
                active={tmpTournament?.public !== false}
                onClick={(e) => {
                  setTmpTournament({  public: true});
                }}
              >
                Público
              </S.Option>
              <S.Option
                active={tmpTournament?.public === false}
                onClick={(e) => {
                  setTmpTournament({  public: false});
                }}
              >
                Privado
              </S.Option>
            </S.Switch>
            <P color="white">
              Cuando un torneo es Público, cualquier persona lo puede encontrar y unirse en
              cualquier momento
            </P>
            <S.Active activepass={false}>
              <TextField
                cursorSt
                type="password"
                placeholder="Contraseña"
                startAdornment={<Image height="24px" width="auto" src={LockIcon} />}
                value={tmpTournament.password}
                onChange={(e) => setTmpTournament({ ...tmpTournament })}
                transparentSt
              />
            </S.Active>
          </S.Caja>
          <MainButton
            pink
            full
            fullWidth
            marginTop="10px"
            onClick={() => {
              navigateTo(routes.tournamentCreateWalletVisibility);
            }}
          >
            SIGUIENTE
          </MainButton>
        </MarginWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

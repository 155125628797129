import { CommonUtils } from '../utils/CommonUtils';

export const routes = {
  entry: 'entry',
  start: 'start',
  newRandomUser: 'newrandomuser',
  createAvatar: 'createavatar',
  tournamentCreate: 'tournamentcreate',
  tournamentCreateName: 'tournamentcreatename',
  tournamentCreateDates: 'tournamentcreatedates',
  tournamentCreateVisibility: 'tournamentcreatevisibility',
  tournamentCreateWalletVisibility: 'tournamentcreatewalletvisibility',
  tournamentCreateInitialBalance: 'tournamentcreateinitialbalance',
  tournamentCreateFees: 'tournamentcreatefees',
  bettingCreateStart: 'bettingcreatestart',
  bettingCreateAmount: 'bettingcreateamount',
  tournamentCreateMarket: 'tournamentcreatemarket',
  tournamentCreateChooseMode: 'tournamentcreatechoosemode',
  tournamentCreateAdvancedMode: 'tournamentcreateadvancedmode',
  tournamentCreateSummary: 'tournamentcreatesummary',
  tournamentCreateAdvancedSummary: 'tournamentcreateadvancedsummary',
  tournamentShare: 'tournamentshare',
  tournamentHomeMain: 'tournamenthomemain',
  tournamentDetailOverview: 'tournamentdetailoverview',
  tournamentDetailOverviewFinal: 'tournamentdetailoverviewfinal',
  tournamentCompetitor: 'tournamentcompetitor',
  tournamentTradeStockDetail: 'tournamenttradestockdetail',
  tournamentTradeInputData: 'tournamenttradeinputdata',
  tournamentTradeSummary: 'tournamenttradesummary',
  tournamentChats: 'tourstartnamentchats',
  tournamentChatDetail: 'tournamentchatdetail',
  tournamentSearch: 'tournamentsearch',
  tournamentJoin: 'tournamentjoin',
};

export const routeMapping = {
  [routes.entry]: '/',
  [routes.start]: '/start',
  [routes.newRandomUser]: '/newRandomUser',
  [routes.createAvatar]: '/avatar',
  [routes.tournamentCreate]: '/tournament/create/start',
  [routes.tournamentCreateName]: '/tournament/create/name',
  [routes.tournamentCreateDates]: '/tournament/create/dates',
  [routes.tournamentCreateVisibility]: '/tournament/create/visibility',
  [routes.tournamentCreateWalletVisibility]: '/tournament/create/wallet-visibility',
  [routes.tournamentCreateInitialBalance]: '/tournament/create/initial-balance',
  [routes.tournamentCreateFees]: '/tournament/create/fees',
  [routes.bettingCreateStart]: '/betting/create/start',
  [routes.bettingCreateAmount]: '/betting/create/amount',
  [routes.tournamentCreateMarket]: '/tournament/create/market',
  [routes.tournamentCreateChooseMode]: '/tournament/create/choose-mode',
  [routes.tournamentCreateAdvancedMode]: '/tournament/create/advanced-mode',
  [routes.tournamentCreateSummary]: '/tournament/create/summary',
  [routes.tournamentCreateAdvancedSummary]: '/tournament/create/advanced-summary',
  [routes.tournamentShare]: '/tournament/share',
  [routes.tournamentHomeMain]: '/tournament/home/main',
  [routes.tournamentDetailOverview]: '/tournament/detail/overview/:id',
  [routes.tournamentDetailOverviewFinal]: '/tournament/detail/overview/:id/final',
  [routes.tournamentCompetitor]: '/tournament/competitor/:id',
  [routes.tournamentTradeStockDetail]: '/tournament/trade/stock-detail/:id',
  [routes.tournamentTradeInputData]: '/tournament/trade/input-data',
  [routes.tournamentTradeSummary]: '/tournament/trade/summary',
  [routes.tournamentChats]: '/tournament/chats',
  [routes.tournamentChatDetail]: '/tournament/chat/detail',
  [routes.tournamentSearch]: '/tournament/search',
  [routes.tournamentJoin]: '/tournament/join/:id',
};

/**
 * @param {NavigateTo} navigateToFn
 * @param {string} route
 * @param {'true' | 'false' | '1' | '0'} fullScreen
 * @param {NavigateToProps} [navigationProps]
 */
export function navigateToNative(navigateToFn, route, fullScreen, navigationProps) {
  console.log('webkit', window.webkit);
  console.log('message handlers', window.webkit?.messageHandlers);

  let newRoute;
  const { queryParams } = navigationProps || {};
  let querySt = '?q=';
  for (const key of Object.keys(queryParams || {})) {
    if (querySt.length) {
      querySt += '&';
    } else {
      querySt += '?';
    }
    querySt += `${key}=${queryParams[key]}`;
  }
  querySt += '&full_screen=true';

  switch (route) {
    case routes.tournamentCreate:
      newRoute = `/tournament/create/start`;
      break;
    case routes.tournamentSearch:
      newRoute = `/tournament/search`;
      break;
    case routes.createAvatar:
      newRoute = `/avatar`;
      break;
    default:
      navigateToFn(route, navigationProps);
      return;
  }

  // TODO: Use postMessage -> https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
  if (fullScreen !== 'true' && CommonUtils.pushToNative) {
    CommonUtils.pushToNative({
      body: { action: 'goFullScreen', params: { route: newRoute, querySt, queryParams } },
    });
  } else {
    navigateToFn(route, navigationProps);
  }
}

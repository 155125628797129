import styled from 'styled-components/macro';
import { space, layout } from 'styled-system';

export const H2 = styled.h2`
  color: var(--color-white);
  font-family: Helvetica;
  font-size: 19px;
  font-weight: ${(props) => (props.bold ? '700' : '400')};
  line-height: 5.5vw;
  text-align: center;
  ${space};
  ${layout};
  @media (max-width: 990px) {
    font-size: 15px;
  }
`;

import { UiWrapper } from 'coinscrap-webapp-core-ts';

UiWrapper.defineComponent('slider', {
  styles: {
    default: {
      dotsContainerComponent: {
        position: 'initial',
        padding: '3vh 0',
      },
      rootComponent: {
        flex: '1',
      },
      containerComponent: {
        height: '100%',
      },
      slideComponent: {
        height: '100%',
      },
      dotComponent: {
        background: 'white',
        opacity: '0.3',
        width: '10px',
        height: '10px',
        margin: '0 4px',
      },
      dotActiveComponent: {
        backgroundColor: 'white',
        opacity: '1',
      },
    },
  },
});

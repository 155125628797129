import { UiWrapper } from 'coinscrap-webapp-core-ts';

UiWrapper.defineComponent('view', {
  styles: {
    default: {
      height: '100%',
    },

    flexSt: {
      flex: 1
    }
  },
});

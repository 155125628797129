import styled from 'styled-components/macro';
import { space, layout } from 'styled-system';

export const H2slide = styled.h2`
  color: var(--color-pink);
  font-family: 'Roboto', sans-serif;
  font-size: 29px;
  font-weight: 400;
  text-align: center;
  ${space};
  ${layout};
`;

import { createGlobalStyle } from 'styled-components/macro';

export const GlobalStyle = createGlobalStyle`

.MuiFilledInput-root {
  background-color: transparent !important;
}
.MuiCheckbox-root svg.MuiSvgIcon-root {
  fill: transparent;
}
body {
  margin: 0;
  fontFamily: 'Helvetica !important',
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background-global);
  background-position: center;
  background-size: cover;
  color: var(--color-primary);

   --color-primary: #1a1c21;
   --color-secondary: #0166FA;
   --color-white: #F9F9F9;
   --color-black: #1a1c21;
   --color-lightGrey: #ebebee;
   --color-lightPurple: rgba(194,185,224,1);
   --color-yellow: rgb(245, 212, 77);
   --color-red: #fc5943;
   --color-blue: #8cdace;
   --color-darkBlue: #3b3667;
   --color-green: #9EF2AD;
   --color-danger: #dc3545;
   --color-pink: #E078A0;
   --color-violet: #B000F5;
   --color-grey: #DADADA;
   --color-blue: #004882;
   
   --background-global: url('/game/img/bg-global.png');
   
   --border-radius: 25px;

   --shadow: 0px 15px 40px 10px rgba(50, 44, 91, 0.40);

   --gradient-purple: linear-gradient(180deg, rgba(50, 44, 91, 1) 0%, rgba(133, 117, 224, 1) 100%);
}
  
div#root{
  margin: auto !important;
  max-width: 800px;
  /*height: 90vh;*/
  background: rgba(0,0,0,0.1);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Eurostile, sans-serif;
  outline: none;
}

@media (min-width: 990px) {
  div#root{
    height: 90vh;
  }
}

@media (max-width: 990px) {
    
  div#root{
    margin: 0 auto !important;
    max-width: 414px;
    max-height: auto;
    background: transparent;
  }
  
}

`;

import { MainButton } from 'components/MainButton/MainButton';
import { Header } from 'components/Header/header';
import { H6 } from 'components/H6/H6';
import { Plabel } from 'components/Plabel/Plabel';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';

import {
  NavigationWrapper,
  UiWrapper,
  InputSelect,
  SelectItem,
  Input,
} from 'coinscrap-webapp-core-ts';
import { routes } from 'routes';
import * as S from './styles';
import { MarginWrapper } from '../../../layout/MarginWrapper/MarginWrapper';

export const AdvancedMode = () => {
  const { navigateTo } = NavigationWrapper.use();
  const { useHeader } = UiWrapper.use();

  useHeader(<Header closeRoute={routes.tournamentHomeMain} />);
  return (
    <S.Wrapper>
      <S.Content>
        <MarginWrapper>
          <S.Caja>
            <H6 marginBottom="10px">Configurar modo avanzado</H6>
            <S.Divimg>
              <S.Imgsmalls src="/game/img/ibex35.svg" />
            </S.Divimg>
            <Plabel center marginTop="35px" marginBottom="5px">
              ¿Ordenes Limitadas?
            </Plabel>
            <InputSelect selectIcon={ExpandMoreOutlinedIcon} value="0">
              <SelectItem value="0" disabled>
                Selecciona
              </SelectItem>
              <SelectItem value="s">Si</SelectItem>
              <SelectItem value="n">No</SelectItem>
            </InputSelect>
            <Plabel center marginTop="35px" marginBottom="5px">
              ¿Stop Loss?
            </Plabel>
            <InputSelect selectIcon={ExpandMoreOutlinedIcon} value="0">
              <SelectItem value="0" disabled>
                Selecciona
              </SelectItem>
              <SelectItem value="s">Si</SelectItem>
              <SelectItem value="n">No</SelectItem>
            </InputSelect>
            <Plabel center marginTop="35px" marginBottom="5px">
              ¿Cuál es el volumen mínimo por orden?
            </Plabel>
            <Input type="number" placeholder="Cantidad minima" inputwhiteSt />
            <Plabel center marginTop="35px" marginBottom="5px">
              ¿Cuál es el volumen máximo por orden?
            </Plabel>
            <Input type="number" placeholder="Cantidad máxima" inputwhiteSt />
            <Plabel center marginTop="35px" marginBottom="5px">
              ¿Compra apalancada?
            </Plabel>
            <InputSelect selectIcon={ExpandMoreOutlinedIcon} value="0">
              <SelectItem value="0" disabled>
                Selecciona
              </SelectItem>
              <SelectItem value="s">Si</SelectItem>
              <SelectItem value="n">No</SelectItem>
            </InputSelect>
            <Plabel center marginTop="35px" marginBottom="5px">
              Tipo de interes para la compra apalancada
            </Plabel>
            <InputSelect selectIcon={ExpandMoreOutlinedIcon} value="0">
              <SelectItem value="0" disabled>
                porcentaje (%)
              </SelectItem>
              <SelectItem value="1">1%</SelectItem>
              <SelectItem value="2">2%</SelectItem>
              <SelectItem value="3">3%</SelectItem>
              <SelectItem value="4">4%</SelectItem>
              <SelectItem value="5">5%</SelectItem>
              <SelectItem value="6">6%</SelectItem>
              <SelectItem value="7">7%</SelectItem>
              <SelectItem value="8">8%</SelectItem>
            </InputSelect>
            <Plabel center marginTop="35px" marginBottom="5px">
              ¿Ventas en corto?
            </Plabel>
            <InputSelect selectIcon={ExpandMoreOutlinedIcon} value="0">
              <SelectItem value="0" disabled>
                Selecciona
              </SelectItem>
              <SelectItem value="s">Si</SelectItem>
              <SelectItem value="n">No</SelectItem>
            </InputSelect>
          </S.Caja>
          <MainButton
            pink
            full
            fullWidth
            marginTop="100px"
            onClick={() => {
              navigateTo(routes.tournamentCreateWalletVisibility);
            }}
          >
            TERMINAR
          </MainButton>
        </MarginWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

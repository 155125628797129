import styled from 'styled-components/macro';
import { space, layout } from 'styled-system';

export const H1 = styled.h1`
  color: ${(props) =>
    // eslint-disable-next-line no-nested-ternary
    props.color ? props.color : props.black ? 'black' : 'var(--color-white)'};
  font-family: Helvetica;
  font-size: 25px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  ${space};
  ${layout};
`;

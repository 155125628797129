import { UiWrapper } from 'coinscrap-webapp-core-ts';

UiWrapper.defineComponent('inputSelect', {
  styles: {
    default: {
      containerComponent: {
        width: '100%',
      },
      selectComponent: {
        paddingTop: '20px',
        paddingBottom: '20px',
        fontSize: '14px',
        color: '#979797',
        background: 'white',
        borderRadius: '10px',
        paddingRight: '45px !important',
      },
      iconComponent: {
        color: '#6586D4',
        borderLeft: '1px solid #E5E5E5',
        width: '40px',
      },
    },
    editableSt: {
      containerComponent: {
        background: 'transparent',
        width: '100%',
        borderRadius: '0',
        border: '0',
        borderBottom: '1px solid white',
        margin: '10px 0',
      },
      selectContainerComponent: {
        background: 'transparent !important',
      },
      selectComponent: {
        fontSize: '18px',
        color: 'white',
        fontFamily: 'EurostileBold',
        fontWeight: '100',
        background: 'transparent !important',
        borderRadius: '0',
        padding: '10px 0 !important',
      },
      iconComponent: {
        borderLeft: '0',
        width: 'auto',
        height: '24px',
        right: 0,
      },
      labelComponent: {
        color: 'white',
        fontSize: '14px',
        fontFamily: 'Helvetica',
        transform: 'translate(0px, -10px) scale(0.85) !important',
      },
    },
  },
});

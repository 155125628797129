import { Api } from 'api/api';
import { BackendWrapper, SessionWrapper, UserWrapper, NavigationWrapper } from 'coinscrap-webapp-core-ts';
import { useEffect } from 'react';
import jwt from 'jsonwebtoken';

export const EasyApi = () => {
  const { getInstanceApi } = BackendWrapper.use();
  const { sessionData, startSession, clearSession } = SessionWrapper.use();
  const { user, updateUserData } = UserWrapper.use();
  const { history } = NavigationWrapper.use();

  const { baseUrl } = getInstanceApi().getCurrentOptions();
  console.log('sessionData', sessionData);

  function doRefreshToken(tokenToForce = undefined) {
    return Api.instance.refreshToken(tokenToForce).then(newToken => {
      console.log('refreshed token with', newToken);
      const decoded = jwt.decode(newToken.id, {complete: true});
      startSession({
        token: newToken.id,
        userId: decoded.userId,
        jwtToken: decoded,
        created: decoded.created,
        ttl: decoded.ttl,
        roles: decoded.roles,
      }).then(v => {
        console.log('session update result', v);
      }).catch(e => {
        console.error('session update error', e);
      });
    })
  }

  //Permabind the changes on token or userId to de API class
  useEffect(() => {
    // Necesary
    Api.instance.baseUrl = baseUrl;

    if (sessionData?.token) {
      try {
        // get the decoded payload and header
        const decoded = jwt.decode(sessionData?.token, {complete: true});
        if (decoded) {
          console.log('decoded.header', decoded.header);
          console.log('decoded.payload', decoded.payload);
          // Optional, this omit repeat params in call,
          //   Always binded to the api instance
          Api.instance.token = sessionData?.token;
        } else {
          doRefreshToken()
          return
        }
      } catch (e) {
        console.error(e);
        doRefreshToken()
        return
      }
    }

    if (sessionData?.userId) {
      Api.instance.user = sessionData?.userId;
    } else if (sessionData?.token) {
      Api.instance.getCurrentUser().then((userFromApi) => {
        Api.instance.user = userFromApi.id;
        startSession({
          token: sessionData?.token,
          userId: userFromApi.id,
          username: userFromApi.username,
          email: userFromApi.email,
          jwtToken: sessionData.jwtToken,
        });
      });
    }
  }, [sessionData?.token, sessionData?.userId, baseUrl]);

  useEffect(() => {
    // clearSession();
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = queryParams.get('access_token');
    console.log('tokenFromUrl', tokenFromUrl);
    if (tokenFromUrl) {
      if (!sessionData || tokenFromUrl !== sessionData?.token) {
        // Api.instance.token = tokenFromUrl
        console.log('token not updated', tokenFromUrl, sessionData);
        // setTimeout(() => {
        doRefreshToken(tokenFromUrl)
        // clearSession();
        //   startSession({
        //     token: tokenFromUrl,
        //   })
              .then(v => {
            queryParams.delete('access_token')
            history.replace({
              search: queryParams.toString(),
            })
            console.log('session update result', v);
          }).catch(e => {
            console.error('session update error', e);
          });
        // });
      }
    }
  }, []);

  return null;
};

import styled from 'styled-components/macro';
import { space, layout } from 'styled-system';

export const Button = styled.button`
  align-items: center;
  background: transparent;
  border: 0;
  border-radius: 0;
  color: var(--color-white);
  display: flex;
  font-family: 'Roboto', sans serif;
  font-size: 14px;
  font-weight: 400;
  justify-content: center;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  padding: 10px;
  text-decoration: underline;
  transition: all 0.2s ease;
  width: ${(props) => (props.fullWidth ? '100%' : '240px')};
  ${space};
  ${layout};

  &:active {
    transform: translateY(3px);
  }
`;

import EditIcon from 'assets/svg/edit.svg';
import CreateIcon from '@material-ui/icons/Create';

import { useState } from 'react';
import { Image, TextField, InputDate, InputSelect } from 'coinscrap-webapp-core-ts';

export const Editable = ({ type, value, minValue, onChange, label, style, children }) => {
  const [isdisabled, setDisabled] = useState(true);

  function handleClick() {
    setDisabled(!isdisabled);
  }

  if (!type || type === 'text' || type === 'number') {
    return (
      <TextField
        disabled={isdisabled}
        type={type}
        value={value}
        onChange={onChange}
        style={style}
        label={label}
        borderBottomSt
        cursorSt
        endAdornment={<Image height="24px" width="auto" src={EditIcon} onClick={handleClick} />}
      />
    );
  }

  if (type === 'date') {
    return (
      <InputDate
        editableSt
        label={label}
        onChange={onChange}
        value={value}
        minDate={minValue}
        style={style}
        cursorSt
      />
    );
  }

  if (type === 'select') {
    return !style || style.display !== 'none' ? (
      <InputSelect
        editableSt
        selectIcon={CreateIcon}
        label={label}
        value={value}
        onChange={onChange}
        style={style}
        cursorSt
      >
        {children}
      </InputSelect>
    ) : null;
  }
};

import { H1regular } from 'components/H1regular/H1regular';
import { P } from 'components/P/P';
import { Header } from 'components/Header/header';

import { MarginWrapper } from 'layout/MarginWrapper/MarginWrapper';
import { NavigationWrapper, UiWrapper } from 'coinscrap-webapp-core-ts';
import { routes } from 'routes';
import { useEffect, useState } from 'react';
import * as S from './styles';
import { TournamentUtils, useTemporaryTournament } from '../../../utils/TournamentUtils';
import { Api } from '../../../api/api';

function MarketsList({ markets, children, onChooseMarket, ...props }) {
  const { navigateTo } = NavigationWrapper.use();
  const fullScreen = new URLSearchParams(window.location.search).get('full_screen') || 'false';

  const list = markets.map((market) => (
    <S.Divimg
      key={market.name}
      onClick={() => {
        onChooseMarket(market.name);
      }}
    >
      {market.icon ? <S.Img src={market.icon} /> : <span style={{ margin: '45px', fontSize: '22px' }}>{market.name}</span>}
    </S.Divimg>
  ));
  return <S.Scroll style={{ width: '100%' }}>{list}</S.Scroll>;
}

export const Market = () => {
  const { navigateTo } = NavigationWrapper.use();
  const { useHeader } = UiWrapper.use();

  const [tmpTournament, setTmpTournament] = useTemporaryTournament();

  const [markets, setMarkets] = useState([
    {
      name: 'IBEX35',
      icon: '/game/img/ibex35.svg',
    },
    // {
    //   name: 'Mercado Continuo',
    //   icon: null,
    // },
    {
      name: 'NASDAQ',
      icon: '/game/img/nasdaq.svg',
    },
    {
      name: 'CRYPTO',
      icon: '/game/img/CryptoLogo.png',
    },
  ]);

  useHeader(<Header closeRoute={routes.tournamentHomeMain} />);
  return (
    <S.Wrapper>
      <S.Content>
        <MarginWrapper justifyContent="space-between">
          <S.Caja>
            <H1regular>Mercado</H1regular>
            <P color="white" align="center" marginBottom="35px" marginTop="10px">
              Elige el Mercado en el que quieras operar durante el Torneo
            </P>
            <MarketsList
              markets={markets}
              onChooseMarket={(marketName) => {
                (setTmpTournament({
                  marketName,
                }) ||
                  true) &&
                  setTimeout(() => navigateTo(routes.tournamentCreateSummary), 300);
              }}
            />
          </S.Caja>
        </MarginWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

import * as S from './styles';

export const MainButton = ({
  children,
  className,
  onClick,
  pink,
  full,
  disabled,
  marginBottom,
  marginTop,
  paddingX,
  width,
  fullWidth,
  right,
  left,
}) => (
  <S.Button
    className={(className || '') + ' mainButton'}
    onClick={onClick}
    pink={pink}
    full={full}
    disabled={disabled}
    marginBottom={marginBottom}
    marginTop={marginTop}
    paddingX={paddingX}
    width={width}
    fullWidth={fullWidth}
    right={right}
    left={left}
  >
    {children}
  </S.Button>
);

import styled from 'styled-components/macro';
import { verticalFlex } from 'styles/common';

export const Wrapper = styled.div`
  ${verticalFlex}
  align-items: center;
  justify-content: space-between;
  min-height: 100%;
  width: 100%;
  @media (max-width: 990px) {
    background: var(--background-global);
    background-position: center;
    background-size: cover;
  }
`;

export const Content = styled.div`
  ${verticalFlex}
  flex: 1;
  height: 100%;
  justify-content: space-between;
  padding: 0 20px;
  width: 100%;
`;

export const Caja = styled.div`
  ${verticalFlex}
  flex: 1;
  height: 100%;
  justify-content: start;
`;

export const Cajita = styled.div`
  ${verticalFlex}
  flex-direction: row;
  justify-content: space-between;
`;

export const MitadL = styled.div`
  padding-right: 5px;
  width: 50%;
`;

export const MitadR = styled.div`
  padding-left: 5px;
  width: 50%;
`;

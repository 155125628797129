import { Header } from 'components/Header/header';
import { H3 } from 'components/H3/H3';
import { MainButton } from 'components/MainButton/MainButton';
import { Editable } from 'components/Editable/Editable';

import { useEffect, useState } from 'react';
import { NavigationWrapper, UiWrapper, SelectItem } from 'coinscrap-webapp-core-ts';
import { routes } from 'routes';
import * as S from './styles';
import { MarginWrapper } from '../../../layout/MarginWrapper/MarginWrapper';
import { TournamentUtils, useTemporaryTournament, useTournamentSaving } from '../../../utils/TournamentUtils';
import { Api } from '../../../api/api';
import { CommonUtils } from '../../../utils/CommonUtils';

export const Summary = () => {
  const { navigateTo } = NavigationWrapper.use();
  const { useHeader } = UiWrapper.use();

  const [tmpTournament, setTmpTournament] = useTemporaryTournament(null);
  const [savedId, setSavedId] = useTemporaryTournament(false);

  useEffect(() => {
    if (tmpTournament?.name) {
      localStorage.setItem('new_tournament_name', tmpTournament?.name);
    }
    if (tmpTournament?.id) {
      localStorage.setItem('new_tournament_id', tmpTournament?.id);
      setSavedId(true);
    }
    if (typeof tmpTournament?.public === 'boolean') {
      localStorage.setItem('new_tournament_public', tmpTournament?.public);
    }
  }, [tmpTournament, tmpTournament?.name, tmpTournament?.id, tmpTournament?.public]);

  useEffect(() => {
    if (localStorage.getItem('should_restart_creation') === 'true') {
      navigateTo(routes.tournamentCreateName);
      localStorage.removeItem('should_restart_creation');
    }
  }, []);

  function updateTmpTournamentBalance(e) {
    let parsed = parseFloat(e.target.value.replace(',', '.'));
    if (Number.isNaN(parsed)) {
      parsed = tmpTournament.initialBalance;
    }
    const newInitialBalance = Math.min(100e6, Math.max(0.01, parsed));
    setTmpTournament({

      initialBalance: newInitialBalance,
    });
  }

  function updateTmpTournamentCommission(e) {
    let parsed = parseFloat(e.target.value.replace(',', '.'));
    if (Number.isNaN(parsed)) {
      parsed = tmpTournament.commission;
    }
    const newInitialCommission = Math.min(100e6, Math.max(0.0, parsed));
    setTmpTournament({

      commission: newInitialCommission,
    });
  }

  function updateTmpTournamentStart(date) {
    if (!date) {
      return;
    }
    // eslint-disable-next-line no-param-reassign
    date = new Date(date.getTime() + 30 * 1000);
    if (date.getTime() >= Date.now()) {
      setTmpTournament({

        start: date,
        end: new Date(Math.max(date.getTime() + 86400 * 1000, tmpTournament.end.getTime())),
      });
    }
  }

  function updateTmpTournamentEnd(date) {
    if (!date) {
      return;
    }
    // eslint-disable-next-line no-param-reassign
    date = new Date(date.getTime() + 300 * 1000);
    if (date.getTime() - tmpTournament.start.getTime() >= 86400 * 1000) {
      setTmpTournament({

        end: date,
      });
    }
  }

  useHeader(<Header closeRoute={routes.tournamentHomeMain} />);
  return (
    <S.Wrapper>
      <S.Content>
        <MarginWrapper justifyContent="space-between">
          <S.Caja>
            <H3 marginBottom="20px" center>
              RESUMEN DEL TORNEO
            </H3>
            <Editable
              label="Nombre del torneo"
              value={tmpTournament?.name}
              type="text"
              onChange={(e) => {
                setTmpTournament({

                  name: e.target.value,
                });
              }}
            />
            <Editable
              label="Fecha de inicio"
              value={tmpTournament?.start}
              minValue={new Date() + 86400 * 1000}
              onChange={(e) => updateTmpTournamentStart(e)}
              type="date"
            />
            <Editable
              label="Fecha final"
              value={tmpTournament?.end}
              minValue={new Date(tmpTournament?.start.getTime() + 86400 * 1000)}
              onChange={(e) => updateTmpTournamentEnd(e)}
              type="date"
            />
            <Editable
              label="Tipo de torneo"
              style={{ display: 'none' }}
              value={tmpTournament?.public ? '1' : '2'}
              type="select"
              onChange={(e) => {
                setTmpTournament({

                  public: e.target.value === '1',
                });
              }}
            >
              <SelectItem value="0" disabled>
                Selecciona
              </SelectItem>
              <SelectItem value="1">Público</SelectItem>
              <SelectItem value="2">Privado</SelectItem>
            </Editable>
            <Editable
              label="Cantidad de inicio (€)"
              value={tmpTournament?.initialBalance}
              type="number"
              onChange={(e) => {
                updateTmpTournamentBalance(e);
              }}
            />
            <Editable
              label="Tipo de cartera"
              value={tmpTournament?.publicWallet ? '1' : '2'}
              onChange={(e) => {
                setTmpTournament({

                  publicWallet: e.target.value === '1',
                });
              }}
              type="select"
            >
              <SelectItem value="0" disabled>
                Selecciona
              </SelectItem>
              <SelectItem value="1">Público</SelectItem>
              <SelectItem value="2">Privado</SelectItem>
            </Editable>
            <Editable
              label="Valor de las comisiones (€)"
              value={tmpTournament?.commission}
              type="number"
              onChange={(e) => {
                updateTmpTournamentCommission(e);
              }}
            />
            {/*<Editable*/}
            {/*    label="Tipo de desafío"*/}
            {/*    value={desafio}*/}
            {/*    onChange={(e) => {*/}
            {/*      setDesafio(e.target.value);*/}
            {/*    }}*/}
            {/*    tipo="select"*/}
            {/*>*/}
            {/*  <SelectItem value="0" disabled>*/}
            {/*    Selecciona*/}
            {/*  </SelectItem>*/}
            {/*  <SelectItem value="1">Mayor rentabilidad</SelectItem>*/}
            {/*  <SelectItem value="2">Privado</SelectItem>*/}
            {/*</Editable>*/}
            {/*<Editable*/}
            {/*    label="Valor de la apuesta"*/}
            {/*    value={apuesta}*/}
            {/*    onChange={(e) => {*/}
            {/*      setApuesta(e.target.value);*/}
            {/*    }}*/}
            {/*    tipo="text"*/}
            {/*/>*/}
          </S.Caja>
          <MainButton
            pink
            fullWidth
            disabled={!tmpTournament?.name || !tmpTournament?.name.trim() || !savedId || tmpTournament?.status === 'CREATED'}
            marginTop="50px"
            onClick={() => {
              TournamentUtils.clearSaveQueue();
              setTmpTournament({ status: 'CREATED' });
              // if (!tmpTournament?.public) localStorage.setItem('new_tournament_password', tmpTournament?.password);
              // setTimeout(() => {
              Api.instance.updateTmpTournament({ ...tmpTournament, status: 'CREATED' }).then(v => {
                if (CommonUtils.pushToNative) {
                  CommonUtils.pushToNative({
                    body: {
                      action: 'sendEmmaEvent',
                      params: {
                        name: 'MKSIM_CREATE_TOR',
                      },
                    },
                  });
                }
                console.log('moved tournament to created', v);
                navigateTo(routes.tournamentShare, { resetHistory: true });
              });
              // });
            }}
          >
            CREAR
          </MainButton>
        </MarginWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

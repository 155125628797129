import { UiWrapper } from 'coinscrap-webapp-core-ts';

UiWrapper.defineComponent('layout', {
  styles: {
    default: {
      bodyScrollerComponent: {
        height: 'auto',
      },
    },
  },
});

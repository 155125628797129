import { Header } from 'components/Header/header';
import { Pbig } from 'components/Pbig/Pbig';
import { MainButton } from 'components/MainButton/MainButton';

import { useEffect, useState } from 'react';
import { NavigationWrapper, UiWrapper, InputCheck, Page } from 'coinscrap-webapp-core-ts';
import { routes } from 'routes';
import * as S from './styles';
import { MarginWrapper } from '../../../layout/MarginWrapper/MarginWrapper';
import { Api } from '../../../api/api';
import { TournamentUtils } from '../../../utils/TournamentUtils';
import { CommonUtils } from '../../../utils/CommonUtils';

export const TradeSummary = () => {
  const { navigateTo } = NavigationWrapper.use();
  const { useHeader } = UiWrapper.use();
  const [ischeck, setCheck] = useState(false);
  const [creatingOrder, setCreatingOrder] = useState(false);
  const [instrument, setInstrument] = useState(null);
  const [tournament, setTournament] = useState(null);
  const [market, setMarket] = useState(null);
  const [estimated, setEstimated] = useState(0);

  const { params, query } = Page.use();
  const { instrumentId, tournamentId, walletId, amount: stAmount, orderType } = query;

  const amount = parseInt(stAmount);

  useEffect(() => {
    if (instrumentId) {
      // setLoadingInstrument(true);
      // eslint-disable-next-line no-shadow
      Api.instance.findInstrumentById(instrumentId).then((instrument) => {
        if (instrument) {
          setInstrument({
            ...instrument,
          });

          // setLoadingInstrument(false);
        }
      });
    }
  }, [instrumentId]);

  useEffect(() => {
    if (tournament) {
      Api.instance.findMarketByNameWithInstruments(tournament.marketName).then(marketFromApi => {
        if (marketFromApi) {
          setMarket(marketFromApi);
        }
      }).catch(reason => {
        console.error(reason);
      })
    }
  }, [tournament]);

  useEffect(() => {
    if (tournamentId) {
      // setLoadingInstrument(true);
      // eslint-disable-next-line no-shadow
      Api.instance.findTournamentById(tournamentId).then((tournament) => {
        if (tournament) {
          setTournament({
            ...tournament,
          });

          // setLoadingTournament(false);
        }
      });
    }
  }, [tournamentId]);

  useEffect(() => {
    if (instrument) {
      Api.instance.socketHandler.subscribeInstrument(
        instrument.ticker,
        instrument.marketId,
        (updatedInstrument) => {
          instrument.last = updatedInstrument.last;
          instrument.var = updatedInstrument.var;
          instrument.vol = updatedInstrument.vol;
          instrument.lastSeen = updatedInstrument.lastSeen;
        }
      );
      setEstimated(amount * instrument?.last);
    }
  }, [instrument]);

  function handleClick() {
    setCheck(!ischeck);
  }

  useHeader(<Header closeRoute={routes.tournamentHomeMain} />);

  function createOrder() {
    setCreatingOrder(true);
    const idN = CommonUtils.padded((Math.round(Math.random() * 1000) % 1000) * 1000, 6, 'pre');
    const dateNow = new Date();

    const idTransaction = `NA${dateNow.getFullYear()}-${CommonUtils.padded(
      dateNow.getMonth() + 1,
      2
    )}${CommonUtils.padded(dateNow.getHours(), 2)}${CommonUtils.padded(
      dateNow.getMinutes(),
      2
    )}${CommonUtils.padded(dateNow.getSeconds(), 2)}${idN}`;

    const order = {
      walletId,

      ISIN: instrument.isin,
      provider: 'game',
      type: orderType,
      market: instrument.market.name,
      ticker: instrument.ticker,
      orderedTitles: amount,
      acceptRiskSim: true,

      idTransaction, // <-- TODO: Move to back
      date: new Date(), // <-- TODO: Move to back

      limitDate: null, // <-- TODO: Complex orders
      orderPrice: null, // <-- TODO: Complex orders
      triggerPrice: null, // <-- TODO: Complex orders
    };
    Api.instance.createOrder(order, tournamentId).then((createdOrder) => {
      setCreatingOrder(false);
      console.log('createdOrder', createdOrder);
      if (createdOrder) {
        // const orderPart = idTransaction.substring(0, idTransaction.length - 3);
        // const idNExecution = CommonUtils.padded(1, 3, 'pre');

        // const idExecution = `${orderPart}${idNExecution}`;
        //
        // // eslint-disable-next-line no-nested-ternary
        // const executionPrefix = order.type === '11' ? `buy` : order.type === '12' ? 'sale' : null;
        // const execution = {
        //   [`${executionPrefix}OrderId`]: createdOrder.id,
        //   executedTitles: createdOrder.orderedTitles,
        //   executionPrice: instrument.last,
        //   executionDate: new Date(),
        //
        //   idExecution, // <-- TODO: Move to back
        // };
        // Api.instance.createExecution(executionPrefix, execution).then((createExecution) => {
        //   console.log('createExecution', createExecution);
        //   if (createExecution) {
        // eslint-disable-next-line no-nested-ternary

        // setLoadingInstrument(false);
        localStorage.removeItem('overviewTab');
        navigateTo(routes.tournamentDetailOverview, {
          clearItems: Object.keys(query).map(it => it !== 'full_screen'),
          routeParams: { id: tournamentId },
          queryParams: { full_screen: 'true', tab: orderType === '11' ? 'c' : 'o' },
          mode: 'replace',
        });
        // }
        // });
      }
    }).catch(e => {
      console.error(e)
      setCreatingOrder(false);
    });

    localStorage.removeItem('trade_amount');
  }

  return (
    <S.Wrapper>
      <S.Content>
        <MarginWrapper justifyContent="space-between">
          <S.Caja>
            <S.H1>{instrument?.ticker}</S.H1>
            <S.Small uppercase marginBottom>
              {instrument?.isin} - {instrument?.name}
            </S.Small>
            <S.H2>{TournamentUtils.formatCurrency(instrument?.last, 2, true, market?.currency)}</S.H2>
          </S.Caja>
          <S.Resumen>
            <S.Fila>
              <Pbig color="white" align="left">
                Número de Acciones
              </Pbig>
              <Pbig color="white" align="right">
                {amount}
              </Pbig>
            </S.Fila>
            <S.Fila>
              <Pbig color="white" align="left">
                Precio de Mercado
              </Pbig>
              <Pbig color="white" align="right">
                {TournamentUtils.formatCurrency(instrument?.last, 2, true, market?.currency)}
              </Pbig>
            </S.Fila>
            <S.Fila>
              <Pbig color="white" align="left">
                Comisión a éxito
              </Pbig>
              <Pbig color="white" align="right">
                {TournamentUtils.formatCurrency(tournament?.commission, 0, true, market?.currency)}
                {/*<a*/}
                {/*  style={{ textDecoration: 'none', color: 'inherit' }}*/}
                {/*  href="https://docs.google.com/viewer?url=https://cloudzerodocuments.s3.eu-north-1.amazonaws.com/Tarifas.pdf"*/}
                {/*>*/}
                {/*  (Ver tarifas)*/}
                {/*</a>*/}
              </Pbig>
            </S.Fila>
          </S.Resumen>
          <S.Resumen>
            <S.Fila>
              <Pbig color="white" align="left">
                Total Orden
              </Pbig>
              <Pbig color="white" align="right">
                {TournamentUtils.formatCurrency(estimated, 2, true, market?.currency)}
              </Pbig>
            </S.Fila>
            <S.Fondo>
              <S.H4>6/6</S.H4>
              <S.H5>
                Este número es indicativo del riesgo del producto siendo 1/6 indicativo de menor
                riesgo y 6/6 de mayor riesgo
              </S.H5>
              <S.H6>
                Declaro conocer que en la ejecución de esta orden (producto no complejo) la entidad
                no tiene obligación de evaluar la adecuación del mismo para sus clientes y, por
                tanto, éstos no gozan de la protección establecida en las normas de conducta
                aplicables a la prestación de Servicios de inversión. También declaro que este
                servicio se realiza a petición propia, considerándome capacitado para valorar la
                conveniencia y los riesgos asociados.
              </S.H6>
              <S.Flex>
                <InputCheck onChange={handleClick} activeSt={ischeck === true} />
                <S.H6 blue sinmargin>
                  He leido y acepto la información y clasificación normalizada del nivel de riesgo
                  de la inversión así como la información sobre los costes y gastos asociados a la
                  operación.
                </S.H6>
              </S.Flex>
            </S.Fondo>
          </S.Resumen>
          <MainButton
            fullWidth
            blue
            disabled={!ischeck || creatingOrder}
            onClick={() => {
              createOrder();
            }}
          >
            CREAR ORDEN A MERCADO
          </MainButton>
        </MarginWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

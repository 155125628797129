import styled from 'styled-components/macro';
import { space } from 'styled-system';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: ${(props) => (props.justifyContent)};
  padding: ${(props) => (props.sinpadding ? '0' : '15px 0')};

  ${space}
`;

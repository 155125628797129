import styled from 'styled-components/macro';
import { verticalFlex } from 'styles/common';

export const Wrapper = styled.div`
  ${verticalFlex}
  align-items: center;
  justify-content: space-between;
  min-height: 100%;
  padding: 0 20px 15px;
`;

export const Content = styled.div`
  ${verticalFlex}
  flex: 1;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export const TextP = styled.p`
  ${verticalFlex}
  color: white;
  font-size: 14px;
  margin-top: 10px;
  text-decoration: underline;
`;

export const Img = styled.img`
  height: 302px;
  padding-top: 33px;
`;

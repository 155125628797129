import Modal from '@material-ui/core/Modal';

import { Header } from 'components/Header/header';
import { MainButton } from 'components/MainButton/MainButton';
import { H1small } from 'components/H1small/H1small';
import { H2small } from 'components/H2small/H2small';
import { H1regular } from 'components/H1regular/H1regular';
import { Psmall } from 'components/Psmall/Psmall';
import { Pbig } from 'components/Pbig/Pbig';

import { useEffect, useState } from 'react';
import { NavigationWrapper, UiWrapper, TextField, View, Page } from 'coinscrap-webapp-core-ts';
import { routes } from 'routes';
import ReactLoading from 'react-loading';
import * as S from './styles';
import { MarginWrapper } from '../../layout/MarginWrapper/MarginWrapper';
import { Api } from '../../api/api';
import { H2 } from '../../components/H2/styles';
import { TournamentUtils } from '../../utils/TournamentUtils';
import * as CommonStyles from '../../styles/common';
import { CommonUtils } from '../../utils/CommonUtils';
import { routeMapping } from '../../routes/routes';
import { BasesModal, BasesModalOpener } from '../../components/BasesModal';

function TournamentWalletsList({ wallets, market, children, ...props }) {
  const { navigateTo } = NavigationWrapper.use();
  const fullScreen = new URLSearchParams(window.location.search).get('full_screen') || 'false';

  const list = wallets.map((wallet, i) => (
    <S.CajaBlanca
      key={wallet.id}
      onClick={() => {
        navigateTo(routes.tournamentCompetitor, {
          routeParams: { id: wallet.user.id },
          queryParams: { full_screen: 'true' }
        });
      }}
    >
      <S.Img src="/game/img/oro.svg" />
      <S.Icon src={wallet.user?.photo || '/game/img/usuario1.svg'} />
      <S.P>{wallet.user?.username}</S.P>
      <S.P>Nº {i + 1}</S.P>
      <Psmall align="center" color="black" marginTop="5px">
        Total ganado
      </Psmall>
      <Psmall align="center" color="black">
        {TournamentUtils.formatCurrency(
          wallet.balance + (wallet.remainingValue || 0) - wallet.tournament.initialBalance, 2, true, market?.currency
        )}
      </Psmall>
    </S.CajaBlanca>
  ));
  return <S.Row style={{ width: '100%' }}>{list}</S.Row>;
}

export const Join = () => {
  const { navigateTo } = NavigationWrapper.use();

  // const {ModalOpener, BasesModal} = useBasesModal();

  const [selecModal, setSelecModal] = useState(false);
  const [openBasesModal, setOpenBasesModal] = useState(false);

  const { useHeader } = UiWrapper.use();
  const { params, query } = Page.use();
  const { id: tournamentId } = params;
  const {
    can_go_back: canGoBack,
    share_code: urlShareCode,
    shared_tournament_id: sharedTournamentId,
  } = query;
  const [tournament, setTournament] = useState(null);
  const [market, setMarket] = useState(null);
  const [requestingTournament, setRequestingTournament] = useState(true);
  const [shareCode, setShareCode] = useState('');
  const [formattedShareCode, setFormattedShareCode] = useState('');
  const [isAvailable, setIsAvailable] = useState(false);
  const [counter, setCounter] = useState(new Date(0));
  const [fechaTorneo, setFechaTorneo] = useState(new Date());
  const [joining, setJoining] = useState(false);

  const [isWalletInTmp, setIsWalletInTmp] = useState(false);

  useEffect(() => {
    if (urlShareCode?.trim() && sharedTournamentId === tournamentId) {
      setShareCode(urlShareCode.trim());
      setJoining(true);
    }
  }, [urlShareCode, tournamentId, sharedTournamentId]);

  useEffect(() => {
    if (tournamentId) {
      // eslint-disable-next-line no-shadow
      setIsWalletInTmp(true);
      Api.instance.findMyWalletInTournament(tournamentId).then((wallet) => {
        if (wallet) {
          navigateTo(routes.tournamentDetailOverview, {
            routeParams: { id: tournamentId },
            queryParams: { full_screen: 'true' },
            mode: 'replace',
            resetHistory: true,
          });
        }
      }).finally(setIsWalletInTmp(false))
    }
  }, [tournamentId]);

  useEffect(() => {
    if (tournament) {
      Api.instance.findMarketByNameWithInstruments(tournament.marketName).then((marketFromApi) => {

        if (marketFromApi) {
          setMarket(marketFromApi);
        }
      });
    }
  }, [tournament]);

  useEffect(() => {
    if (joining && tournament?.id && (tournament?.public || formattedShareCode)) {
      Api.instance
        .createWalletInTournament(tournament, formattedShareCode)
        .then((wallet) => {
          console.log('created wallet', JSON.stringify(wallet));
          if (wallet && wallet.id) {
            if (CommonUtils.pushToNative) {
              CommonUtils.pushToNative({
                body: {
                  action: 'sendEmmaEvent',
                  params: {
                    name: 'MKSIM_JOIN_TOR',
                  },
                },
              });
            }
            navigateTo(routes.tournamentDetailOverview, {
              routeParams: { id: tournamentId },
              queryParams: { full_screen: 'true' },
              mode: 'replace',
              resetHistory: true,
            });
          } else {
            setSelecModal(true);
          }
        })
        .catch((e) => {
          console.error(e);
          setSelecModal(true);
        });
    }
  }, [joining, formattedShareCode, tournament, tournament?.id]);

  useEffect(() => {
    setRequestingTournament(true);
    Api.instance
      .findTournamentById(tournamentId)
      // eslint-disable-next-line no-shadow
      .then((tournament) => {
        if (tournament) {
          const remainingDays = Math.floor(
            (tournament.end.getTime() - Date.now()) / (86400 * 1000)
          );
          // tournament.limitJoinDate is already Date with normalizeTournament()
          if (new Date(tournament.start) > new Date() || tournament.limitJoinDate < new Date()) {
            setIsAvailable(false);
          } else {
            setIsAvailable(true);
          }
          setFechaTorneo(tournament.start);
          const cb = () => {
            const now = new Date();
            const time = (new Date(tournament.start) - now + 1000) / 1000;
            setCounter(time);
          };
          cb();
          setInterval(cb, 1000);
          setTournament({
            ...tournament,
            remainingDays,
          });
        }
        setRequestingTournament(false);
      })
      .catch((e) => {
        setRequestingTournament(false);
      });
  }, []);

  const getTime = () => {
    const seconds = ('0' + Math.floor(counter % 60)).slice(-2);
    const minutes = ('0' + Math.floor((counter / 60) % 60)).slice(-2);
    const hours = ('0' + Math.floor((counter / 3600) % 24)).slice(-2);
    const days = Math.floor(counter / (3600 * 24));

    return {
      seconds,
      minutes,
      hours,
      days,
    };
  };

  useHeader(<Header canGoBack={canGoBack === 'true'} closeRoute={routes.tournamentHomeMain} />);

  function onJoinClicked() {
    if (tournament.public || (shareCode || '').trim()) {
      if (!joining) {
        setJoining(true);
      }
    } else {
      setSelecModal(true);
    }
  }

  useEffect(() => {
    let targetFormattedShareCode = `${shareCode}`?.replace(/\D+/gi, '');
    if (targetFormattedShareCode.trim()) {
      targetFormattedShareCode = `${targetFormattedShareCode.substring(
        0,
        4
      )}-${targetFormattedShareCode.substring(4, 8)}`;
      setFormattedShareCode(targetFormattedShareCode);
    }
  }, [shareCode]);

  if (!tournament) return null;
  return isWalletInTmp ? (
    <CommonStyles.FullHeightLoadingContainer>
      <ReactLoading type="spinningBubbles" color="#ffffff" height="20%" width="20%" />
    </CommonStyles.FullHeightLoadingContainer>
  ) : (
    <S.Wrapper>
      <S.Content>
        <MarginWrapper justifyContent="space-between">
          <H2 style={{ color: 'white' }}>{tournament?.name || 'Torneo sin nombre'}</H2>
          <S.CajaTop>
            {isAvailable ? (
              <H2small>
                ACABA EN {tournament?.remainingDays}{' '}
                {Number(tournament?.remainingDays) === 1 ? 'DÍA' : 'DÍAS'}
              </H2small>
            ) : (
              <div>
                <H1small marginBottom="5px">Empieza en</H1small>
                <S.Fecha>
                  <span style={{ fontSize: 24, fontWeight: 'bold' }}>{getTime().days}</span>
                  <span style={{ color: '#c7c7c7' }}>d</span>{' '}
                  <span style={{ fontSize: 24, fontWeight: 'bold' }}>{getTime().hours}</span>
                  <span style={{ color: '#c7c7c7' }}>h</span>{' '}
                  <span style={{ fontSize: 24, fontWeight: 'bold' }}>{getTime().minutes}</span>
                  <span style={{ color: '#c7c7c7' }}>m</span>{' '}
                  <span style={{ fontSize: 24, fontWeight: 'bold' }}>{getTime().seconds}</span>
                  <span style={{ color: '#c7c7c7' }}>s</span>{' '}
                </S.Fecha>
              </div>
            )}
            <H1small marginBottom="10px">LOS TRES MEJORES DEL TORNEO</H1small>
            <TournamentWalletsList market={market} wallets={tournament?.wallets?.slice(0, 3)} />
            <Psmall align="center" color="white" marginTop="15px" marginBottom="15px">
              Tu posición depende de lo que has ganado, puedes subir o bajar posiciones
            </Psmall>
            <S.CajaBlancaG>
              <S.Titulo>Información del torneo</S.Titulo>
              <Psmall color="grey" marginTop="15px" marginBottom="5px">
                Fecha inicio
              </Psmall>
              <S.Pblack>
                {tournament?.start
                  ? `${tournament?.start.getDate()}/${tournament?.start.getMonth() + 1
                  }/${tournament?.start.getFullYear()}`
                  : ''}
              </S.Pblack>
              <Psmall color="grey" marginTop="15px" marginBottom="5px">
                Fecha final
              </Psmall>
              <S.Pblack>
                {tournament?.end
                  ? `${tournament?.end.getDate()}/${tournament?.end.getMonth() + 1
                  }/${tournament?.end.getFullYear()}`
                  : ''}
              </S.Pblack>
              {/*TODO: implement future join*/}
              {/*<Psmall color="grey" marginTop="10px" marginBottom="5px">*/}
              {/*  Los jugadores pueden unirse después al torneo*/}
              {/*</Psmall>*/}
              {/*<S.Pblack>Sí</S.Pblack>*/}
              <Psmall color="grey" marginTop="10px" marginBottom="5px">
                Dinero con que empieza cada jugador
              </Psmall>
              <S.Pblack>{TournamentUtils.formatCurrency(tournament?.initialBalance || 0, 2, true, market?.currency)}</S.Pblack>
              {/*TODO: implement tournament type*/}
              {/*<Psmall color="grey" marginTop="10px" marginBottom="5px">*/}
              {/*  Desafío*/}
              {/*</Psmall>*/}
              {/*<S.Pblack>mayor rentabilidad</S.Pblack>*/}
              <Psmall color="grey" marginTop="10px" marginBottom="5px">
                Mercado
              </Psmall>
              <S.Pblack>{tournament?.marketName || ''}</S.Pblack>
            </S.CajaBlancaG>
          </S.CajaTop>

          <BasesModalOpener openModalFn={() => setOpenBasesModal(true)} style={{ marginBottom: '10px' }} />

          <MainButton
            pink
            full
            fullWidth
            marginTop="10px"
            disabled={requestingTournament || !isAvailable}
            onClick={() => {
              onJoinClicked();
            }}
          >
            UNIRSE AL TORNEO
          </MainButton>
          <BasesModal isOpenModal={openBasesModal} closeModalFn={() => setOpenBasesModal(false)} />

          <Modal open={selecModal} onClose={() => setSelecModal(false)}>
            <View>
              <S.Modal>
                <S.Cerrar src="/game/img/cross.svg" onClick={() => setSelecModal(false)} />
                <H1regular align="center" marginBottom="30px" marginTop="30px">
                  Este torneo es privado
                </H1regular>
                <Pbig color="white" align="center" marginBottom="40px">
                  Si tienes el código introdúcelo para participar en este torneo.
                </Pbig>
                <TextField
                  whiteSt
                  cursorSt
                  color="#00000000"
                  type="text"
                  pattern="\d*"
                  inputmode="numeric"
                  label="Introduce el código"
                  placeholder="1234 5678"
                  value={shareCode}
                  onChange={(e) => {
                    setShareCode(e.target.value);
                  }}
                />
                <MainButton
                  pink
                  full
                  fullWidth
                  marginTop="20px"
                  onClick={() => {
                    Api.instance
                      .createWalletInTournament(tournament, formattedShareCode)
                      .then((wallet) => {
                        if (wallet && wallet.id) {
                          if (CommonUtils.pushToNative) {
                            CommonUtils.pushToNative({
                              body: {
                                action: 'sendEmmaEvent',
                                params: {
                                  name: 'MKSIM_JOIN_TOR',
                                },
                              },
                            });
                          }
                          navigateTo(routes.tournamentHomeMain);
                        }
                      });
                  }}
                >
                  UNIRSE
                </MainButton>
              </S.Modal>
            </View>
          </Modal>
        </MarginWrapper>
      </S.Content>
    </S.Wrapper>
  )
};
